import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from '@mui/material';
import { handleAxiosError, useAxios, useUserMutation } from 'api';
import { Input } from 'components/ui';
import { useNotify } from 'hooks/useToast';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useUserStore } from 'stores/useUserStore';
import { buttonSx } from 'theme/sxProps';
import { localStorageUtil } from 'utils/localStorageUtils';
import { BackButton } from '../../AuthPage.support';
import { PasswordSchema } from './Schema';

interface IFormData {
  password: string;
  passwordRepeat: string;
  agree1: boolean;
  agree2: boolean;
  agree3: boolean;
}

export const PasswordComponent = (props: {
  handleBack: (arg: boolean) => void;
}) => {
  const { handleBack } = props;
  const { notify } = useNotify();
  const { setAuthToken } = useAxios();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormData>({
    defaultValues: {
      password: '',
      passwordRepeat: '',
      agree1: false,
      agree2: false,
      agree3: false,
    },
    mode: 'all',
    //@ts-ignore
    resolver: yupResolver(PasswordSchema),
  });

  const {
    mutateAsync,
    isSuccess,
    isError,
    error: mutateError,
    isLoading,
  } = useUserMutation('register');
  const {
    data: loginData,
    mutate: loginMutateAsync,
    isSuccess: loginIsSuccess,
    isError: loginIsError,
    error: loginError,
    isLoading: loginIsLoading,
  } = useUserMutation('login');

  const navigate = useNavigate();
  const info = useUserStore((s) => s.info);
  const setAccessToken = localStorageUtil.setItem;
  const setInfo = useUserStore((s) => s.setInfo);

  const onSubmit: SubmitHandler<IFormData> = useCallback(
    (formData) => {
      mutateAsync({
        bbid: info?.bbid!,
        wallet: info?.wallet?.address!,
        email: info?.email!,
        password: formData.password,
      }).then(() => {
        loginMutateAsync({
          email: info?.email!,
          password: formData.password,
        });
      });
    },
    [
      info?.bbid,
      info?.email,
      info?.wallet?.address,
      loginMutateAsync,
      mutateAsync,
    ]
  );

  useEffect(() => {
    if (isSuccess && loginData && loginIsSuccess) {
      const token = loginData.data.accessToken;
      const bbid = loginData.data.user.bbid;

      setAuthToken({ access_token: token });
      setInfo({ bbid });
      setAccessToken({ key: 'access_token', value: token });

      navigate(routes.dashboard.path);
      notify({ type: 'success', meassage: 'Register success!' });
    }
  }, [
    isSuccess,
    navigate,
    setAccessToken,
    setInfo,
    setAuthToken,
    loginData,
    loginIsSuccess,
    notify,
  ]);

  return (
    <Box>
      <BackButton {...{ handleBack }} />
      <Typography variant="h1">Create your </Typography>
      <Typography variant="h1" color="primary" marginBottom={4}>
        BBID password
      </Typography>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} marginBottom={5}>
          <Input
            text="New Password"
            placeholder="12345678"
            fullWidth
            required
            message={
              errors.password?.message ||
              handleAxiosError(mutateError) ||
              handleAxiosError(loginError)
            }
            error={!!errors.password || isError || loginIsError}
            showIcon={true}
            {...register('password')}
          />
          <Input
            text="Repeat Password"
            type="password"
            fullWidth
            required
            message={
              errors.passwordRepeat?.message ||
              handleAxiosError(mutateError) ||
              handleAxiosError(loginError)
            }
            error={!!errors.passwordRepeat || isError || loginIsError}
            {...register('passwordRepeat')}
          />
          {[
            'If I lose my seed password I will lost all my assets',
            'If I give my seed password to anybody my assets may be stolen',
            'BB App and Moovy Support never asks you for your seed password',
          ].map((v, i) => (
            <FormControlLabel
              key={i}
              control={
                <Checkbox
                  required
                  {...register(`agree${i + 1}` as never)}
                  sx={{ padding: 0, '& .MuiSvgIcon-root': { fontSize: 34 } }}
                />
              }
              label={<Typography color="secondary">{v}</Typography>}
            />
          ))}
        </Stack>
        <Button
          fullWidth
          type="submit"
          disabled={!isDirty || !isValid || isLoading || loginIsLoading}
          sx={buttonSx()}
          style={{ marginBottom: 16 }}
        >
          <Typography variant="h5" fontWeight="700">
            Continue
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};
