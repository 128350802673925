import { Box, ButtonBase, Typography, useTheme } from '@mui/material';
import { GalleryIcon } from 'assets/svg-components/Gallery';
import { Fragment } from 'react';
import { buttonSx } from 'theme/sxProps';

export const HotSales = () => {
  const { palette, shape } = useTheme();

  return (
    <Fragment>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="80px"
        sx={{
          p: '20px',
          background: palette.primary.dark,
          borderRadius: `${shape.borderRadius}px`,
        }}
      >
        <Typography variant="h5" color="secondary" fontWeight={500}>
          Checkout the market and buy what you like
        </Typography>
        <ButtonBase
          onClick={(e) => {}}
          sx={buttonSx({
            height: '44px',
            width: '210px',
            borderRadius: `${shape.borderRadius}px`,
          })}
        >
          <GalleryIcon fill="black" />
          <Typography ml={0.5} variant="h5" fontWeight={500}>
            Go to marketplace
          </Typography>
        </ButtonBase>
      </Box>
    </Fragment>
  );
};
