import { useNavigate } from 'react-router-dom';
import { ScLogo } from './Logo.styles';
import { MoovyDash } from 'assets/svg-components/MoovyDash';
import { routes } from 'routes/routes';

export const Logo = () => {
  const navigate = useNavigate();
  return (
    <ScLogo
      sx={{ cursor: 'pointer' }}
      onClick={() => navigate(routes.dashboard.path)}
    >
      <MoovyDash />
    </ScLogo>
  );
};
