import { MutationFunction, useMutation } from '@tanstack/react-query';
import { MutationOptionsType, MutationTagType } from 'api/types';
import axios from 'axios';
import { config } from 'config';
import {
  ChangePassword,
  Login,
  LoginResponse,
  Register,
  RegisterResponse,
  Reset,
  ResetWithEmail,
  Verificate,
} from 'types/models';
import { useAxios } from '../axios';

type MutationKeyToType = {
  login: { args: Login; response: LoginResponse };
  register: { args: Register; response: RegisterResponse };
  reset: { args: Reset; response: { message: string } };
  verificatePincode: { args: Verificate; response: { message: string } };
  changePasswordWithEmail: {
    args: ResetWithEmail;
    response: { message: string };
  };
  changePassword: { args: ChangePassword; response: { message: string } };
};

type MutationKey = keyof MutationKeyToType;

export const useUserMutation = <K extends MutationKey>(
  key: K,
  options?: MutationOptionsType<MutationKeyToType[K]['response']>,
  tags: string[] = []
) => {
  const { api } = useAxios();
  const baseUrl = '/auth';

  const login = async (args: Login) => {
    const { data } = await api().post<MutationKeyToType[K]['response']>(
      baseUrl + '/login',
      args
    );
    return data;
  };

  const register = async (args: Register) => {
    await api().post<MutationKeyToType['register']['response']>(
      baseUrl + '/register',
      args
    );
    const { data: register } = await api().post<
      MutationKeyToType['login']['response']
    >(baseUrl + '/login', args);

    await api().post(
      '/users/create',
      {},
      {
        headers: {
          Authorization: `Bearer ${register.data.accessToken}`,
        },
      }
    );

    await axios.put(
      config.api + '/users/avatar',
      {
        avatarId: 1,
      },
      {
        headers: {
          Authorization: `Bearer ${register.data.accessToken}`,
        },
      }
    );
    await axios.put(
      config.api + '/users/face',
      {
        faceId: 1,
      },
      {
        headers: {
          Authorization: `Bearer ${register.data.accessToken}`,
        },
      }
    );

    const { data } = await api().post<MutationKeyToType[K]['response']>(
      baseUrl + '/login',
      args
    );
    return data;
  };

  const reset = async (args: Reset) => {
    const { data } = await api().post<MutationKeyToType[K]['response']>(
      baseUrl + '/reset-password',
      args
    );
    return data;
  };

  const verificatePincode = async (args: Verificate) => {
    const { data } = await api().post<MutationKeyToType[K]['response']>(
      baseUrl + '/verificate-pincode',
      args
    );
    return data;
  };

  const changePasswordWithEmail = async (args: ResetWithEmail) => {
    const { data } = await api().post<MutationKeyToType[K]['response']>(
      baseUrl + '/change-password-with-email',
      args
    );
    return data;
  };

  const changePassword = async (args: ChangePassword) => {
    const { data } = await api().post<MutationKeyToType[K]['response']>(
      baseUrl + '/change-password',
      args
    );
    return data;
  };

  const mutations = {
    login,
    register,
    reset,
    verificatePincode,
    changePasswordWithEmail,
    changePassword,
  };

  if (!mutations.hasOwnProperty(key)) {
    throw new Error(`Invalid mutation key: ${key}`);
  }

  return useMutation<
    MutationKeyToType[K]['response'],
    unknown,
    MutationKeyToType[K]['args'],
    MutationTagType
  >(
    [key, ...tags],
    mutations[key] as unknown as MutationFunction<
      MutationKeyToType[K]['response'],
      MutationKeyToType[K]['args']
    >,
    options as any
  );
};
