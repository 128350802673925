import { Box } from '@mui/material';
import { useState } from 'react';
import { useTabButton } from '../DashboardPage.hooks';
import { TabType } from '../DashboardPage.types';
import { HotSales } from './MainTabs/HotSales';
import { Lottery } from './MainTabs/Lottery';
import { Staking } from './MainTabs/Staking';

export const MainTab = () => {
  const [selectedTabInfo, setSelectedTabInfo] = useState<TabType>('Hot Sales');

  const hotSalesButton = useTabButton({
    tab: 'Hot Sales',
    selectedTabInfo,
    setSelectedTabInfo,
  });
  const lotteryButton = useTabButton({
    tab: 'Lottery',
    selectedTabInfo,
    setSelectedTabInfo,
  });
  const stakingButton = useTabButton({
    tab: 'Staking',
    selectedTabInfo,
    setSelectedTabInfo,
  });

  return (
    <Box width="100%" display="flex" flexDirection="column" rowGap="10px">
      <Box display="flex" height="60px" columnGap="16px">
        {hotSalesButton}
        {lotteryButton}
        {stakingButton}
      </Box>
      {selectedTabInfo === 'Lottery' && <Lottery />}
      {selectedTabInfo === 'Hot Sales' && <HotSales />}
      {selectedTabInfo === 'Staking' && <Staking />}
    </Box>
  );
};
