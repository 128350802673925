import * as yup from 'yup';

export const SignUpSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email should have correct format')
    .required('Email is a required field'),
  agree: yup
    .boolean()
    .oneOf([true], 'You must agree to the terms and conditions'),
});
