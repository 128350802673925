import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import { TransferIcon } from 'assets/svg-components/Transfer';
import { BackButton } from 'components/pages/AuthPage/AuthPage.support';
import { useSwapTokens } from 'hooks/useSwapTokens';
import { FC, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { SwapTokenSymbol, useSwapStore } from 'stores/useSwapStore';
import { useUserWalletBalanceStore } from 'stores/useUserBalanceStore';
import { useUserItemsStore } from 'stores/useUserItemsStore';
import { buttonSx } from 'theme/sxProps';
import * as yup from 'yup';
import { formBoxSx, inputSx, selectSx } from '../../DashboardPage.shared';
import {
  CoinActionPageProps,
  WalletCoinsTypes,
} from '../../DashboardPage.types';
import { useNotify } from 'hooks/useToast';

export const SwapTabWallet: FC<CoinActionPageProps> = (props) => {
  const title = props.item.identifier.split('.').pop()!;
  const item = props.item;

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    tokenFrom,
    tokenTo,
    setTokenFrom,
    setTokenTo,
    filteredTokens,
    swapTokensList,
    swapTokenNames,
  } = useSwapStore();
  const { notify } = useNotify();
  const { refetch: coinsRefetch } = useUserWalletBalanceStore();
  const { swapTokens, tradeNotAllowed, tradePrice, liquidityError, setAmount } =
    useSwapTokens();
  const { currencyRefetch } = useUserItemsStore();
  const { shape, palette } = useTheme();
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    reset: resetFields,
    formState: { errors, isValid },
  } = useForm<{ amount: number }>({
    mode: 'all',
    defaultValues: { amount: 0 },
    resolver: yupResolver(
      yup.object().shape({
        amount: yup
          .number()
          .positive()
          .moreThan(0)
          .max(Number(item?.amount ?? 0), 'Not enough in your account')
          .required(),
      })
    ),
  });

  const onSuccess = useCallback(async () => {
    resetFields();
    setTimeout(() => {
      currencyRefetch();
      coinsRefetch();
    }, 1000);
    notify({ type: 'success', meassage: 'Swap success!' });
  }, [coinsRefetch, currencyRefetch, notify, resetFields]);

  const onError = useCallback(() => {
    notify({ type: 'error', meassage: 'Swap error!' });
  }, [notify]);

  const onSubmit: SubmitHandler<{ amount: number }> = useCallback(
    async ({ amount }) => {
      setLoading(true);
      try {
        await swapTokens(amount);
        onSuccess();
      } catch (e) {
        onError();
      } finally {
        setLoading(false);
      }
    },
    [onError, onSuccess, swapTokens]
  );

  const handleSelectCoinTab = (e: SelectChangeEvent<SwapTokenSymbol>) => {
    let value = e.target.value as SwapTokenSymbol;
    setTokenFrom(value);

    item.setSelectedTab(('Wallet.' + value) as WalletCoinsTypes);
  };

  let amount = watch('amount');

  useEffect(() => {
    setAmount(amount);
  }, [amount, setAmount, watch]);

  useEffect(() => {
    if (tradeNotAllowed) {
      notify({ type: 'error', meassage: 'Swap not allowed!' });
    }
    if (liquidityError) {
      notify({ type: 'error', meassage: 'Insufficient liquidity!' });
    }
  }, [liquidityError, notify, tradeNotAllowed]);

  return (
    <>
      <Box display="flex" alignItems="center">
        <BackButton
          sx={{ position: 'initial', width: '60px', height: '60px' }}
          handleBack={() => props.setPage('Default')}
        />
        <Typography variant="h1" ml="15px">
          Swap
        </Typography>
        <Box
          sx={{
            display: 'grid',
            placeItems: 'center',
            background: 'rgba(129, 138, 137, 0.1)',
            width: 110,
            height: 60,
            ml: 'auto',
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <Typography variant="h3"> {title.toUpperCase()}</Typography>
        </Box>
      </Box>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={formBoxSx}>
        <Box
          display="flex"
          flexDirection="column"
          gap="20px"
          width="100%"
          height="105px"
          p="15px"
          sx={{
            background: palette.background.card,
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ color: palette.text.disabled }}>From</Typography>
            <Typography sx={{ color: palette.text.disabled }}>
              Balance: {item?.amount}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ color: palette.secondary.main }}>
              <InputBase
                {...register('amount')}
                type="text"
                placeholder="0.00"
                error={!!errors.amount}
                sx={inputSx()}
              />
              {errors.amount?.message && (
                <Typography color="error">{errors.amount?.message}</Typography>
              )}
            </Typography>
            <Box display="flex">
              <Button
                onClick={() =>
                  item?.amount && setValue('amount', Number(item?.amount))
                }
                sx={{
                  ml: '20px',
                  color: palette.primary.main,
                  borderRadius: `${shape.borderRadius * 2}px`,
                }}
              >
                MAX
              </Button>
              <Select
                sx={selectSx}
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                value={tokenFrom}
                onChange={handleSelectCoinTab}
              >
                {swapTokensList.map((tokenName, i) => (
                  <MenuItem value={tokenName} key={i}>
                    {tokenName}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Box>

        <Box
          display="grid"
          top="25%"
          right="60px"
          width="60px"
          height="60px"
          sx={{
            placeItems: 'center',
            background: palette.primary.dark,
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <IconButton
            onClick={() => {
              swapTokenNames();
              item.setSelectedTab(`Wallet.${tokenTo}`);
            }}
          >
            <TransferIcon variant={2} />
          </IconButton>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap="20px"
          width="100%"
          height="105px"
          p="15px"
          sx={{
            background: palette.background.card,
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ color: palette.text.disabled }}>
              To (Estimate)
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" sx={{ color: palette.secondary.main }}>
              {tradePrice ?? 0}
            </Typography>
            <Select
              sx={selectSx}
              open={open2}
              onClose={() => setOpen2(false)}
              onOpen={() => setOpen2(true)}
              value={tokenTo}
              onChange={(e) => setTokenTo(e.target.value as any)}
            >
              {filteredTokens.map((name, i) => (
                <MenuItem value={name} key={i}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Box>

        <Button
          type="submit"
          sx={buttonSx({ mt: 'auto', width: '352px' })}
          disabled={!isValid || loading || tradeNotAllowed || liquidityError}
        >
          <Typography fontWeight={600}>
            {loading ? 'LOADING' : 'SWAP'}
          </Typography>
        </Button>
      </Box>
    </>
  );
};
