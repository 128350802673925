import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { handleAxiosError, useUserMutation } from 'api';
import { Input } from 'components/ui';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUserStore } from 'stores/useUserStore';
import { buttonSx } from 'theme/sxProps';
import * as yup from 'yup';

export const VerificatePincodeForm = (props: {
  setVerificateSuccess: (key: boolean) => void;
  setPincode: (key: string) => void;
}) => {
  const email = useUserStore((s) => s.info?.email!);

  const { setVerificateSuccess, setPincode } = props;
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ pincode: string }>({
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        pincode: yup
          .string()
          .length(5, 'Pincode length must be 5 units')
          .required('Pincode is a required field'),
      })
    ),
  });

  const {
    mutate,
    isSuccess,
    isError,
    error: mutateError,
  } = useUserMutation('verificatePincode');

  const onSubmit: SubmitHandler<{ pincode: string }> = useCallback(
    ({ pincode }) => {
      setPincode(pincode);
      mutate({ email, pincode });
    },
    [email, mutate, setPincode]
  );

  useEffect(() => {
    if (isSuccess) {
      setVerificateSuccess(isSuccess);
    }
  }, [isSuccess, setVerificateSuccess]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} marginBottom={5}>
        <Input
          text="Pincode"
          type="text"
          placeholder="11111"
          fullWidth
          required
          message={errors.pincode?.message || handleAxiosError(mutateError)}
          error={!!errors.pincode || isError}
          {...register('pincode')}
        />
        <LoadingButton
          fullWidth
          type="submit"
          sx={buttonSx({ mb: '16px' })}
          disabled={!isValid}
        >
          <Typography variant="h5" fontWeight="700">
            Send
          </Typography>
        </LoadingButton>
      </Stack>
    </Box>
  );
};
