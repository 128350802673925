import { moil, busd, bnb } from 'constants/index';
import { useEffect, useMemo } from 'react';
import { create, useStore } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export type SwapTokenSymbol = 'bnb' | 'moil' | 'busd';

interface SwapStore {
  tokenFrom: SwapTokenSymbol;
  setTokenFrom: (payload: SwapTokenSymbol) => void;
  tokenTo: SwapTokenSymbol;
  setTokenTo: (payload: SwapTokenSymbol) => void;
}

export const store = create(
  persist<SwapStore>(
    (set, get) => ({
      tokenFrom: 'moil',
      tokenTo: 'busd',
      setTokenFrom: (payload) => {
        set({ tokenFrom: payload });
      },
      setTokenTo: (payload) => {
        set({ tokenTo: payload });
      },
    }),
    {
      name: 'moovy-user-selected-swap-tokens',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const useSwapStore = () => {
  const { setTokenFrom, setTokenTo, tokenFrom, tokenTo } = useStore(store);

  const swapTokensList: SwapTokenSymbol[] = useMemo(
    () => [bnb, busd, moil],
    []
  );
  const filteredTokens = swapTokensList.filter((name) => name !== tokenFrom);

  useEffect(() => {
    if (tokenFrom === tokenTo) {
      setTokenTo(swapTokensList.filter((name) => name !== tokenFrom)[0]);
    }
  }, [setTokenTo, swapTokensList, tokenFrom, tokenTo]);

  const swapTokenNames = () => {
    let tempToken = tokenFrom;
    setTokenFrom(tokenTo);
    setTokenTo(tempToken);
  };

  return {
    swapTokensList,
    setTokenFrom,
    setTokenTo,
    tokenFrom,
    tokenTo,
    filteredTokens,
    swapTokenNames,
  };
};
