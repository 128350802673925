import React from 'react';

export const TabIcon = (props: {
  fill?: string;
  height?: number;
  width?: number;
}) => {
  const width = 18;
  const height = 18;
  const defaultColor = '#21E7D6';

  return (
    <svg
      width={props?.width ?? width}
      height={props?.height ?? height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0308 0.330301C11.0345 -0.1101 8.96552 -0.1101 6.96917 0.3303L6.55155 0.422427C3.76343 1.03749 1.56534 3.17136 0.876983 5.93119C0.374339 7.94645 0.374339 10.0536 0.876983 12.0688C1.56534 14.8286 3.76343 16.9625 6.55155 17.5776L6.96917 17.6697C8.96552 18.1101 11.0345 18.1101 13.0308 17.6697L13.4484 17.5776C16.2366 16.9625 18.4347 14.8286 19.123 12.0688C19.6257 10.0535 19.6257 7.94645 19.123 5.9312C18.4347 3.17136 16.2366 1.03749 13.4484 0.422426L13.0308 0.330301ZM15.9433 6.80778C16.3203 6.74389 16.702 6.72114 17.0807 6.73871C17.4968 6.75801 17.8243 7.08253 17.8802 7.49359C18.0163 8.49327 18.0163 9.50673 17.8802 10.5064C17.8243 10.9175 17.4968 11.242 17.0807 11.2613C16.702 11.2789 16.3203 11.2561 15.9433 11.1922L15.8694 11.1797C14.8874 11.0133 14.1287 10.3507 13.8722 9.51589C13.7684 9.1783 13.7684 8.8217 13.8722 8.48411C14.1287 7.64926 14.8874 6.98674 15.8694 6.82032L15.9433 6.80778ZM5.34559 5.97732C5.34559 5.64344 5.61739 5.37278 5.95269 5.37278L10 5.37278C10.3353 5.37278 10.6071 5.64344 10.6071 5.97732C10.6071 6.3112 10.3353 6.58186 10 6.58186H5.95269C5.61739 6.58186 5.34559 6.3112 5.34559 5.97732Z"
        fill={props?.fill ?? defaultColor}
      />
    </svg>
  );
};
