import React from 'react';

export const GalleryIcon = (props: {
  fill?: string;
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={props?.width ?? 23}
      height={props?.height ?? 20}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0161 9.60738C13.0116 8.23278 14.1223 7.11483 15.4969 7.11036C16.8715 7.1059 17.9895 8.21661 17.9939 9.59121C17.9984 10.9658 16.8877 12.0838 15.5131 12.0882C14.1385 12.0927 13.0205 10.982 13.0161 9.60738ZM15.5006 8.2591C14.7605 8.2615 14.1624 8.86348 14.1648 9.60364C14.1672 10.3438 14.7692 10.9419 15.5093 10.9395C16.2495 10.9371 16.8476 10.3351 16.8452 9.59494C16.8428 8.85477 16.2408 8.25669 15.5006 8.2591ZM20.9155 16.644C21.0789 16.2527 21.2109 15.8445 21.3084 15.4226C21.7603 13.4675 21.7537 11.4343 21.2891 9.48214C20.6181 6.66297 18.4081 4.46721 15.5846 3.81459C13.6295 3.36267 11.5963 3.36927 9.64411 3.83388C6.82494 4.50483 4.62919 6.7149 3.97656 9.53836C3.52464 11.4935 3.53125 13.5267 3.99586 15.4788C4.04512 15.6858 4.10268 15.8895 4.16817 16.0894L4.77 15.4837C6.94665 13.2933 10.4871 13.2818 12.678 15.4581L14.3741 17.1429L14.8159 16.6983C16.5085 14.995 19.2344 15.0061 20.9155 16.644ZM20.3606 17.7355L20.3359 17.7061C19.1309 16.2698 16.9523 16.1781 15.6307 17.5081L15.1891 17.9525L17.6103 20.3575C18.7241 19.7319 19.6624 18.8363 20.339 17.7575C20.3464 17.7503 20.3536 17.743 20.3606 17.7355ZM16.4962 20.8699L11.8684 16.2731C10.1276 14.5439 7.31435 14.553 5.58484 16.2935L4.64981 17.2344C5.66594 19.1871 7.49624 20.6369 9.70034 21.1464C11.6555 21.5983 13.6887 21.5917 15.6408 21.1271C15.933 21.0576 16.2184 20.9715 16.4962 20.8699Z"
        fill={props?.fill ?? '#89D7D3'}
      />
    </svg>
  );
};
