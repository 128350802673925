import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
  AccordionProps,
  Accordion as MuiAccordion,
  styled,
} from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  background: 'transparent',
  borderRadius: `${theme.shape.borderRadius}px`,
  border: `none`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const Summary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  height: '60px',
  borderRadius: `${theme.shape.borderRadius}px`,
  background: theme.palette.background.card,
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const Details = styled(MuiAccordionDetails)(({ theme }) => ({
  borderRadius: `${theme.shape.borderRadius}px`,
  background: theme.palette.background.paper,
  padding: theme.spacing(2),
  marginTop: '4px',
}));

export { Details, Accordion, Summary };
