import { Box, styled } from '@mui/material';

export const AccountAbout = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  columnGap: 8,
  width: '300px',
  padding: 8,
  paddingInline: 25,
  borderInline: `2px solid ${theme.palette.background.default}`,
}));

export const RightSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100%',
  marginLeft: 'auto',
}));
