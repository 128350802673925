import { Box } from '@mui/material';

export const GridList = ({
  height,
  children,
}: {
  height: number;
  children: React.ReactElement;
}) => {
  return (
    <Box
      sx={{
        background:
          'linear-gradient(98.27deg, rgba(33, 231, 214, 0.08) 0%, rgba(131, 218, 255, 0.08) 105.42%);',
      }}
      borderRadius={1}
      p={2}
    >
      <Box
        maxHeight={height}
        sx={{
          overflow: 'auto',
          paddingRight: '5px',
          '&::-webkit-scrollbar': {
            width: '0.4em',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(131, 218, 255, 0.16)',
            borderRadius: 2,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'cyan',
            borderRadius: 2,
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
