import { Rarity } from 'hooks';

export type NFTQuerySortByType =
  | 'viewed'
  | 'lastSalePrice'
  | 'cheapest'
  | 'price'
  | 'saleEndsAt'
  | 'soldAt'
  | 'listedAt'
  | 'oldest'
  | 'createdAt';

export type NFTQueryFilterByType = (
  | 'butNow'
  | 'hasBids'
  | 'hasOffers'
  | 'onAuction'
)[];

export type NFTTokenType = {
  _id: string;
  contentType: string;
  contractAddress: string;
  imageURL: string;
  isAppropriate: boolean;
  lastSalePrice: number;
  lastSalePriceInUSD: number;
  lastSalePricePaymentToken: string;
  liked: number;
  name: string;
  paymentToken: string;
  price: number;
  priceInUSD: number;
  supply: number;
  thumbnailPath: string;
  tokenID: number;
  tokenType: number;
  tokenURI: string;
};

export interface NFTItemResponse {
  description: string;
  image: string;
  name: string;
  properties: {
    IP_Rights: string;
    address: string;
    collection: string;
    createdAt: string;
    recipient: string;
    royalty: number;
    symbol: string;
  };
}

export interface Login {
  email: string;
  password: string;
}

export interface OpenContainer {
  userGarageItemContainerId: number;
}

export interface OpenContainerResponse {
  id: number;
  isActive: boolean;
  burnable: boolean;
  mintable: boolean;
  sendable: boolean;
  breeds_count: number;
  rarity: {
    id: number;
    name: string;
  };
  model: {
    id: number;
    name: string;
    brand: {
      id: number;
      name: string;
    };
    type: {
      id: number;
      name: string;
    };
  };
  characteristics: [
    {
      id: number;
      name: string;
      value: number;
    }
  ];
  visual: {
    id: number;
    name: string;
    assets: [
      {
        id: number;
        name: string;
        asset: {
          url: string;
        };
      }
    ];
  };
  numberplate: {
    id: number;
    value: number;
    wave: number;
    userGarageItemId: number;
  };
}

export interface CrossBreeding {
  car1Id: number;
  car2Id: number;
}
export interface CrossBreedingResponse {
  message: string;
  data: {
    rarity: {
      name: Rarity;
    };
    rarity_id: number;
  };
}
export interface CrossBreedingPreviewResponse {
  bridePrice: number;
}

export interface Register {
  wallet: string;
  email: string;
  password: string;
  bbid: string;
}

export interface Verificate {
  email: string;
  pincode: string;
}

export interface Reset {
  email: string;
}

export interface Register {
  wallet: string;
  email: string;
  password: string;
  bbid: string;
}

export interface ResetWithEmail {
  email: string;
  newPassword: string;
  pincode: string;
}

export interface ChangePassword {
  oldPassword: string;
  newPassword: string;
}

export interface LoginResponse {
  message: string;
  data: {
    user: {
      id: string;
      bbid: string;
      wallet: string;
      createdAt: string;
      updatedAt: string;
      games: string[];
    };
    accessToken: string;
  };
}

export interface RegisterResponse extends LoginResponse {}

export interface UsersAuthorized {
  message: string;
  data: {
    id: number;
    avatar: string;
    face: {
      id: number;
      asset: {
        url: string;
      };
    };
    name: string;
    experience: number;
    level: number;
    isCreatedFreeCar: boolean;
    skills: {
      id: number;
      name: string;
      category: string;
      characteristics: [
        {
          id: number;
          name: string;
          value: number;
          multiplier: number;
          level: number;
        }
      ];
    }[];
    talentPoints: {
      id: number;
      category: string;
    }[];
    baseCharacteristics: {
      id: number;
      name: string;
      value: number;
    }[];
    levelUpCharacteristics: {
      id: number;
      name: string;
      value: number;
    }[];
    totalCharacteristics: {
      name: string;
      value: number;
    }[];
  };
}

export interface UsersGarage {
  message: string;
  data: {
    id: number;
    info: {
      id: number;
      carPlaces: number;
      storage: number;
      spareParts: number;
      rarity: string;
      assets: {
        main: {
          url: string;
        };
        layer: {
          url: string;
        };
      };
    };
  };
}

export interface UsersCars {
  message: string;
  data: Car[];
}

interface Market_on_sale {
  car_to_sale_id: number;
  category_id: number;
  id: number;
  item_to_sale_id: null;
  listed_at: string;
  listed_by_user_id: number;
  price: number;
}

export type Car = {
  id: number;
  breeds_count: number;
  isActive: boolean;
  burnable: boolean;
  mintable: boolean;
  sales_on_market: Market_on_sale | undefined;
  sendable: boolean;
  fuel: number;
  rarity: {
    id: number;
    name: string;
  };
  model: {
    id: number;
    name: string;
    brand: {
      id: number;
      name: string;
    };
    type: {
      id: number;
      name: string;
    };
  };
  characteristics: [
    {
      id: number;
      name: string;
      value: number;
    }
  ];
  visual: {
    id: number;
    name: string;
    assets: [
      {
        id: number;
        name: string;
        asset: {
          url: string;
        };
      }
    ];
  };
  numberplate: {
    id: number;
    value: number;
    wave: number;
    userGarageItemId: number;
  };
  carCapacity: {
    skill: number;
    talent: number;
    base: number;
    total: number;
  };
  distance: {
    max: number;
    remaining: number;
  };
};
export interface UsersCarsSale {
  message: string;
  data: CarSale[];
}
export type CarSale = {
  item_to_sale: any;
  car_to_sale_id: number;
  id: number;
  listed_at: string;
  listed_by_user_id: number;
  price: number;
  car_to_sale: {
    id: number;
    breeds_count: number;
    isActive: boolean;
    burnable: boolean;
    mintable: boolean;
    sendable: boolean;
    fuel: number;
    rarity: {
      id: number;
      name: string;
    };
    model: {
      id: number;
      name: string;
      brand: {
        id: number;
        name: string;
      };
      type: {
        id: number;
        name: string;
      };
    };
    characteristics: [
      {
        id: number;
        name: string;
        value: number;
      }
    ];
    visual: {
      id: number;
      name: string;
      assets: [
        {
          id: number;
          name: string;
          asset: {
            url: string;
          };
        }
      ];
    };
    numberplate: {
      id: number;
      value: number;
      wave: number;
      userGarageItemId: number;
    } | null;
    // carCapacity: {
    //   skill: number;
    //   talent: number;
    //   base: number;
    //   total: number;
    // };
    // distance: {
    //   max: number;
    //   remaining: number;
    // };
  };
};

export interface Container {
  id: number;
  amount: number;
  item: {
    itemId: number;
    name: string;
    openable: boolean;
    burnable: boolean;
    wearable: boolean;
    container: {
      rarity_id: number;
      rarity: {
        name: Rarity;
      };
    };
  };
  burned: false;
  weared: false;
  sales_on_market: Market_on_sale | undefined;
}

export interface Container {
  id: number;
  amount: number;
  item: {
    itemId: number;
    name: string;
    openable: boolean;
    burnable: boolean;
    wearable: boolean;
    container: {
      rarity_id: number;
      rarity: {
        name: Rarity;
      };
    };
  };
  burned: false;
  weared: false;
}

export type ItemType = {
  id: number;
  amount: number;
  burned: boolean;
  weared: boolean;
  item: {
    itemId: number;
    name: string;
    openable: boolean;
    burnable: boolean;
    wearable: boolean;
    numberplate: {
      id: number;
      value: number;
      wave: number;
    };
    container: {
      rarity: {
        name: Rarity;
      };
      rarity_id: number;
    };
  };
  sales_on_market: Market_on_sale | undefined;
};
export interface UsersItemsCurrency {
  message: string;
  data: ItemType[];
}

export interface UsersItemsExpandable {
  message: string;
  data: ItemType[];
}

export interface UsersItemsStorage {
  message: string;
  data: ItemType[];
}

export interface UsersItemsTransactionWithdraw {
  message: string;
  data: {
    id: string;
  };
}

export type TransactionWithdrawType = { amount: number; itemId: number };

export interface BBIDResponse {
  message: 'string';
  data: {
    id: string;
    bbid: string;
  };
}

export interface IToken {
  tokenId: string;
  isActive: boolean;
  symbol: string;
  decimals: number;
  isNative: boolean;
  contractAddress: string | null;
  networkId: string;
  shortenNetworkName?: string;
  // icon: any;
}

export interface INetwork {
  id: string;
  name: string;
  shortName: string;
  rcp: string;
  chainId: number;
  explorer: string;
  icon: string;
  token: {
    id: string;
    symbol: string;
    decimals: number;
  };
}

export enum MODAL_TYPE {
  'DeleteFromMarket',
  'AddToMarket',
}
