import {
  Box,
  Button,
  ButtonBase,
  Grid,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from '@mui/material';
import { CryptoCoinIcon } from 'assets/svg-components/CryptoCoin';
import { QuestionMarkIcon } from 'assets/svg-components/QuestionMark';
import { pancakeSwapLink } from 'constants/index';
import { useState } from 'react';
import { buttonSx } from 'theme/sxProps';
import { useGridItem } from '../DashboardPage.hooks';
import { ListType } from '../DashboardPage.types';

export const InfoWalletTab = (props: {
  listBottom: ListType<JSX.Element>[];
  listTop: ListType<string>[];
}) => {
  const { listBottom, listTop } = props;
  const { palette, shape } = useTheme();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        my="10px"
      >
        <Typography variant="h4">
          Wallet Account
          <IconButton onClick={handleOpen}>
            <QuestionMarkIcon />
          </IconButton>
        </Typography>
        <ButtonBase
          onClick={(e) => {
            e.preventDefault();
            window.open(pancakeSwapLink, '_blank');
          }}
          sx={buttonSx({
            height: '44px',
            width: '90px',
            borderRadius: `${shape.borderRadius * 2}px`,
          })}
        >
          <CryptoCoinIcon />
          <Typography ml={0.5} variant="h5" fontWeight={700}>
            Buy
          </Typography>
        </ButtonBase>
      </Box>
      <Grid container spacing={2}>
        {listTop.map(useGridItem<string>)}
      </Grid>
      <Grid container spacing={2}>
        {listBottom.map(useGridItem<JSX.Element>)}
      </Grid>

      <Modal open={open} onClose={handleClose} disableEnforceFocus>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 450,
            bgcolor: 'background.paper',
            border: `2px solid ${palette.text.disabled}`,
            borderRadius: `${shape.borderRadius * 2}px`,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography textAlign="center" variant="h4" component="h2">
            MOIL gaming account
          </Typography>
          <Typography textAlign="center" sx={{ my: 2 }}>
            Users need MOIL in this account to make any purchase on the
            marketplace. <br /> Show past 30 days transactions
          </Typography>
          <Button
            onClick={handleClose}
            sx={buttonSx({
              height: '60px',
              width: '300px',
              margin: '0 auto',
              color: palette.primary.main,
              background: palette.gradient.green,
            })}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};
