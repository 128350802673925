import React from 'react';

export const ArrowRightUpIcon = (props: {
  fill?: string;
  height?: number;
  width?: number;
}) => {
  const width = 18;
  const height = 18;
  const defaultColor = 'black';

  return (
    <svg
      width={props?.width ?? width}
      height={props?.height ?? height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2901 4.25209L8.36271 4.18988C7.36235 4.1874 6.54938 3.37443 6.54689 2.37407C6.54441 1.3737 7.35335 0.56476 8.35371 0.567245L17.4103 0.589738L17.4328 9.64633C17.4353 10.6467 16.6263 11.4556 15.626 11.4532C14.6256 11.4507 13.8126 10.6377 13.8102 9.63734L13.803 6.76497L3.42237 17.036C2.71677 17.7416 1.62498 17.7938 0.915856 17.0847C0.206735 16.3756 0.25896 15.2838 0.964566 14.5782L11.2901 4.25209Z"
        fill={props.fill ?? defaultColor}
      />
    </svg>
  );
};
