import { QueryFunction, useQuery } from '@tanstack/react-query';
import { QueryOptionsType, QueryTagType } from 'api/types';
import { UsersAuthorized, UsersCars, UsersGarage } from 'types/models';
import { useAxios } from '../axios';

// Define a mapping from keys to types
type QueryKeyToType = {
  authorized: UsersAuthorized;
  garage: UsersGarage;
  cars: UsersCars;
};

type QueryKey = keyof QueryKeyToType;

export const useUserQuery = <K extends QueryKey>(
  key: K,
  options?: QueryOptionsType<QueryKeyToType[K]>,
  tags: string[] = []
) => {
  const { api } = useAxios();

  const authorized = async () => {
    const { data } = await api().get<QueryKeyToType['authorized']>(
      '/users/authorized'
    );
    return data;
  };

  const garage = async () => {
    const { data } = await api().get<QueryKeyToType['garage']>('/users/garage');
    return data;
  };

  const cars = async () => {
    const { data } = await api().get<QueryKeyToType['cars']>('/users/cars');
    return data;
  };

  const queries = {
    authorized,
    garage,
    cars,
  };

  if (!queries.hasOwnProperty(key)) {
    throw new Error(`Invalid query key: ${key}`);
  }
  return useQuery<QueryKeyToType[K], unknown, QueryKeyToType[K], QueryTagType>(
    [key as any, ...tags],
    queries[key] as unknown as QueryFunction<QueryKeyToType[K], QueryTagType>,
    options
  );
};
