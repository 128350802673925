export const stakingLink = 'https://staking.moovy.io/#/';
export const pancakeSwapLink = 'https://pancakeswap.finance/swap';
export const moil = 'moil';
export const mgas = 'mgas';
export const numberplate = 'numberplate';
export const container = 'tme container';
export const moilToMgasExchangeRate = 2;
export const mgasToMoilExchangeRate = 0.5;
export const bnb = 'bnb';
export const busd = 'busd';

export const minimumTradeAmount = 0.000001;

export const labelColors: Record<string, string> = {
  classic: '#939393',
  epic: '#4398FF',
  rare: '#7FFF52',
  insane: '#FFA71A',
  legendary: '#E087FF',
};

export const MAX_BREED_COUNT = 7;

