import { useQuery } from '@tanstack/react-query';
import { QueryOptionsType, QueryTagType } from 'api/types';
import { useAxios } from '../axios';
import { BBIDResponse } from 'types/models';

// Define a mapping from keys to types
type QueryKeyToType = {
  get: BBIDResponse;
};
type QueryKey = keyof QueryKeyToType;

export const useBbidQuery = <K extends QueryKey>(
  key: K,
  options?: QueryOptionsType<QueryKeyToType[K]>,
  tags: string[] = []
) => {
  const { api } = useAxios();

  const get = async () => {
    const { data } = await api().get<QueryKeyToType['get']>('/bbid');
    return data;
  };

  const queries = {
    get,
  };

  if (!queries.hasOwnProperty(key)) {
    throw new Error(`Invalid query key: ${key}`);
  }
  return useQuery<QueryKeyToType[K], unknown, QueryKeyToType[K], QueryTagType>(
    [key as any, ...tags],
    queries[key],
    options
  );
};
