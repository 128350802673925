import { Modal } from '@mui/material';
import { StyledNumberInput } from 'components/pages/DashboardPage/Tabs/CoinTabs/TransferTab';
import { useState } from 'react';
import styled from 'styled-components';
import { MODAL_TYPE } from 'types/models';
import { bool, boolean, string } from 'yup';
interface PropsTypes {
  isOpenModal: {
    isOpen: boolean;
    type: MODAL_TYPE | undefined;
    handleClick?: (v?: number) => void;
  };
  onClose: () => void;
}

// AddCarMarket
const ModalConfirmation = ({ isOpenModal, onClose }: PropsTypes) => {
  const [price, setPrice] = useState<number | null>(null);
  const [errorInput, setErrorInput] = useState('');
  const [isDisabled, setIsDisabled] = useState(false);
  const { isOpen, type, handleClick } = isOpenModal;
  const handleBuy = () => {
    if (type === MODAL_TYPE.AddToMarket) {
      handleClick && price && handleClick(price);
    } else {
      handleClick && handleClick();
    }
    setPrice(null);
    onClose();
  };

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    
    if (value === '' || (Number(value) <= 9999999 && !isNaN(Number(value)))) {
      setPrice(Number(event.target.value))
      setErrorInput('')
      setIsDisabled(false)
    } else {
      setErrorInput('The maximum number is 9999999')
      setIsDisabled(true)
    }
    if (String(value).length < 1) {
      setIsDisabled(true)
    }
  };
  
  
  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ModalBackdrop>
          <ModalBox>
            <Title>Are you sure?</Title>
            {type === MODAL_TYPE.AddToMarket && (
              <>
                <InputTitle>Enter price</InputTitle>
                <InputBox>
                  <StyledNumberInput
                    type="number"
                    style={{ padding: 10, width: '100%' }}
                    placeholder="Enter your price"
                    autoComplete="off"
                    onChange={handleInputChange}
                  />
                </InputBox>
                {errorInput.length > 0 && <InputError>{errorInput}</InputError>}
              </>
            )}
            <BtnBox>
              <BuyBtn onClick={onClose}>Cancel</BuyBtn>
              <BuyBtn
                onClick={handleBuy}
                disabled={isDisabled}
              >
                Confirm
              </BuyBtn>
            </BtnBox>
          </ModalBox>
        </ModalBackdrop>
      </Modal>
    </>
  );
};

export default ModalConfirmation;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
`;
const ModalBox = styled.div`
  border-radius: 10px;
  padding: 30px;
  background: linear-gradient(135deg, #141313 0%, #18191b 100%);
  box-shadow: 0px 3.6074767112731934px 18.037384033203125px 0px
    rgba(0, 0, 0, 0.5);
  width: 340px;
  border: 2px solid #1d1d1d;
`;
const Title = styled.h2`
  text-align: center;
  margin: 0;
  margin-bottom: 40px;
`;
const InputTitle = styled.h2`
  margin: 0;
  font-size: 14px;
`;
const InputError = styled.h2`
  margin: 0;
  font-size: 10px;
  color: red;
`;
const BtnBox = styled.div`
  display: flex;
  gap: 3%;
  justify-content: center;
  margin-top: 20px;
`;
const InputBox = styled.div`
  justify-content: center;
  align-item: center;
  padding: 0;
  border: 2px solid #1d1d1d;
`;
const BuyBtn = styled.button`
  text-align: center;
  width: 45%;
  height: 42px;
  padding: 10px 15px;
  border-radius: 15px;
  background: linear-gradient(180deg, #21e7d6 0%, #83daff 100%);
  color: black;
  font-family: 'Fact';
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: none;
`;


