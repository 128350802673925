import { Box, Modal, Typography } from '@mui/material';
import {
  ContinueButton,
  Dot,
  Label,
} from 'components/pages/DashboardPage/Tabs/styles';
import { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from 'utils/textUtils';
//@ts-ignore
import { labelColors } from '../../constants';

export const CongratulationModal = ({
  open,
  onClose,
  rarity,
  label = true,
  backgroundUrl,
  children,
  videoUrl,
  videoTimeout,
}: {
  open: boolean;
  onClose: () => void;
  rarity: string;
  label?: boolean;
  backgroundUrl: string;
  children?: React.ReactNode;
  videoUrl?: string;
  videoTimeout?: number;
}) => {
  const [videoPlaying, setVideoPlaying] = useState(!!videoUrl);

  useEffect(() => {
    if (videoUrl) {
      setVideoPlaying(true);
      setTimeout(() => {
        setVideoPlaying(false);
      }, videoTimeout || 3000);
    }
  }, []);

  return (
    <Modal open={open} onClose={onClose} disableEnforceFocus>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80vw',
          height: '80vh',
          borderRadius: '5px',
          border: '1px solid #202526',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        {videoPlaying ? (
          <video
            style={{ width: '100%', height: '100%' }}
            width="100%"
            src={videoUrl}
            autoPlay
          ></video>
        ) : (
          <Box
            sx={{
              backgroundImage: `url(${backgroundUrl})`,
              backgroundSize: 'cover',
              // background: '#18191B',
              width: '100%',
              height: '100%',
              position: 'relative',
              borderRadius: '10px',
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                left: '50%',
                top: '5%',
                transform: 'translate(-50%, 0%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Typography
                variant="h1"
                sx={{ fontSize: 46, fontWeight: 'bold', color: 'primary.main' }}
              >
                Congratulation!
              </Typography>
              {label && (
                <Label>
                  <Dot sx={{ background: labelColors[rarity] }} />
                  {capitalizeFirstLetter(rarity)}
                </Label>
              )}
            </Box>
            {children}
            <ContinueButton
              onClick={onClose}
              variant="contained"
              color="primary"
            >
              Continue
            </ContinueButton>
          </Box>
        )}
      </Box>
    </Modal>
  );
};
