import classic from '../assets/images/containers/classic.png';
import epic from '../assets/images/containers/epic.png';
import insane from '../assets/images/containers/insane.png';
import legendary from '../assets/images/containers/legendary.png';
import rare from '../assets/images/containers/rare.png';

export const containers: Record<string, string> = {
  classic,
  epic,
  rare,
  insane,
  legendary,
};
