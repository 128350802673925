import { MutationFunction, useMutation } from '@tanstack/react-query';
import { MutationOptionsType, MutationTagType } from 'api/types';
import { CrossBreeding, CrossBreedingResponse } from 'types/models';
import { useAxios } from '../axios';

type MutationKeyToType = {
  setMarketCars: {
    args: { carId: number; price: number };
    response: CrossBreedingResponse;
  };
  setMarketItems: {
    args: { itemId: number; price: number };
    response: CrossBreedingResponse;
  };
  buyMarketItems: {
    args: { marketItemId: number };
    response: CrossBreedingResponse;
  };
  deleteMarketItems: {
    args: { marketItemId: number };
    response: CrossBreedingResponse;
  };
};

type MutationKey = keyof MutationKeyToType;

export const useMarketMutation = <K extends MutationKey>(
  key: K,
  options?: MutationOptionsType<MutationKeyToType[K]['response']>,
  tags: string[] = []
) => {
  const { api } = useAxios();
  const baseUrl = '/market';

  const setMarketCars = async ({
    carId,
    price,
  }: {
    carId: number;
    price: number;
  }) => {
    const { data } = await api().post<MutationKeyToType[K]['response']>(
      baseUrl + '/cars',
      { carId, price }
    );
    return data;
  };

  const setMarketItems = async ({
    itemId,
    price,
  }: {
    itemId: number;
    price: number;
  }) => {
    const { data } = await api().post<MutationKeyToType[K]['response']>(
      baseUrl + '/items',
      { itemId, price }
    );
    return data;
  };
  const buyMarketItems = async ({ marketItemId }: { marketItemId: number }) => {
    const { data } = await api().post<MutationKeyToType[K]['response']>(
      baseUrl + `/${marketItemId}/buy`
    );
    return data;
  };

  const deleteMarketItems = async ({
    marketItemId,
  }: {
    marketItemId: number;
  }) => {
    const { data } = await api().delete<MutationKeyToType[K]['response']>(
      baseUrl + `/${marketItemId}`
    );
    return data;
  };

  const mutations = {
    setMarketCars,
    setMarketItems,
    buyMarketItems,
    deleteMarketItems,
  };

  if (!mutations.hasOwnProperty(key)) {
    throw new Error(`Invalid mutation key: ${key}`);
  }

  return useMutation<
    MutationKeyToType[K]['response'],
    unknown,
    MutationKeyToType[K]['args'],
    MutationTagType
  >(
    [key, ...tags],
    mutations[key] as unknown as MutationFunction<
      MutationKeyToType[K]['response'],
      MutationKeyToType[K]['args']
    >,
    options as any
  );
};
