import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useUserQuery } from 'api';
import { useMarketMutation } from 'api/mutations/marketMutation';
import { ConsumptionIcon } from 'assets/svg-components/Consumption';
import { DiscanteIcon } from 'assets/svg-components/Distance';
import { EfficiencyIcon } from 'assets/svg-components/Efficiency';
import { FuelIcon } from 'assets/svg-components/Fuel';
import { MarketInteractor } from 'components/pages/Marketplace/interactor';
import { GridCardItem } from 'components/ui/GridCard';
import { useNotify } from 'hooks/useToast';
import { useState } from 'react';
import { useUserStore } from 'stores/useUserStore';
import { Car, MODAL_TYPE } from 'types/models';
import { capitalizeFirstLetter } from 'utils/textUtils';
import Moovy from '../../../../../assets/images/moovy.svg';
import { labelColors } from '../../../../../constants';
import { Dot, Property } from '../styles';

export const CarItem = ({
  car,
  onClick,
  selectedCars,
  isSelectEnabled,
}: {
  car: Car;
  onClick: (car: Car) => void;
  selectedCars: number[];
  isSelectEnabled: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setIsOpenModal } = useUserStore();
  const { mutateAsync: setMarketCars } = useMarketMutation('setMarketCars');
  const { mutateAsync: unlist } = useMarketMutation('deleteMarketItems');
  const { refetch } = useUserQuery('cars', { enabled: true });
  const { notify } = useNotify();

  const { fethMarketCars } = MarketInteractor();

  const handleList = (type: MODAL_TYPE) => {
    setIsOpenModal({
      isOpen: true,
      type: type,
      handleClick: (value: number | undefined) => {
        setIsLoading(true);
        if (value && type === MODAL_TYPE.AddToMarket) {
          listOnMarket(value);
        }
        if (type === MODAL_TYPE.DeleteFromMarket) {
          deleteFromMarket();
        }
      },
    });
  };

  const listOnMarket = (value: number) => {
    setMarketCars({
      carId: car.id,
      price: value,
    })
      .then((res: any) => {
        notify({ type: 'success', meassage: 'Car successfully listes' });
        refetch();
        setTimeout(() => fethMarketCars(), 2000);
      })
      .catch((err: any) => {
        console.log('[list error] : ', err);
      })
      .finally(() => setTimeout(() => setIsLoading(false), 1000));
  };

  const deleteFromMarket = () => {
    if (!car.sales_on_market)
      return notify({
        type: 'info',
        meassage: 'Car not on market',
      });

    unlist({ marketItemId: car.sales_on_market.id })
      .then((res: any) => {
        notify({
          type: 'success',
          meassage: 'Car successfully unlisted from market',
        });
        refetch();
        setTimeout(() => fethMarketCars(), 2000);
      })
      .catch((err: any) => {
        console.log('unlist error] : ', err);
      })
      .finally(() => setTimeout(() => setIsLoading(false), 1000));
  };

  return (
    <GridCardItem
      selected={selectedCars.includes(car.id)}
      img={car.visual.assets.find((a) => a.name === 'garage')?.asset.url || ''}
      onClick={() => onClick(car)}
      sx={{ cursor: isSelectEnabled ? 'pointer' : 'default' }}
    >
      <>
        <Property item xs={7.5}>
          <DiscanteIcon />
          {(
            ((car.characteristics.find((c) => c.name === 'tank')?.value || 1) /
              (car.characteristics.find((c) => c.name === 'consumption')
                ?.value || 1)) *
            100
          ).toFixed()}{' '}
          km
        </Property>
        <Property item xs={7.5}>
          <FuelIcon />
          {car.characteristics.find((c) => c.name === 'tank')?.value}
        </Property>
        <Property item xs={7.5}>
          <ConsumptionIcon />
          {car.characteristics
            .find((c) => c.name === 'consumption')
            ?.value.toFixed()}
        </Property>
        <Property item xs={7.5}>
          <EfficiencyIcon />
          {car.characteristics
            .find((c) => c.name === 'efficiency')
            ?.value.toFixed()}
        </Property>
        {!isSelectEnabled && (
          <Property mt={2} item xs={15.5}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              width={'100%'}
            >
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                gap={1}
              >
                <img src={Moovy} height={16} alt="" />
                <Typography fontWeight={'bold'}>{car.model.name}</Typography>
              </Box>
              <Typography fontWeight={'bold'} color={'gray'}>
                {car.model.brand.name}
              </Typography>
            </Box>
          </Property>
        )}

        <Property item xs={7.5}>
          <Dot sx={{ background: labelColors[car.rarity.name] }} />
          {capitalizeFirstLetter(car.rarity.name)}
        </Property>
        <Property item xs={7.5}>
          {car.model.type.name}
        </Property>
        <Property item xs={isSelectEnabled ? 16 : 7.5}>
          {car.breeds_count} / 7
        </Property>
        {!isSelectEnabled && (
          <>
            {car.sales_on_market ? (
              <Property padding={'0px'} item xs={7.5}>
                {isLoading ? (
                  <CircularProgress
                    sx={{ gridColumnStart: 2, margin: 'auto' }}
                    color="secondary"
                  />
                ) : (
                  <Button
                    onClick={() => handleList(MODAL_TYPE.DeleteFromMarket)}
                    sx={{
                      width: '100%',
                      height: '100%',
                      fontWeight: 'bold',
                    }}
                    variant="contained"
                    color="error"
                  >
                    UNLIST
                  </Button>
                )}
              </Property>
            ) : (
              <Property padding={'0px'} item xs={7.5}>
                {isLoading ? (
                  <CircularProgress
                    sx={{ gridColumnStart: 2, margin: 'auto' }}
                    color="secondary"
                  />
                ) : (
                  <Button
                    onClick={() => handleList(MODAL_TYPE.AddToMarket)}
                    sx={{ width: '100%', height: '100%', fontWeight: 'bold' }}
                    variant="contained"
                  >
                    LIST
                  </Button>
                )}
              </Property>
            )}
          </>
        )}
      </>
    </GridCardItem>
  );
};
