import { Basket } from 'assets/svg-components/Basket';
import { ConsumptionIcon } from 'assets/svg-components/Consumption';
import { DiscanteIcon } from 'assets/svg-components/Distance';
import { EfficiencyIcon } from 'assets/svg-components/Efficiency';
import { FuelIcon } from 'assets/svg-components/Fuel';
import { useNotify } from 'hooks/useToast';
import { useUserStore } from 'stores/useUserStore';
import styled from 'styled-components';
import { CarSale } from 'types/models';
import { capitalizeFirstLetter } from 'utils/textUtils';
import MGAS3dxxx from '../../../../../assets/images/MGAS3dxxx.png';
import { labelColors } from '../../../../../constants';
import { MarketInteractor } from '../../interactor';
import { Dot, Property } from '../styles';
import { useMarketMutation } from 'api/mutations/marketMutation';

export const CarItem = ({ car }: { car: CarSale }) => {
  const { setIsOpenModal } = useUserStore();
  const { notify } = useNotify();
  const tank =
    car.car_to_sale.characteristics.find((c) => c.name === 'tank')?.value || 1;
  const { mutateAsync: buyMarketItems } = useMarketMutation('buyMarketItems');
  const { fethMarketCars } = MarketInteractor();

  const handleBuy = () => {
    setIsOpenModal({
      isOpen: true,
      handleClick: () => {
        buyMarketItems({
          marketItemId: car.id,
        })
          .then((res: any) => {
            notify({
              type: 'success',
              meassage: 'You successfully purchased a car',
            });
            fethMarketCars();
          })
          .catch((err: any) => {
            notify({
              type: 'error',
              meassage: err.response.data.message,
            });
            console.log('[breed price error] : ', err);
          });
      },
    });
  };
  return (
    <>
      <CardBox>
        <Img
          src={
            car.car_to_sale.visual.assets.find((a) => a.name === 'garage')
              ?.asset.url || ''
          }
          alt={'CarImg'}
        />
        <OptionBox>
          <Property item xs={7.5}>
            <DiscanteIcon />
            {(
              ((car.car_to_sale.characteristics.find((c) => c.name === 'tank')
                ?.value || 1) /
                (car.car_to_sale.characteristics.find(
                  (c) => c.name === 'consumption'
                )?.value || 1)) *
              100
            ).toFixed()}{' '}
            km
          </Property>
          <Property item xs={7.5}>
            <FuelIcon />
            {tank}
          </Property>
          <Property item xs={7.5}>
            <ConsumptionIcon />
            {car.car_to_sale.characteristics
              .find((c) => c.name === 'consumption')
              ?.value.toFixed()}
          </Property>
          <Property item xs={15.5}>
            <EfficiencyIcon />
            {car.car_to_sale.characteristics
              .find((c) => c.name === 'efficiency')
              ?.value.toFixed()}
          </Property>
        </OptionBox>
        <RarytyBox>
          <Property item xs={7.5}>
            <Dot
              sx={{ background: labelColors[car.car_to_sale.rarity.name] }}
            />
            {capitalizeFirstLetter(car.car_to_sale.rarity.name)}
          </Property>
          <Property item xs={7.5}>
            {car.car_to_sale.model.type.name}
          </Property>
          <Property>Mint: {car.car_to_sale.breeds_count} of 7</Property>
          <BuyBtn onClick={handleBuy}>
            <Img style={{ width: 15 }} src={MGAS3dxxx} alt={'CarImg'} />
            {car.price} <Basket />
          </BuyBtn>
        </RarytyBox>
      </CardBox>
    </>
  );
};

const CardBox = styled.div`
  border-radius: 10px;
  padding: 15px;
  background: linear-gradient(135deg, #141313 0%, #18191b 100%);
  box-shadow: 0px 3.6074767112731934px 18.037384033203125px 0px
    rgba(0, 0, 0, 0.5);
  width: 31%;
  border: 2px solid #1d1d1d;
`;
const Img = styled.img`
  border-radius: 15px;
  width: 100%;
`;
const OptionBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 4px;
  margin-top: 17px;
`;
const RarytyBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 4px;
  margin-top: 5px;
`;
const BuyBtn = styled.div`
  display: flex;
  width: 49%;
  height: 42px;
  padding: 10px 15px;
  align-items: center;
  gap: 5px;
  bordet: none;
  border-radius: 15px;
  background: linear-gradient(180deg, #21e7d6 0%, #83daff 100%);
  color: black;
  font-family: 'Fact';
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  justify-content: center;
`;
