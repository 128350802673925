import { Box, Grid, SxProps, Typography, useTheme } from '@mui/material';
import bnbPhoto from 'assets/images/Bnb.png';
import busdPhoto from 'assets/images/Busd.png';
import moilPhoto from 'assets/images/DashboardTabs/moil.png';
import { CarIcon } from 'assets/svg-components/Car';
import { ContainerIcon } from 'assets/svg-components/Container';
import { NumberplateIcon } from 'assets/svg-components/Numberplate';
import { TabIcon } from 'assets/svg-components/Tab';
import { bnb, busd, mgas, moil } from 'constants/index';
import { SwapTokenSymbol, useSwapStore } from 'stores/useSwapStore';
import { useUserWalletBalanceStore } from 'stores/useUserBalanceStore';
import { useUserItemsStore } from 'stores/useUserItemsStore';
import { toFixed } from 'utils/textUtils';
import { imgSx } from './DashboardPage.shared';
import { ListType, MainTabType, TabType } from './DashboardPage.types';
import { TabButton } from './TabButton';
import { CarsTab } from './Tabs/CarsTab';
import { CoinTab } from './Tabs/CoinTab';
import { ContainersTab } from './Tabs/ContainersTab';
import { InfoGamingTab } from './Tabs/InfoGamingTab';
import { InfoWalletTab } from './Tabs/InfoWalletTab';
import { MainTab } from './Tabs/MainTab';

export const useGridItem = function <T>(
  { amount, icon, image, identifier, setSelectedTab, selectedTab }: ListType<T>,
  index: number
) {
  const capitalizedTitle =
    identifier!.split('.')!.pop()!.charAt(0)!.toUpperCase()! +
    identifier!.split('.')!.pop()!.slice(1);

  const { setTokenFrom } = useSwapStore();

  const { palette, shape } = useTheme();

  const boxSx: SxProps = {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    p: '10px',
    width: '100%',
    height: 140,
    background: palette.gradient.darkGrey,
    border:
      selectedTab === identifier
        ? `2px solid ${palette.primary.main}`
        : `2px solid ${palette.text.disabled}`,
    borderRadius: `${shape.borderRadius * 2}px`,
    '&:hover': {
      cursor: 'pointer',
      border: `2px solid ${palette.primary.main}`,
    },
  };

  return (
    <Grid item xs={4} key={index}>
      <Box
        sx={boxSx}
        onClick={() => {
          if (identifier.startsWith('Wallet')) {
            setTokenFrom(identifier.split('.').pop()! as SwapTokenSymbol);
          }
          setSelectedTab && setSelectedTab(identifier);
        }}
      >
        {image && <img alt="" src={image} style={imgSx} />}
        <Typography variant="h5" fontWeight={500} align="left">
          {capitalizedTitle}
        </Typography>
        <Typography variant="h4" align="left" color="primary">
          {amount}
        </Typography>
        <Typography align="right" mt="auto" color="primary">
          {typeof icon !== 'string' && <>{icon}</>}
          {typeof icon === 'string' && (
            <img width="36px" height="36px" src={icon} alt="" />
          )}
        </Typography>
      </Box>
    </Grid>
  );
};

export const useCoinObjects = (props: {
  selectedTab: MainTabType;
  setSelectedTab: React.Dispatch<React.SetStateAction<MainTabType>>;
}) => {
  const { currency } = useUserItemsStore();

  const { selectedTab, setSelectedTab } = props;

  const { tokenBalances } = useUserWalletBalanceStore();

  const moilItemWallet = tokenBalances?.find(({ symbol }) => {
    return symbol === moil;
  });

  const busdItemWallet = tokenBalances?.find(({ symbol }) => {
    return symbol === busd;
  });

  const bnbItemWallet = tokenBalances?.find(({ symbol }) => {
    return symbol === bnb;
  });

  const moilItem = currency?.data.find(({ item }) => {
    return item.name === moil;
  });

  const mgasItem = currency?.data.find(({ item }) => {
    return item.name === mgas;
  });

  const moilWalletObject: ListType<string> = {
    identifier: 'Wallet.moil',
    amount: toFixed(Number(moilItemWallet?.balance) || 0, 2),
    icon: moilPhoto,
    image: moilPhoto,
    selectedTab,
    setSelectedTab,
  };
  const busdWalletObject: ListType<string> = {
    identifier: 'Wallet.busd',
    amount: toFixed(Number(busdItemWallet?.balance) || 0, 2),
    icon: busdPhoto,
    image: busdPhoto,
    selectedTab,
    setSelectedTab,
  };
  const bnbWalletObject: ListType<string> = {
    identifier: 'Wallet.bnb',
    amount: toFixed(Number(bnbItemWallet?.balance) || 0, 2),
    icon: bnbPhoto,
    image: bnbPhoto,
    selectedTab,
    setSelectedTab,
  };

  const moilGamingObject: ListType<string> = {
    identifier: 'Gaming.moil',
    amount: toFixed(Number(moilItem?.amount) || 0, 2),
    icon: moilPhoto,
    image: moilPhoto,
    selectedTab,
    setSelectedTab,
  };
  const mgasGamingObject: ListType<string> = {
    identifier: 'Gaming.mgas',
    amount: toFixed(Number(mgasItem?.amount) || 0, 2),
    icon: moilPhoto,
    image: moilPhoto,
    selectedTab,
    setSelectedTab,
  };

  const listTopGaming: ListType<string>[] = [
    moilGamingObject,
    mgasGamingObject,
  ];

  const listTopWallet: ListType<string>[] = [
    moilWalletObject,
    busdWalletObject,
    bnbWalletObject,
  ];

  const getListBottom = (
    carsAmount: number | undefined,
    containersAmount: number | undefined,
    numberplateAmount: number | undefined,
    type: 'gaming' | 'wallet'
  ): ListType<JSX.Element>[] => {
    return [
      {
        identifier: type === 'gaming' ? 'Gaming.cars' : 'Wallet.cars',
        amount: carsAmount,
        icon: <CarIcon />,
        image: null,
        selectedTab,
        setSelectedTab,
      },
      {
        identifier:
          type === 'gaming' ? 'Gaming.containers' : 'Wallet.containers',
        amount: containersAmount,
        icon: <ContainerIcon />,
        image: null,
        selectedTab,
        setSelectedTab,
      },
      {
        identifier:
          type === 'gaming' ? 'Gaming.numberplates' : 'Wallet.numberplates',
        amount: numberplateAmount,
        icon: <NumberplateIcon />,
        image: null,
        selectedTab,
        setSelectedTab,
      },
    ];
  };

  return {
    listTopGaming,
    listTopWallet,
    moilWalletObject,
    busdWalletObject,
    bnbWalletObject,
    moilGamingObject,
    mgasGamingObject,
    getListBottom,
  };
};

export const useTabDictionary = (props: {
  selectedTab: MainTabType;
  setSelectedTab: React.Dispatch<React.SetStateAction<MainTabType>>;
}) => {
  const {
    bnbWalletObject,
    busdWalletObject,
    mgasGamingObject,
    moilGamingObject,
    moilWalletObject,
  } = useCoinObjects(props);

  const tabs: Partial<Record<MainTabType, () => JSX.Element>> = {
    Main: () => <MainTab />,
    'Gaming.moil': () => <CoinTab item={moilGamingObject} />,
    // 'Gaming.mgas': () => <CoinTab item={mgasGamingObject} />,
    'Wallet.moil': () => <CoinTab item={moilWalletObject} />,
    'Wallet.bnb': () => <CoinTab item={bnbWalletObject} />,
    'Wallet.busd': () => <CoinTab item={busdWalletObject} />,
    'Gaming.cars': () => <CarsTab />,
    'Gaming.containers': () => <ContainersTab />,
  };

  const TabComponent = tabs[props.selectedTab];
  return TabComponent ? TabComponent() : <MainTab />;
};

export const useTabInfo = (props: {
  tab: TabType;
  selectedTab: MainTabType;
  setSelectedTab: React.Dispatch<React.SetStateAction<MainTabType>>;
  cars?: number;
  containers?: number;
  numberplates?: number;
}) => {
  const { selectedTab, setSelectedTab, tab, cars, containers, numberplates } =
    props;

  const { getListBottom, listTopGaming, listTopWallet } = useCoinObjects({
    selectedTab,
    setSelectedTab,
  });

  const tabInfo: Partial<Record<TabType, () => JSX.Element>> = {
    Gaming: () => (
      <InfoGamingTab
        listTop={listTopGaming}
        listBottom={getListBottom(cars, containers, numberplates, 'gaming')}
      />
    ),
    Wallet: () => (
      <InfoWalletTab
        listTop={listTopWallet}
        listBottom={getListBottom(0, 0, 0, 'wallet')}
      />
    ),
  };

  return tabInfo[tab]?.() || null;
};

export const useTabButton = (props: {
  tab: TabType;
  selectedTabInfo: TabType;
  setSelectedTabInfo: React.Dispatch<React.SetStateAction<TabType>>;
}) => {
  const { palette } = useTheme();
  const { selectedTabInfo, setSelectedTabInfo, tab } = props;
  const btnProps = {
    tab,
    size: 6,
    selectedTabInfo,
    isSelected: selectedTabInfo === tab,
    onClick: () => {
      setSelectedTabInfo(tab);
    },
    icon: (
      <TabIcon
        fill={
          selectedTabInfo === tab
            ? palette.background.default
            : palette.primary.main
        }
      />
    ),
  };
  return <TabButton sx={{ flex: 1 }} {...btnProps} />;
};
