import React from 'react';
import ReactDOM from 'react-dom/client';
import { App, AppProviders } from './app';
import './font.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AppProviders>
    <App />
  </AppProviders>
);
