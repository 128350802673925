import React from 'react';

export const SwapIcon = (props: {
  fill?: string;
  height?: number;
  width?: number;
}) => {
  const width = 24;
  const height = 14;
  const defaultColor = 'black';

  return (
    <svg
      width={props?.width ?? width}
      height={props?.height ?? height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1372 5.3106L17.1532 5.32741V5.3106H17.1372ZM15.0616 5.3106L6.63989 5.24337L8.58984 3.27661C9.26223 2.58741 9.26223 1.47796 8.58984 0.788753C7.91744 0.0995491 6.80799 0.0995491 6.1356 0.788753L0 6.99159L6.1356 13.2112C6.80799 13.9004 7.91744 13.9004 8.58984 13.2112C9.26223 12.522 9.26223 11.4126 8.58984 10.7234L6.63989 8.67258H8.73165L17.1532 8.73981L15.2032 10.7066C14.5308 11.3958 14.5308 12.5052 15.2032 13.1944C15.8756 13.8836 16.9851 13.8836 17.6574 13.1944L23.793 6.99159L17.6574 0.788753C16.9851 0.0995491 15.8756 0.0995491 15.2032 0.788753C14.5308 1.47796 14.5308 2.58741 15.2032 3.27661L17.1372 5.3106L15.0616 5.3106Z"
        fill={props.fill ?? defaultColor}
      />
    </svg>
  );
};
