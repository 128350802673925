import React from 'react';

export const FuelIcon: React.FC = (props) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2246 0C17.5286 0 16.8607 0.276999 16.3687 0.768999L14.5264 2.6113L15.7637 3.8486L17.606 2.0063C17.7702 1.8421 17.9931 1.75 18.2246 1.75H21V0H18.2246ZM7.875 2.625L6.759 3.741C6.0567 4.4433 5.9535 5.5098 6.4343 6.3284L1.7654 10.9973C1.2503 11.5124 0.9767 12.1745 0.916 12.8533C0.3252 13.7194 0 14.7481 0 15.8115V21H1.75V15.8115C1.75 15.635 1.7751 15.4626 1.8013 15.2903L4.8706 18.3596C6.0388 19.5278 7.9595 19.5278 9.1277 18.3596L13.7966 13.6907C14.6149 14.1715 15.6817 14.0683 16.384 13.366L17.5 12.25V7.875L12.25 2.625H7.875ZM8.75 9.4746C9.1978 9.4746 9.6456 9.6456 9.9873 9.9873L12.5063 12.5063L7.8904 17.1223C7.3909 17.6218 6.6074 17.6218 6.1079 17.1223L3.2437 14.2563L7 10.5V13.125C6.9984 13.2409 7.0198 13.3561 7.063 13.4637C7.1063 13.5713 7.1704 13.6692 7.2519 13.7518C7.3333 13.8343 7.4303 13.8999 7.5373 13.9447C7.6442 13.9894 7.759 14.0125 7.875 14.0125C7.991 14.0125 8.1058 13.9894 8.2127 13.9447C8.3197 13.8999 8.4167 13.8343 8.4981 13.7518C8.5796 13.6692 8.6437 13.5713 8.687 13.4637C8.7302 13.3561 8.7516 13.2409 8.75 13.125V9.4746Z"
        fill="#939393"
      />
    </svg>
  );
};
