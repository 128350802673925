import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useMarketMutation } from 'api/mutations/marketMutation';
import { MarketInteractor } from 'components/pages/Marketplace/interactor';
import { GridCardItem } from 'components/ui/GridCard';
import { containers } from 'constants/containerImages';
import { useNotify } from 'hooks/useToast';
import { useState } from 'react';
import { useUserItemsStore } from 'stores/useUserItemsStore';
import { useUserStore } from 'stores/useUserStore';
import { ItemType, MODAL_TYPE } from 'types/models';
import { capitalizeFirstLetter } from 'utils/textUtils';
import Moovy from '../../../../../assets/images/moovy.svg';
import { labelColors } from '../../../../../constants';
import { Dot, Property } from '../styles';

export const ContainerItem = ({
  container,
  onClick,
  selectedContainers,
  isSelectEnabled,
}: {
  container: ItemType;
  onClick: (id: number) => void;
  selectedContainers: number[];
  isSelectEnabled: boolean;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { setIsOpenModal } = useUserStore();
  const { fethMarketContainer } = MarketInteractor();
  const { mutateAsync: setMarketItems } = useMarketMutation('setMarketItems');
  const { mutateAsync: unlist } = useMarketMutation('deleteMarketItems');

  const { storageRefetch } = useUserItemsStore();
  const { notify } = useNotify();

  const handleList = (type: MODAL_TYPE) => {
    setIsOpenModal({
      isOpen: true,
      type: type,
      handleClick: (value: number | undefined) => {
        setIsLoading(true);
        if (type === MODAL_TYPE.AddToMarket) addToMarket(value);
        if (type === MODAL_TYPE.DeleteFromMarket) deleteFromMarket();
      },
    });
  };

  const addToMarket = (value: number | undefined) => {
    if (value) {
      setMarketItems({
        itemId: container.id,
        price: value,
      })
        .then((res: any) => {
          notify({
            type: 'success',
            meassage: 'Container successfully listes',
          });

          storageRefetch();
          setTimeout(() => fethMarketContainer(), 2000);
        })
        .catch((err: any) => {
          console.log('[list error] : ', err);
        })
        .finally(() => setTimeout(() => setIsLoading(false), 1000));
    }
  };

  const deleteFromMarket = () => {
    if (!container.sales_on_market)
      return notify({
        type: 'info',
        meassage: 'Container not on market',
      });

    unlist({ marketItemId: container.sales_on_market.id })
      .then((res: any) => {
        notify({
          type: 'success',
          meassage: 'Container successfully unlisted from market',
        });
        storageRefetch();
        setTimeout(() => fethMarketContainer(), 2000);
      })
      .catch((err: any) => {
        console.log('[unlisterror] : ', err);
      })
      .finally(() => setTimeout(() => setIsLoading(false), 1000));
  };

  return (
    <GridCardItem
      selected={selectedContainers.includes(container.id)}
      img={containers[container.item.container.rarity.name]}
      onClick={() => onClick(container.id)}
    >
      <>
        {' '}
        <Property mt={2} item xs={15.5}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            width={'100%'}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={1}
            >
              <img src={Moovy} height={16} alt="" />
              <Typography fontWeight={'bold'}>Container</Typography>
            </Box>
          </Box>
        </Property>
        <Property item xs={isSelectEnabled ? 15.5 : 7.5}>
          <Dot
            sx={{
              background: labelColors[container.item.container.rarity.name],
            }}
          />
          {capitalizeFirstLetter(container.item.container.rarity.name)}
        </Property>
        {!isSelectEnabled && (
          <>
            {container.sales_on_market ? (
              <Property padding={'0px'} item xs={7.5}>
                {isLoading ? (
                  <CircularProgress
                    sx={{ gridColumnStart: 2, margin: 'auto' }}
                    color="secondary"
                  />
                ) : (
                  <Button
                    onClick={() => handleList(MODAL_TYPE.DeleteFromMarket)}
                    sx={{
                      width: '100%',
                      height: '100%',
                      fontWeight: 'bold',
                    }}
                    variant="contained"
                    color="error"
                  >
                    UNLIST
                  </Button>
                )}
              </Property>
            ) : (
              <Property item xs={7.5} padding={'0px'}>
                {isLoading ? (
                  <CircularProgress
                    sx={{ gridColumnStart: 2, margin: 'auto' }}
                    color="secondary"
                  />
                ) : (
                  <Button
                    onClick={() => handleList(MODAL_TYPE.AddToMarket)}
                    sx={{ width: '100%', height: '100%', fontWeight: 'bold' }}
                    variant="contained"
                  >
                    LIST
                  </Button>
                )}
              </Property>
            )}
          </>
        )}
      </>
    </GridCardItem>
  );
};
