import { CssBaseline, ThemeProvider } from '@mui/material';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { useAxios } from 'api';
import { useWeb3 } from 'hooks';
import { useNotify } from 'hooks/useToast';
import { Web3Provider } from 'providers/web3Provider';
import { FC, PropsWithChildren, useEffect } from 'react';
import { HashRouter as Router, useNavigate } from 'react-router-dom';
import { AppRoutes, routes } from 'routes/routes';
import { createAppTheme } from 'theme';
import { localStorageUtil } from 'utils/localStorageUtils';

export const AppProviders: FC<PropsWithChildren<{}>> = ({ children }) => {
  const theme = createAppTheme({ themeName: 'darkTheme' });
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        enabled: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        useErrorBoundary: false,
        retry: 2,
      },
    },
    queryCache: new QueryCache({}),
  });

  return (
    <Web3Provider>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>{children}</Router>
        </ThemeProvider>
      </QueryClientProvider>
    </Web3Provider>
  );
};

export const App = () => {
  const { loggedWithWeb3, logout } = useWeb3();
  const { setAuthToken } = useAxios();
  const { toast } = useNotify();

  const access_token = localStorageUtil.getItem({ key: 'access_token' });
  const navigate = useNavigate();

  useEffect(() => {
    access_token && setAuthToken({ access_token });

    // if (loggedWithWeb3 && access_token) {
    //   navigate(routes.marketplace.path);
    // }
    if (!loggedWithWeb3 || !access_token) {
      navigate(routes.auth.path);
    }
  }, [access_token, loggedWithWeb3, logout, navigate, setAuthToken]);

  return (
    <>
      <AppRoutes />
      {toast}
    </>
  );
};
