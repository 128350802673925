import { createTheme } from '@mui/material';
import { ThemePalette } from 'types/mui';
import { fontFamily } from './fontFamily';

export const createThemePalette = (palette: ThemePalette) => {
  return createTheme({
    typography: {
      fontFamily,
      htmlFontSize: 14,
      fontSize: 14,
      h1: {
        fontSize: '2.5rem',
        fontWeight: 500,
      },
      h2: {
        fontSize: '2.25rem',
        fontWeight: 500,
      },
      h3: {
        fontSize: '1.875rem',
        fontWeight: 500,
      },
      h4: {
        fontSize: '1.375rem',
        fontWeight: 700,
      },
      h5: {
        fontSize: '1.125rem',
        fontWeight: 400,
      },
      h6: {
        fontSize: '0.875rem',
        fontWeight: 400,
      },
      body1: {
        fontSize: '1rem',
      },
      body2: {
        fontSize: '0.875rem',
      },
      button: {
        fontSize: '1rem',
        textTransform: 'none',
      },
      subtitle1: {
        fontSize: '1.125rem',
      },
      subtitle2: {
        fontSize: '0.875rem',
        fontWeight: 400,
      },
      caption: {
        fontSize: '0.875rem',
        fontWeight: 500,
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            fontSize: 14,
          },
          'input::-webkit-outer-spin-button': {
            webkitAppearance: 'none',
            margin: 0,
          },
          'input::-webkit-inner-spin-button': {
            webkitAppearance: 'none',
            margin: 0,
          },
          'input[type="number"]': {
            mozAppearance: 'textfield',
          },
          '.disable-text-selection': {
            WebkitTouchCallout: 'none',
            WebkitUserSelect: 'none',
            KhtmlUserSelect: 'none',
            MozUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none',
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            border: 'none',
            fontSize: '1rem',
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            background: palette.background.card,
            '.MuiTableCell-head': {
              fontSize: '1rem',
              fontWeight: 700,
            },
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            background: palette.background.card,
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: { marginTop: '5px' },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderRadius: '10px',
            '&:first-child': {
              borderRadius: '10px 0 0 10px',
            },
            '&:last-child': {
              borderRadius: '0 10px 10px 0',
            },
            '&:not(:first-child):not(:last-child)': {
              borderRadius: '0',
            },
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          root: {
            '.MuiMenu-paper': {},
          },
          list: {
            padding: 0,
          },
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            padding: '12px',
            minWidth: '160px',
            width: '100%',
            background: palette.gradient.green,
          },
        },
      },
    },
    palette,
    shape: {
      borderRadius: 10,
    },
  });
};
