import { Box, ButtonBase, Typography, useTheme } from '@mui/material';
import { GalleryIcon } from 'assets/svg-components/Gallery';
import { Fragment } from 'react';
import { buttonSx } from 'theme/sxProps';

export const Lottery = () => {
  const { palette, shape } = useTheme();

  return (
    <Fragment>
      <Box
        width="100%"
        height="430px"
        display="grid"
        sx={{
          placeItems: 'center',
          borderRadius: `${shape.borderRadius}px`,
          background:
            'linear-gradient(98.27deg, rgba(33, 231, 214, 0.08) 0%, rgba(131, 218, 255, 0.08) 105.42%)',
        }}
      >
        <Typography
          fontWeight={500}
          fontSize="100px"
          variant="h1"
          sx={{ color: palette.text.disabled }}
        >
          Banner
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="80px"
        sx={{
          p: '20px',
          background: palette.primary.dark,
          borderRadius: `${shape.borderRadius}px`,
        }}
      >
        <Typography variant="h5" color="secondary" fontWeight={500}>
          Checkout the market and buy what you like
        </Typography>
        <ButtonBase
          onClick={(e) => {}}
          sx={buttonSx({
            height: '44px',
            width: '210px',
            borderRadius: `${shape.borderRadius}px`,
          })}
        >
          <GalleryIcon fill="black" />
          <Typography ml={0.5} variant="h5" fontWeight={500}>
            Go to lottery
          </Typography>
        </ButtonBase>
      </Box>
    </Fragment>
  );
};
