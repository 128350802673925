import { MutationFunction, useMutation } from '@tanstack/react-query';
import { MutationOptionsType, MutationTagType } from 'api/types';
import {
  CrossBreeding,
  CrossBreedingPreviewResponse,
  CrossBreedingResponse,
} from 'types/models';
import { useAxios } from '../axios';

type MutationKeyToType = {
  crossBreeding: { args: CrossBreeding; response: CrossBreedingResponse };
  crossBreedingPreview: {
    args: CrossBreeding;
    response: CrossBreedingPreviewResponse;
  };
};

type MutationKey = keyof MutationKeyToType;

export const useCarsCrossbreedingMutation = <K extends MutationKey>(
  key: K,
  options?: MutationOptionsType<MutationKeyToType[K]['response']>,
  tags: string[] = []
) => {
  const { api } = useAxios();
  const baseUrl = '/cars-crossbreeding';

  const crossBreeding = async (args: CrossBreeding) => {
    const { data } = await api().post<MutationKeyToType[K]['response']>(
      baseUrl,
      args
    );
    return data;
  };

  const crossBreedingPreview = async (args: CrossBreeding) => {
    const { data } = await api().post<MutationKeyToType[K]['response']>(
      baseUrl + '/preview',
      args
    );
    return data;
  };

  const mutations = {
    crossBreeding,
    crossBreedingPreview,
  };

  if (!mutations.hasOwnProperty(key)) {
    throw new Error(`Invalid mutation key: ${key}`);
  }

  return useMutation<
    MutationKeyToType[K]['response'],
    unknown,
    MutationKeyToType[K]['args'],
    MutationTagType
  >(
    [key, ...tags],
    mutations[key] as unknown as MutationFunction<
      MutationKeyToType[K]['response'],
      MutationKeyToType[K]['args']
    >,
    options as any
  );
};
