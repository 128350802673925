import {
  UsersItemsStorage,
  UsersItemsCurrency,
  UsersItemsExpandable,
  UsersItemsTransactionWithdraw,
} from 'types/models';
import { useAxios } from '../axios';
import { useQuery } from '@tanstack/react-query';
import { QueryOptionsType, QueryTagType } from 'api/types';

// Define a mapping from keys to types
type QueryKeyToType = {
  storage: UsersItemsStorage;
  expandable: UsersItemsExpandable;
  currency: UsersItemsCurrency;
  transactionWithdraw: UsersItemsTransactionWithdraw;
};

type QueryKey = keyof QueryKeyToType;

export const useUserItemsQuery = <K extends QueryKey>(
  key: K,
  options?: QueryOptionsType<QueryKeyToType[K]>,
  tags: string[] = []
) => {
  const baseUrl = '/user-items';
  const { api } = useAxios();

  const storage = async () => {
    const { data } = await api().get<QueryKeyToType['storage']>(
      '/user-items/storage'
    );
    return data;
  };

  const expandable = async () => {
    const { data } = await api().get<QueryKeyToType['expandable']>(
      baseUrl + '/expandable'
    );
    return data;
  };

  const currency = async () => {
    const { data } = await api().get<QueryKeyToType['currency']>(
      baseUrl + '/currency'
    );
    return data;
  };

  const transactionWithdraw = async () => {
    const { data } = await api().get<QueryKeyToType['transactionWithdraw']>(
      baseUrl + '/transaction/withdraw'
    );
    return data;
  };

  const queries = {
    storage,
    expandable,
    currency,
    transactionWithdraw,
  };

  if (!queries.hasOwnProperty(key)) {
    throw new Error(`Invalid query key: ${key}`);
  }
  return useQuery<QueryKeyToType[K], unknown, QueryKeyToType[K], QueryTagType>(
    [key as any, ...tags],
    queries[key] as any,
    options
  );
};
