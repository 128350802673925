import React from 'react';

export const Moovy = (props: {
  fill?: string;
  height?: number;
  width?: number;
}) => {
  return (
    <svg
      width={props?.width ?? 23}
      height={props?.height ?? 20}
      viewBox="0 0 23 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <path
          d="M12.5041 7.95264C12.3525 8.84766 12.0108 9.69938 11.5023 10.4498C10.9211 11.3117 10.1384 12.0172 9.22284 12.5045C8.30723 12.9917 7.28666 13.2459 6.25057 13.2447C4.75494 13.2448 3.30739 12.7138 2.16364 11.7455C1.0199 10.7771 0.253554 9.43373 0 7.95264L2.23546 7.95264C2.47309 8.8403 2.99517 9.62456 3.72095 10.1841C4.44673 10.7437 5.33576 11.0474 6.25057 11.0482C7.16537 11.0474 8.0544 10.7437 8.78018 10.1841C9.50596 9.62456 10.028 8.8403 10.2657 7.95264L12.5041 7.95264Z"
          fill={props?.fill ?? '#7BBAC1'}
        />
        <path
          d="M22.9568 5.54014L20.6887 5.54014C20.4103 4.71196 19.8806 3.99225 19.1741 3.48218C18.4676 2.97212 17.6198 2.69734 16.7499 2.69648C15.8799 2.69734 15.0321 2.97212 14.3256 3.48218C13.6191 3.99225 13.0895 4.71196 12.8111 5.54014L10.5469 5.54014C10.7165 4.73645 11.0406 3.97377 11.5011 3.29488C12.0823 2.43299 12.865 1.7275 13.7806 1.24023C14.6962 0.752958 15.7168 0.498791 16.7528 0.500004C18.2049 0.499451 19.6132 0.999638 20.7424 1.91699C21.8716 2.83434 22.6533 4.1133 22.9568 5.54014Z"
          fill={props?.fill ?? '#7BBAC1'}
        />
        <path
          d="M12.4617 5.54014L10.1926 5.54014C9.9142 4.71196 9.38454 3.99225 8.67804 3.48218C7.97154 2.97212 7.12373 2.69734 6.25378 2.69648C5.38383 2.69734 4.53602 2.97212 3.82952 3.48218C3.12302 3.99225 2.59336 4.71196 2.31497 5.54014L0.0507812 5.54014C0.35432 4.11346 1.13586 2.83464 2.26484 1.91731C3.39382 0.999973 4.80187 0.499679 6.25378 0.500004C7.28987 0.498791 8.31045 0.752958 9.22606 1.24023C10.1417 1.7275 10.9244 2.43299 11.5055 3.29488C11.9667 3.97361 12.2915 4.73631 12.4617 5.54014Z"
          fill={props?.fill ?? '#7BBAC1'}
        />
        <path
          d="M23.0002 7.95264C22.7466 9.43373 21.9803 10.7771 20.8365 11.7455C19.6928 12.7138 18.2452 13.2448 16.7496 13.2447C15.7135 13.2459 14.693 12.9917 13.7774 12.5045C12.8617 12.0172 12.0791 11.3117 11.4979 10.4498C10.9893 9.69938 10.6477 8.84766 10.4961 7.95264L12.7316 7.95264C12.9692 8.8403 13.4913 9.62456 14.217 10.1841C14.9428 10.7437 15.8319 11.0474 16.7467 11.0482C17.6615 11.0474 18.5505 10.7437 19.2763 10.1841C20.0021 9.62456 20.5241 8.8403 20.7618 7.95264L23.0002 7.95264Z"
          fill={props?.fill ?? '#7BBAC1'}
        />
      </g>
    </svg>
  );
};
