import { AuthPage, DashboardPage, MarketplacePage } from 'components/pages';
import { stakingLink } from 'constants/index';
import { Navigate, Route, RouteProps, Routes } from 'react-router-dom';
import { localStorageUtil } from 'utils/localStorageUtils';

type RouteType = Pick<RouteProps, 'element' | 'index'> & {
  path: string;
  title: string;
  href?: string;
};

type RouteNameType =
  | 'auth'
  | 'dashboard'
  | 'marketplace'
  | 'staking'
  | 'lottery';

export const routes: Record<RouteNameType, RouteType> = {
  auth: {
    title: 'AUTH',
    path: '/',
    element: <AuthPage />,
  },
  dashboard: {
    title: 'DASHBOARD',
    path: '/dashboard',
    element: <DashboardPage />,
  },
  marketplace: {
    title: 'MARKETPLACE',
    path: '/marketplace',
    element: <MarketplacePage />,
  },
  staking: {
    title: 'STAKING',
    path: '/staking',
    element: <DashboardPage />,
    href: stakingLink,
  },
  lottery: { title: 'LOTTERY', path: '/lottery', element: <DashboardPage /> },
} as const;

export const AppRoutes = () => {
  const access_token = localStorageUtil.getItem({ key: 'access_token' });

  if (!!access_token) {
    return (
      <Routes>
        <Route index {...routes.auth} />
        <Route {...routes.marketplace} />
        <Route {...routes.dashboard} />
        <Route
          path="*"
          element={<Navigate to={routes.dashboard.path} replace />}
        />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route index {...routes.auth} />
      <Route path="*" element={<Navigate to={routes.auth.path} replace />} />
    </Routes>
  );
};
