import { theme as darkTheme } from './darkTheme';

export type ThemeNames = 'darkTheme';

export const ThemeMap = {
  darkTheme,
};

export const createAppTheme = ({ themeName }: { themeName: ThemeNames }) => {
  if (!ThemeMap.hasOwnProperty(themeName)) {
    console.error(
      `[@bbplace/mui]: Provided theme [${themeName}] is not supported. Switching to default.`
    );
  }
  const createTheme = ThemeMap[themeName] ?? ThemeMap['darkTheme'];
  return createTheme();
};
