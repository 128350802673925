import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import ModalConfirmation from 'components/Modal';
import { Header } from 'components/layout';
import { Fragment, useState } from 'react';
import { useUserStore } from 'stores/useUserStore';
import { CarIcon } from '../../../assets/svg-components/Car';
import { ContainerIcon } from '../../../assets/svg-components/Container';
import { NumberplateIcon } from '../../../assets/svg-components/Numberplate';
import { useTabButton } from './Marketplace.hooks';
import { TabType } from './Marketplace.types';
import { CarsTab } from './Tabs/CarsTab';
import { ContainersTab } from './Tabs/ContainersTab';

export const MarketplacePage = () => {
  const { isOpenModal, setIsOpenModal } = useUserStore();
  const { palette, shape } = useTheme();
  const [selectedTabInfo, setSelectedTabInfo] = useState<TabType>('Cars');
  const onClose = () => {
    setIsOpenModal({ isOpen: false });
  };
  return (
    <Fragment>
      <Box
        width="100%"
        sx={{ background: palette.gradient.black, paddingBottom: 10 }}
      >
        <Header />
        <Container maxWidth="lg">
          <Typography variant="h1" fontSize={40} paddingTop={8}>
            MARKETPLACE
          </Typography>
          <Grid
            container
            sx={{
              width: '518px',
              height: '60px',
              background:
                ' linear-gradient(135deg, rgba(33, 231, 214, 0.16) 0%, rgba(131, 218, 255, 0.16) 100%);',
              borderRadius: `${shape.borderRadius * 2}px`,
              marginBottom: 9,
              marginTop: 6,
            }}
          >
            {useTabButton({
              tab: 'Cars',
              selectedTabInfo,
              setSelectedTabInfo,
              Icon: CarIcon,
            })}
            {useTabButton({
              tab: 'Containers',
              selectedTabInfo,
              setSelectedTabInfo,
              Icon: ContainerIcon,
            })}
            {useTabButton({
              tab: 'Car plates',
              selectedTabInfo,
              setSelectedTabInfo,
              Icon: NumberplateIcon,
            })}
          </Grid>
          {selectedTabInfo === 'Cars' && <CarsTab />}
          {selectedTabInfo === 'Containers' && <ContainersTab />}
          <ModalConfirmation isOpenModal={isOpenModal} onClose={onClose} />
        </Container>
      </Box>
    </Fragment>
  );
};
