import { ThemePalette } from 'types/mui';
import { createThemePalette } from './createTheme';

export const theme = () => {
  const palette: ThemePalette = {
    mode: 'dark',
    primary: {
      main: '#21E7D6',
      dark: '#163130',
      darker: 'rgba(129, 138, 137, 0.1)',
    },
    secondary: {
      main: '#8a898a',
      dark: '#2D3236',
      light: '#FDBA0F',
      green: 'rgba(107, 161, 161, 0.1)',
    },
    info: {
      main: '#6B71FF',
    },
    error: {
      main: '#E72121',
    },
    warning: {
      main: '#FFA71A',
    },
    purple: { main: '#C76BFF' },
    background: {
      default: '#000000',
      paper: '#171718',
      card: '#1D1D1D',
      lightOpacity: 'rgba(123, 186, 193, 0.5)',
    },
    gradient: {
      darkGrey: 'linear-gradient(135deg, rgb(20,20,20) 0%, rgb(24,25,26) 100%)',
      black:
        'linear-gradient(180deg, rgba(33, 231, 214, 0.12) 0%, rgba(33, 231, 214, 0) 108.4%), #000000',
      green:
        'linear-gradient(98.27deg, rgba(33, 231, 214, 0.24) 0%, rgba(131, 218, 255, 0.24) 105.42%)',
      blue: 'linear-gradient(98.27deg, #21E7D6 0%, #83DAFF 105.42%)',
    },
    action: {
      hover: '',
    },
    text: {
      main: '#fff',
      secondary: '#98989F',
      disabled: 'rgb(62, 65, 67)',
    },
    divider: '#353c41',
  };
  return createThemePalette(palette);
};
