export const textEllipsis = (str: string, n = 6): string => {
  if (str) {
    return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
  }
  return '';
};

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const toFixed = (value: number, precision: number) => {
  if (!value) {
    return '0';
  }
  value = Number(value);
  const fixed = value.toFixed(precision + 2);
  const length = fixed.length;
  const splittedByDot = fixed.substring(0, length - 2).split('.');

  if (+splittedByDot[1] === 0) {
    return splittedByDot[0];
  }
  return fixed.substring(0, length - 2);
};
