import { useUserItemsQuery } from 'api';
import { useUserItemsMutation } from 'api/mutations/userItemsMutation';
import { config } from 'config';
import { ethers } from 'ethers';
import { VAULT_KEEPER_ABI } from 'evm/ABIs/vaultKeeper.ABI';
import { useEffect, useState } from 'react';
import { useUserStore } from 'stores/useUserStore';
import { TransactionWithdrawType } from 'types/models';
import { useWeb3 } from './useWeb3';

export const useVaultKeeper = () => {
  const [activeTxId, setActiveTxId] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const privKey = useUserStore((s) => s.info?.privateKey);
  const { provider } = useWeb3();
  const {
    refetch,
    isLoading: isIdLoading,
    error: getTransactionWithdrawError,
  } = useUserItemsQuery('transactionWithdraw', { enabled: false });
  const {
    mutateAsync,
    isSuccess,
    isLoading,
    error: transactionWithdrawError,
  } = useUserItemsMutation('transactionWithdraw');
  const [loading, setLoading] = useState(isIdLoading || isLoading);
  // const { api } = useAxios();

  async function requestWithdraw({ amount, itemId }: TransactionWithdrawType) {
    setLoading(true);
    try {
      const result = await mutateAsync({
        amount,
        itemId,
      });
      //TODO: check result body and if statement
      if (result.message) {
        const { data } = await refetch();
        const id = data?.data.id;
        if (id) {
          return await vaultKeeperCall({ id });
        }
      }
    } catch (e) {
      console.error('[requestWithdraw] error', e);
      setError(true);
      throw e;
    } finally {
      setLoading(false);
    }
  }

  async function vaultKeeperCall({ id }: { id: string }) {
    const signer = new ethers.Wallet(privKey!, provider);
    const vaultKeeperContract = new ethers.Contract(
      config.vault_keeper,
      VAULT_KEEPER_ABI,
      signer
    );

    try {
      setLoading(true);
      const tx = await vaultKeeperContract.requestWithdraw(id);
      const res = await tx.wait();

      return res;
    } catch (error) {
      setError(true);
      console.error('[VaultKeeper] Error : ', error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    refetch()
      .then(({ data }) => {
        if (data?.data?.id) {
          setActiveTxId(data?.data.id);
        }
      })
      .finally(() => setLoading(false));
  }, [refetch]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 1500);
    }
  }, [error]);

  return {
    error,
    setError,
    requestWithdraw,
    setActiveTxId,
    activeTxId,
    loading,
    vaultKeeperCall,
    isSuccess,
    isIdLoading,
    transactionWithdrawError,
    getTransactionWithdrawError,
  };
};
