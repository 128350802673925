import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useWeb3 } from 'hooks';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useUserStore } from 'stores/useUserStore';
import { authContainerSx, buttonSx } from '../../../../theme/sxProps';
import { Input } from '../../../ui';
import { AuthTitle } from '../AuthPage.support';
import { AuthType, IFormRegisterData } from '../AuthPage.types';
import { LoginProviders } from '../LoginProviders';
import { GenerateBbid } from './GenerateBbid';
import { PasswordComponent } from './PasswordComponent';
import { SignUpSchema } from './Schema';

export const SignUpDrawer = (props: {
  setActive: (payload: AuthType) => void;
  active: AuthType;
}) => {
  const info = useUserStore((s) => s.info);
  const setInfo = useUserStore((s) => s.setInfo);

  const [showPasswordComponent, setShowPasswordComponent] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = useForm<IFormRegisterData>({
    defaultValues: { email: info?.email ?? '', agree: false },
    mode: 'all',
    //@ts-ignore
    resolver: yupResolver(SignUpSchema),
  });
  const { loginWithEmail, loggedWithWeb3, logout, initLoading } = useWeb3();
  const { active, setActive } = props;
  const { palette } = useTheme();

  const onSubmit: SubmitHandler<IFormRegisterData> = useCallback(
    (data) => {
      const email = data.email.trim();
      setInfo({ email });
      loginWithEmail(email);
    },
    [loginWithEmail, setInfo]
  );

  const loggedWithWeb3Component = useMemo(
    () => (
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} marginBottom={5}>
          <Input
            type="email"
            text="E-mail"
            placeholder="email@gmail.com"
            fullWidth
            required
            message={errors.email?.message}
            error={!!errors.email}
            {...register('email')}
          />
          <FormControlLabel
            control={
              <Checkbox
                required
                {...register('agree')}
                sx={{ padding: 0, '& .MuiSvgIcon-root': { fontSize: 34 } }}
              />
            }
            label={
              <Typography>
                I Agree{' '}
                <Link style={{ color: palette.primary.main }} to="/">
                  Terms & Conditions
                </Link>
              </Typography>
            }
          />
        </Stack>
        <Button
          fullWidth
          type="submit"
          disabled={!isDirty || !isValid || initLoading}
          sx={buttonSx()}
          style={{ marginBottom: 16 }}
        >
          <Typography variant="h5" fontWeight="700">
            {initLoading ? 'Loading' : 'Join'}
          </Typography>
        </Button>
        <LoginProviders />
      </Box>
    ),
    [
      initLoading,
      errors.email,
      handleSubmit,
      isDirty,
      isValid,
      onSubmit,
      palette.primary.main,
      register,
    ]
  );

  return (
    <Box sx={authContainerSx()}>
      <Typography variant="h1">
        Welcome to <br /> Moovy Dashboard
      </Typography>
      <Box display="flex" mt="30px">
        <AuthTitle {...{ path: 'login', title: 'Login', setActive, active }} />
        <AuthTitle
          {...{ path: 'signup', title: 'Sign up', setActive, active, ml: 30 }}
        />
        <Typography ml="auto" variant="h5" color="secondary" marginBottom={4}>
          <Link style={{ color: palette.primary.main }} to="/forgot-password">
            Forgot Password?
          </Link>
        </Typography>
      </Box>
      <Fragment>
        {!loggedWithWeb3 && loggedWithWeb3Component}
        {loggedWithWeb3 && !showPasswordComponent && (
          <GenerateBbid
            goBack={() => logout()}
            allowNextStep={setShowPasswordComponent}
          />
        )}
        {loggedWithWeb3 && showPasswordComponent && (
          <PasswordComponent handleBack={setShowPasswordComponent} />
        )}
      </Fragment>
    </Box>
  );
};
