import { MutationFunction, useMutation } from '@tanstack/react-query';
import { MutationOptionsType, MutationTagType } from 'api/types';
import { useAxios } from '../axios';
import { TransactionWithdrawType } from 'types/models';

type MutationKeyToType = {
  transactionWithdraw: {
    args: TransactionWithdrawType;
    response: { message: string };
  };
  exchangeMoilToMgas: {
    args: { amount: number };
    response: { message: string };
  };
  exchangeMgasToMoil: {
    args: { amount: number };
    response: { message: string };
  };
};

type MutationKey = keyof MutationKeyToType;

export const useUserItemsMutation = <K extends MutationKey>(
  key: K,
  options?: MutationOptionsType<MutationKeyToType[K]['response']>,
  tags: string[] = []
) => {
  const { api } = useAxios();
  const baseUrl = '/user-items';

  const transactionWithdraw = async (args: TransactionWithdrawType) => {
    const { data } = await api().post<MutationKeyToType['transactionWithdraw']>(
      baseUrl + '/transaction/withdraw',
      args
    );
    return data;
  };

  const exchangeMoilToMgas = async (args: TransactionWithdrawType) => {
    const { data } = await api().post<MutationKeyToType['exchangeMoilToMgas']>(
      baseUrl + '/exchange/moil-to-mgas',
      args
    );
    return data;
  };

  const exchangeMgasToMoil = async (args: TransactionWithdrawType) => {
    const { data } = await api().post<MutationKeyToType['exchangeMgasToMoil']>(
      baseUrl + '/exchange/mgas-to-moil',
      args
    );
    return data;
  };

  const mutations = {
    transactionWithdraw,
    exchangeMoilToMgas,
    exchangeMgasToMoil,
  };

  if (!mutations.hasOwnProperty(key)) {
    throw new Error(`Invalid mutation key: ${key}`);
  }

  return useMutation<
    MutationKeyToType[K]['response'],
    unknown,
    MutationKeyToType[K]['args'],
    MutationTagType
  >(
    [key, ...tags],
    mutations[key] as unknown as MutationFunction<
      MutationKeyToType[K]['response'],
      MutationKeyToType[K]['args']
    >,
    options as any
  );
};
