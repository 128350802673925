import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Typography,
  useTheme,
} from '@mui/material';
import { ScanIcon } from 'assets/svg-components/Scan';
import { BackButton } from 'components/pages/AuthPage/AuthPage.support';
import { isValidAddress } from 'evm/utils/isValidAddress';
import { FC, useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { buttonSx } from 'theme/sxProps';
import * as yup from 'yup';
import { CoinActionPageProps } from '../../DashboardPage.types';
import { useWithdraw } from 'stores/useWithdraw';
import { config } from 'config';
import { formBoxSx, inputSx } from '../../DashboardPage.shared';
import { useNotify } from 'hooks/useToast';

type MoilWithdrawForm = {
  address: string;
  amount: number;
};

export const WithdrawTab: FC<CoinActionPageProps> = ({ item, setPage }) => {
  const title = item.identifier.split('.').pop()!;

  const { notify } = useNotify();
  const { shape, palette } = useTheme();
  const { loading, reset, setAmount, setLoading, setReceiver, withdraw } =
    useWithdraw();
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    reset: resetFields,
    formState: { errors, isValid },
  } = useForm<MoilWithdrawForm>({
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        address: yup
          .string()
          .required()
          .test('Invalid Ethereum address', (value) => isValidAddress(value)),
        amount: yup.number().moreThan(0).required(),
      })
    ),
  });

  const onSuccess = useCallback(async () => {
    reset();
    resetFields();
    notify({ type: 'success', meassage: 'Withdraw success!' });
  }, [notify, reset, resetFields]);

  const onError = useCallback(() => {
    notify({ type: 'error', meassage: 'Withdraw error!' });
  }, [notify]);

  const onSubmit: SubmitHandler<MoilWithdrawForm> = useCallback(async () => {
    setLoading(true);
    try {
      const token = config.tokens.find((t) => t.symbol === title)!;

      await withdraw(token);
      await onSuccess();
    } catch (e) {
      onError();
    } finally {
      setLoading(false);
    }
  }, [onError, onSuccess, setLoading, title, withdraw]);

  let amount = String(watch('amount'));
  let address = String(watch('address'));

  useEffect(() => {
    setAmount(amount);
    setReceiver(address);
  }, [address, amount, setAmount, setReceiver, watch]);

  return (
    <>
      <Box display="flex" alignItems="center">
        <BackButton
          sx={{ position: 'initial', width: '60px', height: '60px' }}
          handleBack={() => setPage('Default')}
        />
        <Typography variant="h1" ml="15px">
          Withdraw
        </Typography>
        <Box
          sx={{
            display: 'grid',
            placeItems: 'center',
            background: palette.primary.darker,
            width: 110,
            height: 60,
            ml: 'auto',
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <Typography variant="h3"> {title.toUpperCase()}</Typography>
        </Box>
      </Box>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={formBoxSx}>
        <Box
          display="flex"
          flexDirection="column"
          gap="20px"
          width="100%"
          height="105px"
          sx={{
            gap: '15px',
            p: '15px',
            background: palette.background.card,
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ color: palette.text.disabled }}>
              To Address
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography>
              <InputBase
                {...register('address')}
                type="text"
                fullWidth
                placeholder="-"
                error={!!errors.address}
                sx={inputSx({ width: 496 })}
              />
              {errors.address?.message && (
                <Typography color="error">{errors.address?.message}</Typography>
              )}
            </Typography>
            <IconButton sx={{ background: palette.primary.darker }}>
              <ScanIcon />
            </IconButton>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap="20px"
          width="100%"
          height="105px"
          sx={{
            p: '15px',
            background: palette.background.card,
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ color: palette.text.disabled }}>
              Amount
            </Typography>
            <Typography sx={{ color: palette.text.disabled }}>
              Balance: {item.amount}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ color: palette.secondary.main }}>
              <InputBase
                {...register('amount')}
                placeholder="0.00"
                error={!!errors.amount}
                sx={inputSx()}
              />
              {errors.amount?.message && (
                <Typography color="error">{errors.amount?.message}</Typography>
              )}
            </Typography>
            <Box display="flex" alignItems="center">
              {item.image && (
                <img src={item.image} width={24} height={24} alt={title} />
              )}
              <Typography ml="5px" fontWeight={600}>
                {title.toLocaleUpperCase()}
              </Typography>
              <Button
                onClick={() =>
                  item?.amount && setValue('amount', Number(item?.amount))
                }
                sx={{
                  ml: '20px',
                  background: palette.primary.dark,
                  color: palette.primary.main,
                  borderRadius: `${shape.borderRadius * 2}px`,
                }}
              >
                ALL
              </Button>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap="20px"
          width="100%"
          height="105px"
          sx={{
            p: '15px',
            background: palette.background.card,
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <Typography sx={{ color: palette.text.disabled }}>
            The network you have selected is{' '}
            <span style={{ fontWeight: '600' }}>BEP 20</span>. Please ensure
            that the withdrawal address supports the
            <span style={{ fontWeight: '600' }}> BEP 20</span> network. You will
            lose your assets if the chosen platform does not support retrievals.
          </Typography>
        </Box>

        <Button
          type="submit"
          disabled={!isValid || loading}
          sx={buttonSx({ mt: 'auto', width: '352px' })}
        >
          <Typography fontWeight={600}>
            {loading ? 'LOADING' : 'WITHDRAW'}
          </Typography>
        </Button>
      </Box>
    </>
  );
};
