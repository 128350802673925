import { ConfigTokenBalanceType, config } from 'config';
import { ethers } from 'ethers';
import { useWeb3 } from 'hooks';
import { useCallback, useEffect } from 'react';
import { useUserStore } from 'stores/useUserStore';
import { create, useStore } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { getErc20BalanceHelper } from '../evm/utils/getErc20BalanceHelper';
import { getSignerNativeBalanceHelper } from '../evm/utils/getSignerNativeBalanceHelper';

interface UserWalletBalanceStore {
  tokenBalances: ConfigTokenBalanceType[] | null;
  setTokenBalances: (payload: ConfigTokenBalanceType[] | null) => void;
}

export const store = create(
  persist<UserWalletBalanceStore>(
    (set, get) => ({
      tokenBalances: null,
      setTokenBalances: (payload) => {
        set({ tokenBalances: payload });
      },
    }),
    {
      name: 'moovy-user-wallet-balance',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export const useUserWalletBalanceStore = () => {
  const { tokenBalances, setTokenBalances } = useStore(store);
  const { provider } = useWeb3();
  const info = useUserStore((s) => s.info);

  const fetchTokenBalances = useCallback(async () => {
    try {
      if (!provider || !info?.wallet?.address) return null;

      const signer = new ethers.Wallet(info.privateKey!, provider);
      const balances = await Promise.all(
        config.tokens.map(async (token) => {
          let balance: string = '0';

          if (!token.address || !info.wallet?.address) {
            balance = await getSignerNativeBalanceHelper(signer);
          } else {
            const bnBalance = await getErc20BalanceHelper(
              token.address,
              info.wallet.address,
              signer
            );

            balance = ethers.utils.formatUnits(
              bnBalance ?? ethers.BigNumber.from(0),
              token.decimals
            );
          }

          return { ...token, balance };
        })
      );
      setTokenBalances(balances);
    } catch (e) {
      console.error(e);
    }
  }, [info?.privateKey, info?.wallet?.address, provider, setTokenBalances]);

  const refetch = () => fetchTokenBalances();

  useEffect(() => {
    let canFetch = provider && info?.wallet?.address;

    if (canFetch) {
      fetchTokenBalances();
    }
  }, [fetchTokenBalances, info?.wallet?.address, provider]);
  return { tokenBalances, fetchTokenBalances, refetch };
};
