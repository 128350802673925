import PersonAdd from '@mui/icons-material/PersonAdd';
import PowerSettingsNewRoundedIcon from '@mui/icons-material/PowerSettingsNewRounded';
import Settings from '@mui/icons-material/Settings';
import { Menu, MenuItem } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useWeb3 } from 'hooks';
import { useNotify } from 'hooks/useToast';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from 'stores/useUserStore';
import { localStorageUtil } from 'utils/localStorageUtils';

export const HeaderMenu = (props: {
  setAnchorEl: (payload: null | HTMLElement) => void;
  anchorEl: null | HTMLElement;
}) => {
  const { anchorEl, setAnchorEl } = props;
  const { logout, loggedWithWeb3 } = useWeb3();
  const { notify } = useNotify();
  const setInfo = useUserStore((s) => s.setInfo);
  const access_token = localStorageUtil.getItem({ key: 'access_token' });

  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem
        onClick={() => {
          navigate('/mynft');
          setAnchorEl(null);
        }}
        disabled={!loggedWithWeb3}
      >
        <ListItemIcon>
          <PersonAdd fontSize="small" />
        </ListItemIcon>
        My NFT
      </MenuItem>
      <MenuItem
        disabled={!loggedWithWeb3}
        onClick={() => {
          navigate('/settings');
          setAnchorEl(null);
        }}
      >
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        Settings
      </MenuItem>
      <MenuItem
        color="error"
        onClick={() => {
          if (access_token) {
            localStorageUtil.removeItem({ key: 'access_token' });
            setInfo({ wallet: undefined, bbid: undefined });
            logout();
            notify({ type: 'success', meassage: 'Logout success!' });
          }
          if (!access_token) {
            navigate('/auth');
          }
          setAnchorEl(null);
        }}
      >
        <ListItemIcon>
          <PowerSettingsNewRoundedIcon color="error" fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );
};
