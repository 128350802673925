import { Typography, useTheme } from '@mui/material';
import { Moovy } from 'assets/svg-components/Moovy';
import { useCallback, useMemo } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';

export const useNotify = () => {
  const { palette, shape } = useTheme();

  const getColor = useCallback(
    (type: 'success' | 'error' | 'info') => {
      switch (type) {
        case 'success':
          return palette.primary.main;
        case 'info':
          return palette.info.main;
        case 'error':
          return palette.error.main;
        default:
          return palette.primary.main;
      }
    },
    [palette.error.main, palette.info.main, palette.primary.main]
  );

  const notify = useCallback(
    (props: { meassage: string; type: 'success' | 'error' | 'info' }) =>
      toast[props.type](
        <Typography variant="h5" sx={{ color: getColor(props.type) }}>
          {props.meassage}
        </Typography>,
        {
          position: 'top-right',
          autoClose: 3000,
          icon: <Moovy width={32} height={32} fill={getColor(props.type)} />,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          style: {
            caretColor: palette.primary.main,
            background:
              'linear-gradient(179.16deg, rgba(49, 73, 74, 0.5) -7.44%, rgba(27, 23, 27, 0.5) 106%)',
            borderRadius: shape.borderRadius,
          },
        }
      ),
    [getColor, palette.primary.main, shape.borderRadius]
  );

  return useMemo(() => {
    return {
      notify,
      toast: <StyledToastContainer />,
    };
  }, [notify]);
};

const StyledToastContainer = styled(ToastContainer)`
  top: 5em;
`;
