import { MutationFunction, useMutation } from '@tanstack/react-query';
import { MutationOptionsType, MutationTagType } from 'api/types';
import { OpenContainer, OpenContainerResponse } from 'types/models';
import { useAxios } from '../axios';

type MutationKeyToType = {
  open: { args: OpenContainer; response: OpenContainerResponse };
};

type MutationKey = keyof MutationKeyToType;

export const useContainersMutation = <K extends MutationKey>(
  key: K,
  options?: MutationOptionsType<MutationKeyToType[K]['response']>,
  tags: string[] = []
) => {
  const { api } = useAxios();
  const baseUrl = '/containers';

  const open = async (args: OpenContainer) => {
    const { data } = await api().post<MutationKeyToType[K]['response']>(
      baseUrl + '/open',
      args
    );
    return data;
  };

  const mutations = {
    open,
  };

  if (!mutations.hasOwnProperty(key)) {
    throw new Error(`Invalid mutation key: ${key}`);
  }

  return useMutation<
    MutationKeyToType[K]['response'],
    unknown,
    MutationKeyToType[K]['args'],
    MutationTagType
  >(
    [key, ...tags],
    mutations[key] as unknown as MutationFunction<
      MutationKeyToType[K]['response'],
      MutationKeyToType[K]['args']
    >,
    options as any
  );
};
