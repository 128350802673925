import { useTheme } from '@mui/material';
import { TabType } from './Marketplace.types';
import { TabButton } from './TabButton';

export const useTabButton = (props: {
  tab: TabType;
  selectedTabInfo: TabType;
  setSelectedTabInfo: React.Dispatch<React.SetStateAction<TabType>>;
  Icon: any;
}) => {
  const { palette } = useTheme();
  const { selectedTabInfo, setSelectedTabInfo, tab, Icon } = props;
  const btnProps = {
    tab,
    size: 6,
    selectedTabInfo,
    isSelected: selectedTabInfo === tab,
    onClick: () => {
      setSelectedTabInfo(tab);
    },
    icon: (
      <Icon
        fill={
          selectedTabInfo === tab
            ? palette.background.default
            : palette.primary.main
        }
      />
    ),
  };
  return <TabButton sx={{ flex: 1 }} {...btnProps} />;
};
