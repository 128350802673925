import React, { useState } from 'react';
import {
  FormControl,
  InputBase,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  SelectProps,
  SxProps,
  Theme,
  styled,
  useTheme,
} from '@mui/material';
import { SystemStyleObject } from '@mui/system';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  padding: 0,
  borderRadius: theme.shape.borderRadius,
  '& .MuiInputBase-input': {
    padding: 0,
    borderRadius: 4,
    position: 'relative',
    backgroundColor: `${theme.palette.background.paper}px`,
    border: 'none',
    fontSize: 16,
    fontWeight: '500',
    paddingInline: '20px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderRadius: 4,
      borderColor: 'none',
    },
  },
}));

export const Select = (
  props: SelectProps & { options: { value: string; label: string }[] }
) => {
  const options = props?.options;
  const [value, setValue] = useState(options[0].value);
  const { palette } = useTheme();

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    setValue(event.target.value as string);
  };

  const sx =
    (styles?: SystemStyleObject<Theme>): SxProps<Theme> =>
    () => ({
      height: '60px',
      width: '250px',
      background: palette.background.card,
      ...styles,
    });

  return (
    <FormControl>
      <MuiSelect
        value={value}
        onChange={handleChange}
        input={<BootstrapInput />}
        {...props}
        sx={sx(props.sx as SystemStyleObject)}
      >
        {options.map((v, i) => {
          return (
            <MenuItem key={i} value={options[i].value}>
              {options[i].label}
            </MenuItem>
          );
        })}
      </MuiSelect>
    </FormControl>
  );
};
