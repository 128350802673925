import {
  CurrencyAmount,
  Fetcher,
  Percent,
  Route,
  Token,
  Trade,
  TradeType,
  WBNB,
} from '@pancakeswap/sdk';
import { config } from 'config';
import { Contract, ethers } from 'ethers';
import { ERC_20_ABI } from 'evm/ABIs/erc20.ABI';
import { SWAP_ABI } from 'evm/ABIs/swap.ABI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSwapStore } from 'stores/useSwapStore';
import { useUserStore } from 'stores/useUserStore';
import { useWeb3 } from './useWeb3';

export const useSwapTokens = () => {
  const { tokenTo, tokenFrom } = useSwapStore();
  const { provider } = useWeb3();

  const [amount, setAmount] = useState<number>(0);
  const [tradeNotAllowed, setTradeNotAllowed] = useState(false);
  const [liquidityError, setLiquidityError] = useState(false);
  const [tradePrice, setTradePrice] = useState<null | string>(null);

  const configTokenIn = useMemo(
    () => config.tokens.find((t) => t.symbol === tokenFrom)!,
    [tokenFrom]
  );
  const configTokenOut = useMemo(
    () => config.tokens.find((t) => t.symbol === tokenTo)!,
    [tokenTo]
  );

  const tokenIn = useMemo(() => {
    return configTokenIn.address
      ? new Token(
          config.chainId,
          configTokenIn.address,
          configTokenIn.decimals,
          configTokenIn.symbol
        )
      : WBNB[config.chainId];
  }, [configTokenIn.address, configTokenIn.decimals, configTokenIn.symbol]);

  const tokenOut = useMemo(() => {
    return configTokenOut.address
      ? new Token(
          config.chainId,
          configTokenOut.address,
          configTokenOut.decimals,
          configTokenOut.symbol
        )
      : WBNB[config.chainId];
  }, [configTokenOut.address, configTokenOut.decimals, configTokenOut.symbol]);

  const privateKey = useUserStore((s) => s.info?.privateKey);

  if (!privateKey) {
    throw Error;
  }
  const signer = useMemo(
    () => new ethers.Wallet(privateKey, provider),
    [privateKey, provider]
  );

  const router = useMemo(
    () => new ethers.Contract(config.routerAddress, SWAP_ABI, signer),
    [signer]
  );

  const wallet = useMemo(
    () => new ethers.Wallet(privateKey, provider),
    [privateKey, provider]
  );

  const slippageTolerance = new Percent('100000', '10000');

  const checkAllowence = useCallback(
    async (tokenIn: Token) => {
      const maxAmount = ethers.constants.MaxUint256;
      const tokenInContract = new Contract(tokenIn.address, ERC_20_ABI, wallet);
      const allowanceFunds = await tokenInContract.allowance(
        wallet.address,
        config.routerAddress
      );

      try {
        if (allowanceFunds.toBigInt() !== maxAmount.toBigInt()) {
          const approveTx = await tokenInContract.approve(
            config.routerAddress,
            maxAmount,
            {
              gasLimit: 200000,
            }
          );
          await approveTx.wait();
          setTradeNotAllowed(false);
        }
      } catch (e) {
        console.error(e);
        setTradeNotAllowed(true);
      }
    },
    [wallet]
  );

  const getPair = async (tokenIn: Token, tokenOut: Token) => {
    try {
      return await Fetcher.fetchPairData(tokenIn, tokenOut);
    } catch (error) {
      return null;
    }
  };

  const fetchPairPrice = useCallback(
    async (amount: number, tokenIn: Token, tokenOut: Token) => {
      const pair = await getPair(tokenIn, tokenOut);

      if (!pair) {
        setLiquidityError(true);
      } else {
        setLiquidityError(false);
        const route = new Route([pair], tokenIn, tokenOut);
        const trade = new Trade(
          route,
          CurrencyAmount.fromRawAmount(
            tokenIn,
            ethers.utils.parseEther(amount.toPrecision(6).toString()).toString()
          ),
          TradeType.EXACT_INPUT
        );

        setTradePrice(trade.outputAmount.toSignificant(6));
      }
    },
    []
  );

  useEffect(() => {
    checkAllowence(tokenIn);
  }, [checkAllowence, tokenIn]);

  useEffect(() => {
    const value = Number(amount);

    if (value && value !== 0 && value >= 0.000001) {
      fetchPairPrice(value, tokenIn, tokenOut);
    }
  }, [amount, fetchPairPrice, tokenIn, tokenOut]);

  const swapTokenToToken = async (amount: number) => {
    const pair = await getPair(tokenIn, tokenOut);

    if (!pair) {
      setLiquidityError(true);
    } else {
      const route = new Route([pair], tokenIn, tokenOut);
      const trade = new Trade(
        route,
        CurrencyAmount.fromRawAmount(
          tokenIn,
          ethers.utils.parseEther(amount.toString()).toString()
        ),
        TradeType.EXACT_INPUT
      );

      const amountOut = trade
        .minimumAmountOut(slippageTolerance)
        .toSignificant(6);

      const path = [tokenIn.address, tokenOut.address];
      const deadline = Math.floor(Date.now() / 1000) + 60 * 20;
      const txInAmount = ethers.utils.parseUnits(
        amount.toString(),
        tokenIn.decimals
      );

      const txOutAmount = ethers.utils.parseUnits(amountOut, tokenOut.decimals);
      const rawTxn = await router.swapExactTokensForTokens(
        txInAmount,
        txOutAmount,
        path,
        wallet.address,
        deadline,
        { gasLimit: 200000 }
      );
      let transactionResponse = await rawTxn;

      await transactionResponse.wait();
    }
  };

  const swapBnbToToken = async (amount: number) => {
    const pair = await getPair(tokenIn, tokenOut);

    if (!pair) {
      setLiquidityError(true);
    } else {
      const route = new Route([pair], tokenIn, tokenOut);
      const trade = new Trade(
        route,
        CurrencyAmount.fromRawAmount(
          tokenIn,
          ethers.utils.parseEther(amount.toString()).toString()
        ),
        TradeType.EXACT_INPUT
      );

      const amountOut = trade
        .minimumAmountOut(slippageTolerance)
        .toSignificant(6);

      const deadline = Math.floor(Date.now() / 1000) + 60 * 20;
      const txOutAmount = ethers.utils.parseUnits(amountOut, tokenOut.decimals);
      const path = route.path.map((tokens) => {
        return tokens.address;
      });

      const value = trade.inputAmount;
      const valueHex = ethers.utils.parseEther(value.toSignificant(6));
      const rawTxn = await router.swapExactETHForTokens(
        txOutAmount,
        path,
        wallet.address,
        deadline,
        {
          value: valueHex,
        }
      );

      let transactionResponse = await rawTxn;
      await transactionResponse.wait();
    }
  };

  const swapTokenToBnb = async (amount: number) => {
    const pair = await getPair(tokenIn, tokenOut);

    if (!pair) {
      setLiquidityError(true);
    } else {
      const route = new Route([pair], tokenIn, tokenOut);
      const trade = new Trade(
        route,
        CurrencyAmount.fromRawAmount(
          tokenIn,
          ethers.utils.parseEther(amount.toString()).toString()
        ),
        TradeType.EXACT_INPUT
      );

      const amountOut = trade
        .minimumAmountOut(slippageTolerance)
        .toSignificant(6);

      const deadline = Math.floor(Date.now() / 1000) + 60 * 20;
      const txInAmount = ethers.utils.parseUnits(
        amount.toString(),
        tokenIn.decimals
      );

      const txOutAmount = ethers.utils.parseUnits(amountOut, tokenOut.decimals);
      const path = route.path.map((tokens) => {
        return tokens.address;
      });

      const rawTxn = await router.swapExactTokensForETH(
        txInAmount,
        txOutAmount,
        path,
        wallet.address,
        deadline
      );

      let transactionResponse = await rawTxn;
      await transactionResponse.wait();
    }
  };

  const swapTokens = async (amount: number) => {
    if (tokenFrom === 'bnb') {
      await swapBnbToToken(amount);
    } else if (tokenTo === 'bnb') {
      await swapTokenToBnb(amount);
    } else {
      await swapTokenToToken(amount);
    }
  };

  return { swapTokens, tradeNotAllowed, tradePrice, liquidityError, setAmount };
};
