import { Box, Button, Typography, useTheme } from '@mui/material';
import { BackButton } from 'components/pages/AuthPage/AuthPage.support';
import QRCodeSVG from 'qrcode.react';
import { FC } from 'react';
import { useUserStore } from 'stores/useUserStore';
import { buttonSx } from 'theme/sxProps';
import { CoinActionPageProps } from '../../DashboardPage.types';

export const DepositTab: FC<CoinActionPageProps> = (props) => {
  const { shape, palette } = useTheme();
  const title = props.item.identifier.split('.').pop()!;
  const info = useUserStore((s) => s.info);

  return (
    <>
      <Box display="flex" alignItems="center">
        <BackButton
          sx={{ position: 'initial', width: '60px', height: '60px' }}
          handleBack={() => props.setPage('Default')}
        />
        <Typography variant="h1" ml="15px">
          Deposit
        </Typography>
        <Box
          sx={{
            display: 'grid',
            placeItems: 'center',
            background: 'rgba(129, 138, 137, 0.1)',
            width: 110,
            height: 60,
            ml: 'auto',
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <Typography variant="h3">{title.toUpperCase()}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: '40px',
          height: '508px',
          width: '710px',
          border: `2px solid ${palette.text.disabled}`,
          borderRadius: `${shape.borderRadius}px`,
          background: 'linear-gradient(135deg, #141313 0%, #18191B 100%)',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Box
          width={265}
          height={265}
          sx={{
            p: '25px',
            background: palette.primary.darker,
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <QRCodeSVG
            bgColor={palette.primary.main}
            style={{ width: '205px', height: '205px', color: 'red' }}
            value={info?.wallet?.address!}
          />
        </Box>
        <Typography mt="40px" fontWeight={500}>
          Scan address to recieve payment
        </Typography>
        <Box
          mt="10px"
          sx={{
            display: 'grid',
            placeItems: 'center',
            height: '40px',
            width: '352px',
            background: palette.primary.darker,
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <Typography color="primary">{info?.wallet?.address}</Typography>
        </Box>
        <Button
          sx={buttonSx({ mt: '10px', width: '352px' })}
          onClick={() => {
            navigator.clipboard.writeText(info?.wallet?.address!);
          }}
        >
          <Typography fontWeight={600}>COPY ADDRESS</Typography>
        </Button>
      </Box>
    </>
  );
};
