import { useMarketQuery } from 'api/queries/marketQuery';
import { useUserStore } from 'stores/useUserStore';

export const MarketInteractor = () => {
  const { refetch } = useMarketQuery('marketItems');
  const { setMarketContainer, setMarketCarsState } = useUserStore.getState();
  const { refetch: refetchCars } = useMarketQuery('marketCars');

  const fethMarketCars = async () => {
    refetchCars()
      .then((res: any) => {
        setMarketCarsState(res.data);
      })
      .catch((err: any) => {
        console.log('[breed price error] : ', err);
      });
  };

  const fethMarketContainer = async () => {
    refetch()
      .then((res: any) => {
        setMarketContainer(res.data);
      })
      .catch((err: any) => {
        console.log('[breed price error] : ', err);
      });
  };
  return { fethMarketCars, fethMarketContainer };
};
