import { Box, styled } from '@mui/material';

export const ScLogo = styled(Box)(({ theme }) => ({
  display: 'grid',
  placeItems: 'center',
  height: 70,
  width: 70,
  padding: 18,
  background: theme.palette.gradient.blue,
  img: {
    height: '100%',
    width: '100%',
  },
}));
