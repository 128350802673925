import React from 'react';

export const ActiveRouteIcon = (props: {
  fill?: string;
  height?: number;
  width?: number;
  style?: React.SVGProps<SVGSVGElement>['style'];
}) => {
  const width = 20;
  const height = 3;
  const defaultColor = '#21E7D6';
  return (
    <svg
      style={props.style}
      width={props?.width ?? width}
      height={props?.height ?? height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="3" height="3" fill={props?.fill ?? defaultColor} />
      <path d="M10 0L11.5 3H8.5L10 0Z" fill={props?.fill ?? defaultColor} />
      <circle cx="18" cy="1.5" r="1.5" fill={props?.fill ?? defaultColor} />
    </svg>
  );
};
