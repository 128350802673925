import * as yup from 'yup';

export const PasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .max(32, 'Password is too long - should be 32 chars maximum.')
    .required('No password provided.'),
  passwordRepeat: yup
    .string()
    .min(8, 'Password is too short - should be 8 chars minimum.')
    .max(32, 'Password is too long - should be 32 chars maximum.')
    .required('Repeat password is required')
    .oneOf([yup.ref('password')], 'Passwords must match'),
  agree1: yup.boolean().oneOf([true], 'You must agree'),
  agree2: yup.boolean().oneOf([true], 'You must agree'),
  agree3: yup.boolean().oneOf([true], 'You must agree'),
});
