import { ArrowBackRounded } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { useBbidQuery } from 'api';
import GenerateBbidPhoto from 'assets/images/GenerateBbid.png';
import { Fragment, useEffect, useState } from 'react';
import { useUserStore } from 'stores/useUserStore';

export const GenerateBbid = (props: {
  allowNextStep: (arg: boolean) => void;
  goBack: () => void;
}) => {
  const { allowNextStep, goBack } = props;
  const [allowContinue, setAllowContinue] = useState(false);
  const { data, refetch, isSuccess } = useBbidQuery('get', {
    enabled: true,
  });
  const setInfo = useUserStore((s) => s.setInfo);

  useEffect(() => {
    if (isSuccess && allowContinue) {
      const bbid = data?.data?.bbid;

      setInfo({ bbid });
      allowNextStep(true);
    }
  }, [allowContinue, allowNextStep, data?.data.bbid, isSuccess, setInfo]);

  return (
    <Fragment>
      <Box
        sx={{
          position: 'absolute',
          zIndex: 1,
          top: 124,
          left: 0,
          width: 70,
          height: 70,
          background: 'rgba(129, 138, 137, 0.1)',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <IconButton onClick={goBack} size="large">
          <ArrowBackRounded color="primary" fontSize="small" />
        </IconButton>
      </Box>
      <Typography variant="h3">Generate BBID</Typography>

      <img
        src={GenerateBbidPhoto}
        style={{ cursor: 'pointer' }}
        alt=""
        onClick={() => {
          setAllowContinue(true);
          refetch();
        }}
      />
    </Fragment>
  );
};
