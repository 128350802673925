import React from 'react';

export const CryptoCoinIcon = (props: {
  fill?: string;
  height?: number;
  width?: number;
}) => {
  const width = 18;
  const height = 18;
  const defaultColor = 'black';

  return (
    <svg
      width={props?.width ?? width}
      height={props?.height ?? height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.22635 10.8701C5.24108 10.8849 5.25582 10.8996 5.27056 10.9143C6.35123 11.995 7.4319 13.0757 8.50766 14.1514C8.55679 14.2006 8.54696 14.2006 8.601 14.1514C9.68167 13.0708 10.7623 11.9901 11.8381 10.9143C11.8528 10.8996 11.8676 10.8849 11.8774 10.875C12.5209 11.5185 13.1595 12.162 13.8079 12.8104C13.803 12.8153 13.7931 12.8252 13.7833 12.835C12.0542 14.5641 10.3202 16.298 8.59117 18.0271C8.55679 18.0615 8.54205 18.0566 8.51258 18.0271C6.7835 16.298 5.05442 14.569 3.32534 12.8399C3.31552 12.8301 3.30078 12.8153 3.30078 12.8104C3.94427 12.162 4.58285 11.5185 5.22635 10.8701Z"
        fill={props?.fill ?? defaultColor}
      />
      <path
        d="M3.30078 6.18386C3.31061 6.17895 3.32534 6.16912 3.34008 6.1593C5.06424 4.43513 6.79332 2.70605 8.51749 0.981888C8.5617 0.937678 8.5617 0.937678 8.60591 0.981888C10.3301 2.70605 12.0591 4.43513 13.7833 6.1593C13.7981 6.17403 13.8079 6.18386 13.8177 6.19368C13.1742 6.83718 12.5307 7.48067 11.8872 8.12416C11.8774 8.11434 11.8627 8.0996 11.8479 8.08978C10.7673 7.0091 9.69149 5.93334 8.61082 4.85266C8.60099 4.84284 8.59117 4.83302 8.58135 4.82319C8.56661 4.80354 8.55187 4.80354 8.53714 4.82319C8.52731 4.83302 8.51749 4.84284 8.50766 4.85266C7.4319 5.92843 6.35123 7.0091 5.27547 8.08486C5.26073 8.0996 5.2509 8.11925 5.24599 8.12907C4.59759 7.47576 3.9541 6.83226 3.30078 6.18386Z"
        fill={props?.fill ?? defaultColor}
      />
      <path
        d="M8.55559 11.4498C7.90719 10.8014 7.25387 10.1481 6.60547 9.49969C7.25387 8.85128 7.90719 8.19796 8.55068 7.54956C9.19909 8.19796 9.8524 8.85128 10.5008 9.49969C9.86223 10.1481 9.20891 10.8014 8.55559 11.4498Z"
        fill={props?.fill ?? defaultColor}
      />
      <path
        d="M3.85481 9.49902C3.8499 9.50884 3.84499 9.51375 3.84008 9.51866C3.83025 9.52849 3.82534 9.5334 3.81552 9.54322C3.2015 10.1572 2.58748 10.7713 1.96855 11.3902C1.92434 11.4344 1.92434 11.4344 1.87521 11.3902C1.2612 10.7762 0.647176 10.1622 0.033157 9.54814C-0.0110523 9.50393 -0.0110523 9.50392 0.033157 9.45972C0.647176 8.8457 1.26611 8.22677 1.88013 7.61275C1.92434 7.56854 1.92434 7.56854 1.96363 7.61275C2.57765 8.22677 3.19658 8.8457 3.8106 9.45972C3.83025 9.46954 3.84499 9.48428 3.85481 9.49902Z"
        fill={props?.fill ?? defaultColor}
      />
      <path
        d="M17.1201 9.49945C17.1053 9.51419 17.0955 9.52401 17.0857 9.53383C16.8303 9.78926 16.5748 10.0447 16.3194 10.3001C15.9559 10.6636 15.5924 11.0271 15.2289 11.3906C15.1896 11.4299 15.1896 11.4299 15.1503 11.3906C14.5314 10.7717 13.9124 10.1528 13.2935 9.53383C13.2591 9.50436 13.264 9.48471 13.2935 9.45524C13.9124 8.83631 14.5314 8.21738 15.1503 7.59844C15.1798 7.56897 15.1945 7.56406 15.2289 7.59844C15.8478 8.21738 16.4668 8.83631 17.0857 9.45524C17.0955 9.47489 17.1053 9.48471 17.1201 9.49945Z"
        fill={props?.fill ?? defaultColor}
      />
    </svg>
  );
};
