import React from 'react';

export const ScanIcon = (props: {
  fill?: string;
  height?: number;
  width?: number;
  style?: React.SVGProps<SVGSVGElement>['style'];
}) => {
  const width = 24;
  const height = 24;
  const defaultColor = '#21E7D6';
  return (
    <svg
      width={props?.width ?? width}
      height={props?.height ?? height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.65714 20.2286C8.65714 20.6546 8.31176 21 7.88571 21C5.18741 21 3 18.8126 3 16.1143C3 15.6882 3.34538 15.3429 3.77143 15.3429C4.19748 15.3429 4.54286 15.6882 4.54286 16.1143C4.54286 17.9605 6.0395 19.4571 7.88571 19.4571C8.31176 19.4571 8.65714 19.8025 8.65714 20.2286Z"
        fill={props?.fill ?? defaultColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2286 8.65714C19.8025 8.65714 19.4571 8.31176 19.4571 7.88571C19.4571 6.0395 17.9605 4.54286 16.1143 4.54286C15.6882 4.54286 15.3429 4.19747 15.3429 3.77143C15.3429 3.34538 15.6882 3 16.1143 3C18.8126 3 21 5.18741 21 7.88571C21 8.31176 20.6546 8.65714 20.2286 8.65714Z"
        fill={props?.fill ?? defaultColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 16.1143C21 18.8126 18.8126 21 16.1143 21C15.6882 21 15.3429 20.6546 15.3429 20.2286C15.3429 19.8025 15.6882 19.4571 16.1143 19.4571C17.9605 19.4571 19.4571 17.9605 19.4571 16.1143C19.4571 15.6882 19.8025 15.3429 20.2286 15.3429C20.6546 15.3429 21 15.6882 21 16.1143Z"
        fill={props?.fill ?? defaultColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.77143 8.65714C3.34538 8.65714 3 8.31176 3 7.88571C3 5.18741 5.18741 3 7.88572 3C8.31176 3 8.65714 3.34538 8.65714 3.77143C8.65714 4.19747 8.31176 4.54286 7.88572 4.54286C6.03951 4.54285 4.54286 6.0395 4.54286 7.88571C4.54286 8.31176 4.19748 8.65714 3.77143 8.65714Z"
        fill={props?.fill ?? defaultColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.55363 11.1669C10.7477 12.1421 13.2523 12.1421 15.4464 11.1669L16.3153 10.7808C16.7046 10.6077 17.1605 10.7831 17.3335 11.1724C17.5065 11.5617 17.3312 12.0176 16.9419 12.1907L16.073 12.5768C13.4799 13.7293 10.5201 13.7293 7.92701 12.5768L7.05812 12.1907C6.66879 12.0176 6.49345 11.5617 6.66649 11.1724C6.83952 10.7831 7.29541 10.6077 7.68474 10.7808L8.55363 11.1669Z"
        fill={props?.fill ?? defaultColor}
      />
    </svg>
  );
};
