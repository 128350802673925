import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { handleAxiosError, useUserMutation } from 'api';
import { Input } from 'components/ui';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUserStore } from 'stores/useUserStore';
import { buttonSx } from 'theme/sxProps';
import * as yup from 'yup';

export const ResetForm = (props: {
  setResetSuccess: (key: boolean) => void;
  pincode: string;
}) => {
  const email = useUserStore((s) => s.info?.email!);

  const { setResetSuccess, pincode } = props;
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<{ newPassword: string; passwordRepeat: string }>({
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        newPassword: yup
          .string()
          .min(8, 'Password is too short - should be 8 chars minimum.')
          .max(32, 'Too long, need 8-32 symbols')
          .required('No password provided.'),
        passwordRepeat: yup
          .string()
          .min(8, 'Password is too short - should be 8 chars minimum.')
          .max(32, 'Password is too long - should be 32 chars maximum.')
          .required('Repeat password is required')
          .oneOf([yup.ref('newPassword')], 'Passwords must match'),
      })
    ),
  });

  const {
    mutate,
    isSuccess,
    isError,
    error: mutateError,
  } = useUserMutation('changePasswordWithEmail');

  const onSubmit: SubmitHandler<{
    newPassword: string;
    passwordRepeat: string;
  }> = useCallback(
    ({ newPassword }) => {
      mutate({ email, newPassword, pincode });
    },
    [email, pincode, mutate]
  );

  useEffect(() => {
    if (isSuccess) {
      setResetSuccess(isSuccess);
    }
  }, [isSuccess, setResetSuccess]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} marginBottom={5}>
        <Input
          text="Password"
          placeholder="Qwerty123"
          fullWidth
          required
          message={errors.newPassword?.message || handleAxiosError(mutateError)}
          error={!!errors.newPassword || isError}
          {...register('newPassword')}
        />
        <Input
          text="Repeat Password"
          placeholder="Qwerty123"
          fullWidth
          required
          message={
            errors.passwordRepeat?.message || handleAxiosError(mutateError)
          }
          error={!!errors.passwordRepeat || isError}
          {...register('passwordRepeat')}
        />
        <LoadingButton
          fullWidth
          type="submit"
          sx={buttonSx({ mb: '16px' })}
          disabled={!isValid}
        >
          <Typography variant="h5" fontWeight="700">
            Send
          </Typography>
        </LoadingButton>
      </Stack>
    </Box>
  );
};
