import { SxProps, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export const buttonSx =
  (styles?: SystemStyleObject<Theme>): SxProps<Theme> =>
  ({ shape, palette }) => ({
    borderRadius: `${shape.borderRadius}`,
    background: palette.gradient.blue,
    maxWidth: 454,
    height: '60px',
    color: palette.background.paper,
    '&:disabled': {
      color: palette.background.paper,
      opacity: 0.3,
    },
    ...styles,
  });

export const authContainerSx =
  (styles?: SystemStyleObject<Theme>): SxProps<Theme> =>
  () => ({
    display: 'flex',
    flexDirection: 'column',
    rowGap: 1.25,
    paddingTop: 6,
    paddingInline: 12,
    width: 635,
    ...styles,
  });

export const containerSx =
  (styles?: SystemStyleObject<Theme>): SxProps<Theme> =>
  () => ({
    display: 'flex',
    flexDirection: 'column',
    rowGap: '40px',
    pt: '65px',
    ...styles,
  });
