import React from 'react';

export const CarIcon = (props: {
  fill?: string;
  height?: number;
  width?: number;
}) => {
  const width = 24;
  const height = 14;
  const defaultColor = 'white';

  return (
    <svg
      width={props?.width ?? width}
      height={props?.height ?? height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.55643 1.01435e-05C9.5178 0.000847372 9.47929 0.00449098 9.44118 0.0109122H7.17667C6.34805 0.0109122 5.45806 0.357347 4.65362 1.16809C3.88122 1.94653 3.09432 3.1463 2.09787 5.0025C0.879752 5.45224 0 6.61565 0 7.98499V9.5798C0 10.9011 1.07092 11.972 2.39222 11.972H2.43272C2.62999 13.316 3.78774 14.3643 5.18315 14.3643C6.57856 14.3643 7.73631 13.316 7.93358 11.972H16.7861C16.9833 13.316 18.1411 14.3643 19.5365 14.3643C20.9319 14.3643 22.0896 13.316 22.2869 11.972H22.3274C23.2086 11.972 23.9222 11.2583 23.9222 10.3772V8.7824C23.4821 8.7824 23.1248 8.42516 23.1248 7.98499C23.1248 7.70191 23.2808 7.46422 23.5033 7.32308C22.8465 6.47234 21.2868 5.08721 18.1317 4.8452C16.9475 3.04687 16.006 1.87572 15.2037 1.14784C14.2938 0.32238 13.4581 0.0109122 12.7585 0.0109122H9.69193C9.64716 0.00342844 9.60183 -0.000218809 9.55643 1.01435e-05ZM7.17667 1.60573H8.77149V4.79536H4.03376C4.74562 3.5618 5.32355 2.75694 5.78588 2.291C6.38188 1.69033 6.69834 1.60573 7.17667 1.60573ZM10.3663 1.60573H12.7585C13.0581 1.60573 13.4066 1.67006 14.1322 2.32838C14.6518 2.79982 15.3291 3.59209 16.1755 4.79536H10.3663V1.60573ZM5.18315 10.3772C5.85319 10.3772 6.37926 10.9033 6.37926 11.5733C6.37926 12.2434 5.85319 12.7694 5.18315 12.7694C4.51311 12.7694 3.98704 12.2434 3.98704 11.5733C3.98704 10.9033 4.51311 10.3772 5.18315 10.3772ZM19.5365 10.3772C20.2065 10.3772 20.7326 10.9033 20.7326 11.5733C20.7326 12.2434 20.2065 12.7694 19.5365 12.7694C18.8664 12.7694 18.3404 12.2434 18.3404 11.5733C18.3404 10.9033 18.8664 10.3772 19.5365 10.3772Z"
        fill={props?.fill ?? defaultColor}
      />
    </svg>
  );
};
