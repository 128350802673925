export type ConfigTokenType = {
  symbol: 'moil' | 'bnb' | 'busd';
  address: string | null;
  decimals: number;
};

export type ConfigTokenBalanceType = ConfigTokenType & {
  balance: string;
};

export type ConfigType = {
  api: string;
  routerAddress: string;
  chainIdHash: string;
  chainId: 97 | 56;
  rpcTarget: string;
  tokens: ConfigTokenType[];
  vault_keeper: string;
};

const prodApi = 'https://moovy-backend-production.up.railway.app/api';
const devApi = 'https://moovy-backend-dev.up.railway.app/api';

const testnet: ConfigType = {
  api: devApi,
  routerAddress: '0xD99D1c33F9fC3444f8101754aBC46c52416550D1',
  vault_keeper: '0xDf60fee5982F19ED3Caa6240a6b16b9E4703d603',
  chainIdHash: '0x61',
  chainId: 97,
  rpcTarget: 'https://rpc.ankr.com/bsc_testnet_chapel',
  tokens: [
    {
      symbol: 'moil',
      address: '0x82abB4eF944dC4bd6a205b80c006eC3E51bD3b95',
      decimals: 18,
    },
    {
      symbol: 'busd',
      address: '0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814',
      decimals: 18,
    },
    {
      symbol: 'bnb',
      address: null,
      decimals: 18,
    },
  ],
};

const mainnet: ConfigType = {
  api: prodApi,
  routerAddress: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
  vault_keeper: '0x41127A5CEdB79163C8832abc69eec78f05C72CCe',
  chainIdHash: '0x38',
  chainId: 56,
  rpcTarget: 'https://bsc.publicnode.com',
  tokens: [
    {
      symbol: 'moil',
      address: '0xa0a4c12aa90fe439b07b16657cd2c12e4d41e25f',
      decimals: 18,
    },
    {
      symbol: 'busd',
      address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      decimals: 18,
    },
    {
      symbol: 'bnb',
      address: null,
      decimals: 18,
    },
  ],
};

export const config =
  process.env.REACT_APP_ENV === 'testnet' ? testnet : mainnet;
