import styled from 'styled-components';
import { UsersCarsSale } from 'types/models';
import { CarItem } from './CarItem';

export const CarsList = ({ cars }: { cars: UsersCarsSale | undefined }) => {
  return (
    <CarList>
      {cars?.data.map((car) => (
        <CarItem car={car} key={car.id} />
      ))}
    </CarList>
  );
};

const CarList = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
  padding: 30px;
  border-radius: 10px;
  background: linear-gradient(
    135deg,
    rgba(33, 231, 214, 0.08) 0%,
    rgba(131, 218, 255, 0.08) 100%
  );
  backdrop-filter: blur(21.481481552124023px);
`;
