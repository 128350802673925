import { Grid, Paper, SxProps, styled } from '@mui/material';
import { fontFamily } from 'theme/fontFamily';

export const GridCardItem = ({
  children,
  onClick,
  img,
  selected,
  sx,
}: {
  children: React.ReactElement;
  onClick: () => void;
  img: string;
  selected: boolean;
  sx?: SxProps;
}) => {
  return (
    <Grid item xs={8} onClick={onClick} sx={sx}>
      <Item
        sx={{
          border: selected ? '2px solid cyan' : '2px solid transparent',
        }}
      >
        <img
          style={{ width: '100%', height: '100%', borderRadius: 15 }}
          src={img}
          alt=""
        />
        <Grid
          container
          gap={1}
          columns={16}
          m={'auto'}
          width={'100%'}
          justifyContent={'center'}
        >
          {children}
        </Grid>
      </Item>
    </Grid>
  );
};

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    'linear-gradient(138.69deg, #141313 0%, #18191B 100%), linear-gradient(0deg, #21E7D6, #21E7D6),linear-gradient(180deg, #424D50 0%, #202526 100%)',
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  fontFamily: fontFamily,
  fontWeight: 'bold',
}));
