import {
  AppBar,
  Avatar,
  Box,
  ButtonBase,
  Container,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import user from 'assets/images/User.svg';
import { MenuIcon } from 'assets/svg-components/Menu';
import { Fragment, useState } from 'react';
import { routes } from 'routes/routes';
import { Logo } from '../../ui';
import { AccountAbout, RightSection } from './Header.styles';
import { HeaderMenu } from './HeaderMenu';
import { RouteButton } from './RouteButton';
import { useUserStore } from 'stores/useUserStore';

export const Header = () => {
  const { dashboard, marketplace, lottery, staking } = routes;
  const { palette, shape } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const info = useUserStore((s) => s.info);
  const image = false;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  return (
    <Fragment>
      <AppBar position="static">
        <Container
          sx={{
            '&.MuiContainer-root': { padding: 0, maxWidth: 'none' },
            background: ({ palette }) => palette.primary.dark,
          }}
        >
          <Toolbar disableGutters>
            <Logo />
            <Box sx={{ flexGrow: 1, ml: 6, display: 'flex' }}>
              <RouteButton
                {...{ title: dashboard.title, path: dashboard.path }}
              />
              <RouteButton
                {...{ title: marketplace.title, path: marketplace.path }}
              />
              <RouteButton
                {...{
                  title: staking.title,
                  path: staking.path,
                  link: staking.href,
                }}
              />
              <RouteButton {...{ title: lottery.title, path: lottery.path }} />
            </Box>
            <RightSection>
              <AccountAbout
                sx={{ height: '70px', justifyContent: 'space-between' }}
              >
                <Box display="flex" alignItems="center">
                  {image && <Avatar alt="avatar image" src={user} />}
                  {!image && (
                    <Tooltip title="Account settings">
                      <IconButton
                        sx={{ background: palette.gradient.green }}
                        size="large"
                        onClick={handleClick}
                      >
                        <img src={user} alt="avatar" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Typography ml="10px" fontWeight="700">
                    {info?.bbid}ID
                  </Typography>
                </Box>
                <Tooltip title="Open notifications">
                  <ButtonBase
                    sx={{
                      height: '42px',
                      width: '42px',
                      p: 0,
                      borderRadius: `${shape.borderRadius}px`,
                      background: palette.gradient.green,
                    }}
                  >
                    <MenuIcon />
                  </ButtonBase>
                </Tooltip>
              </AccountAbout>
            </RightSection>
          </Toolbar>
        </Container>
      </AppBar>
      <HeaderMenu {...{ anchorEl, setAnchorEl }} />
    </Fragment>
  );
};
