import {
  FormControl,
  FormHelperText,
  FormHelperTextTypeMap,
  IconButton,
  InputAdornment,
  OutlinedInput,
  OutlinedInputProps,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SystemStyleObject } from '@mui/system';
import AttentionIcon from 'assets/images/Attention.svg';
import HiddenPassword from 'assets/images/hidden-password.svg';
import { ForwardedRef, ReactNode, forwardRef, useState } from 'react';

export const Input = forwardRef(
  (
    props: OutlinedInputProps & {
      text?: ReactNode;
      message?: ReactNode;
      helperTextProps?: OverridableComponent<FormHelperTextTypeMap<{}, 'p'>>;
      showIcon?: boolean;
    },
    ref: ForwardedRef<any>
  ): JSX.Element => {
    const { palette } = useTheme();
    const [focused, setFocused] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const inputSx =
      (
        styles?: SystemStyleObject<Theme>,
        error?: boolean,
        focus?: boolean
      ): SxProps<Theme> =>
      ({ shape, palette }) => ({
        borderRadius: `${shape.borderRadius}px`,
        background: palette.background.card,
        backgroundImage: 'none',
        '.MuiInputBase-input': { height: '27px' },
        '.MuiOutlinedInput-notchedOutline': {
          borderRadius: `${shape.borderRadius}px`,
          border: error || focused ? 'solid 1px' : 'none',
          borderColor: focus ? palette.primary.main : palette.error.main,
          boxShadow: 'none',
          outline: 'none',
          fontSize: '1rem',
          fontWeight: 'normal',
        },
        ...styles,
      });

    const handleClickShowPassword = () => setShowPassword((o) => !o);
    const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) =>
      e.preventDefault();

    const PasswordInputIcon = () => (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          <img src={HiddenPassword} alt="hide" />
        </IconButton>
      </InputAdornment>
    );

    return (
      <FormControl variant="standard" fullWidth={props?.fullWidth}>
        <Typography
          color={props.error ? palette.text.main : 'secondary'}
          fontWeight={500}
          mb={props?.text ? '5px' : 0}
        >
          {props?.text}
        </Typography>
        <OutlinedInput
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          type={showPassword ? 'text' : 'password'}
          {...props}
          endAdornment={props?.showIcon ? <PasswordInputIcon /> : <></>}
          sx={inputSx(props.sx as SystemStyleObject, props.error, focused)}
          ref={ref}
        />
        <FormHelperText
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: 500,
            color: palette.error.main,
          }}
          {...props.helperTextProps}
        >
          {props?.message && (
            <img style={{ padding: 8 }} src={AttentionIcon} alt="" />
          )}
          {props?.message}
        </FormHelperText>
      </FormControl>
    );
  }
);
