export const EfficiencyIcon = () => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.05 19V13L21.05 16L16.05 19ZM11.05 19V13L16.05 16L11.05 19ZM7.3 20L6.9 16.8C6.6833 16.7167 6.4791 16.6167 6.2875 16.5C6.0958 16.3833 5.9083 16.2583 5.725 16.125L2.75 17.375L0 12.625L2.575 10.675C2.5583 10.5583 2.55 10.4458 2.55 10.3375V9.6625C2.55 9.5542 2.5583 9.4417 2.575 9.325L0 7.375L2.75 2.625L5.725 3.875C5.9083 3.7417 6.1 3.6167 6.3 3.5C6.5 3.3833 6.7 3.2833 6.9 3.2L7.3 0H12.8L13.2 3.2C13.4166 3.2833 13.6208 3.3833 13.8125 3.5C14.0041 3.6167 14.1916 3.7417 14.375 3.875L17.35 2.625L20.1 7.375L17.525 9.325C17.5416 9.4417 17.55 9.5542 17.55 9.6625V10.5C17.55 10.6667 17.5333 10.8333 17.5 11H15.45C15.4833 10.8333 15.5083 10.6667 15.525 10.5C15.5416 10.3333 15.55 10.1667 15.55 10C15.5333 9.6833 15.5083 9.4042 15.475 9.1625C15.4416 8.9208 15.3916 8.6917 15.325 8.475L17.475 6.85L16.5 5.15L14.025 6.2C13.6583 5.8167 13.2541 5.4958 12.8125 5.2375C12.3708 4.9792 11.8916 4.7833 11.375 4.65L11.05 2H9.075L8.725 4.65C8.2083 4.7833 7.7291 4.9792 7.2875 5.2375C6.8458 5.4958 6.4416 5.8083 6.075 6.175L3.6 5.15L2.625 6.85L4.775 8.45C4.6916 8.7 4.6333 8.95 4.6 9.2C4.5666 9.45 4.55 9.7167 4.55 10C4.55 10.2667 4.5666 10.525 4.6 10.775C4.6333 11.025 4.6916 11.275 4.775 11.525L2.625 13.15L3.6 14.85L6.075 13.8C6.475 14.2167 6.925 14.5667 7.425 14.85C7.925 15.1333 8.4666 15.3167 9.05 15.4V20H7.3ZM9.05 13.35V11.075C8.9166 10.9417 8.8083 10.7833 8.725 10.6C8.6416 10.4167 8.6 10.2167 8.6 10C8.6 9.5833 8.7458 9.2292 9.0375 8.9375C9.3291 8.6458 9.6833 8.5 10.1 8.5C10.5166 8.5 10.8708 8.6458 11.1625 8.9375C11.4541 9.2292 11.6 9.5833 11.6 10C11.6 10.1833 11.5708 10.3625 11.5125 10.5375C11.4541 10.7125 11.3583 10.8667 11.225 11H13.45C13.5 10.8333 13.5375 10.6708 13.5625 10.5125C13.5875 10.3542 13.6 10.1833 13.6 10C13.6 9.0333 13.2583 8.2083 12.575 7.525C11.8916 6.8417 11.0666 6.5 10.1 6.5C9.1166 6.5 8.2875 6.8417 7.6125 7.525C6.9375 8.2083 6.6 9.0333 6.6 10C6.6 10.8 6.8291 11.5 7.2875 12.1C7.7458 12.7 8.3333 13.1167 9.05 13.35Z"
        fill="#939393"
      />
    </svg>
  );
};
