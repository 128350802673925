import { Button, Typography, useTheme } from '@mui/material';
import { ActiveRouteIcon } from 'assets/svg-components/ActiveRoute';
import { useLocation, useNavigate } from 'react-router-dom';

export const RouteButton = (props: {
  path: string;
  title: string;
  link?: string;
}) => {
  const { path, title, link } = props;
  const { palette } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isActiveRoute = location.pathname === path;

  return (
    <Button
      sx={{
        display: 'block',
        position: 'relative',
        color: isActiveRoute ? palette.primary.main : palette.text.main,
        ml: 4,
        my: 2,
      }}
      onClick={(e) => {
        if (link) {
          e.preventDefault();
          window.open(link, '_blank');
        } else navigate(path);
      }}
    >
      <Typography fontWeight="700">{title}</Typography>
      {isActiveRoute && (
        <ActiveRouteIcon
          style={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        />
      )}
    </Button>
  );
};
