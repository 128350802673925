import { Grid } from '@mui/material';
import { GridList } from 'components/ui/GridList';
import React from 'react';
import { Car, UsersCars } from 'types/models';
import { MAX_BREED_COUNT } from '../../../../../constants';
import { CarItem } from './CarItem';

export const CarsList = ({
  isSelectEnabled,
  cars,
  onItemClick,
  selectedCars,
}: {
  isSelectEnabled: boolean;
  cars: UsersCars | undefined;
  selectedCars: number[];
  onItemClick: (car: Car) => void;
}) => {
  return (
    <GridList height={isSelectEnabled ? 422 : 502}>
      <Grid container spacing={2} columns={16}>
        {cars?.data.map((car) =>
          car.breeds_count === MAX_BREED_COUNT && isSelectEnabled ? (
            <React.Fragment key={car.id}></React.Fragment>
          ) : (
            <CarItem
              car={car}
              key={car.id}
              selectedCars={selectedCars}
              onClick={onItemClick}
              isSelectEnabled={isSelectEnabled}
            />
          )
        )}
      </Grid>
    </GridList>
  );
};
