import { Box, useTheme } from '@mui/material';
import Cars from 'assets/images/auth-background/cars.png';
import Carousel from 'react-material-ui-carousel';

const carousel_height = 780;
const carousel_width = 1100;
const items = [
  { photo: Cars, id: 1 },
  { photo: Cars, id: 2 },
  { photo: Cars, id: 3 },
];

export const CarsCarousel = () => {
  const { palette } = useTheme();

  return (
    <Carousel
      autoPlay
      interval={4000}
      indicatorIconButtonProps={{
        style: {
          marginRight: 26,
        },
      }}
      activeIndicatorIconButtonProps={{
        style: {
          color: palette.primary.main,
          transform: 'scale(1.2)',
        },
      }}
      indicatorContainerProps={{
        style: {
          zIndex: 1,
          marginTop: -88,
          position: 'relative',
        },
      }}
      sx={{
        position: 'absolute',
        height: carousel_height,
        width: carousel_width,
        right: 0,
        bottom: 0,
        '&.MuiSvgIcon-root': {
          color: 'red',
        },
      }}
    >
      {items.map((v) => (
        <Box key={v.id} height={carousel_height} width={carousel_width}>
          <img
            height={carousel_height}
            width={carousel_width}
            style={{ objectFit: 'cover' }}
            src={v.photo}
            alt=""
          />
        </Box>
      ))}
    </Carousel>
  );
};
