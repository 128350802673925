import { Box, Typography, useTheme } from '@mui/material';
import { ArrowRightUpIcon } from 'assets/svg-components/ArrowRightUp';
import { DepositIcon } from 'assets/svg-components/Deposit';
import { SwapIcon } from 'assets/svg-components/Swap';
import { useCallback, useMemo, useState } from 'react';
import { CoinActionType, ListType } from '../DashboardPage.types';
import { TabButton } from '../TabButton';
import { getPage } from './CoinTab.support';
import { TransferIcon } from 'assets/svg-components/Transfer';

export const CoinTab = (props: { item: ListType<string> }) => {
  const [page, setPage] = useState<CoinActionType>('Default');

  const { shape, palette } = useTheme();
  const { identifier, image, amount } = props.item;

  const title = identifier.split('.').pop()!;

  const getType = useCallback(() => {
    const type = identifier.split('.').shift()!;
    if (type === 'Gaming')
      return (
        <>
          {identifier === 'Gaming.moil' && (
            <TabButton
              sx={{ justifyContent: 'center', width: '100%' }}
              onClick={() => setPage('Transfer')}
              icon={<TransferIcon />}
              tab="Transfer"
            />
          )}
          <TabButton
            sx={{ justifyContent: 'center', width: '100%' }}
            onClick={() => setPage('Swap')}
            icon={<SwapIcon />}
            tab="Swap"
          />
        </>
      );
    if (type === 'Wallet')
      return (
        <>
          <TabButton
            sx={{ flex: 1 }}
            onClick={() => setPage('Deposit')}
            icon={<DepositIcon />}
            tab="Deposit"
          />
          <TabButton
            sx={{ flex: 1 }}
            onClick={() => setPage('Withdraw')}
            icon={<ArrowRightUpIcon />}
            tab="Withdraw"
          />
          <TabButton
            sx={{ flex: 1 }}
            onClick={() => setPage('Swap')}
            icon={<SwapIcon />}
            tab="Swap"
          />
        </>
      );
  }, [identifier]);

  const defaultPage = useMemo(
    () => (
      <>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h1">{title.toUpperCase()}</Typography>
          <Typography variant="h1" color="primary">
            {amount}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'grid',
            placeItems: 'center',
            width: '710px',
            height: '460px',
            borderRadius: `${shape.borderRadius * 1.5}px`,
            background:
              'linear-gradient(135deg, rgba(33, 231, 214, 0.08) 0%, rgba(131, 218, 255, 0.08) 100%)',
            backdropFilter: 'blur(21.5)',
          }}
        >
          {image && <img src={image} width="250px" height="250px" alt="" />}
        </Box>
        <Box
          display="flex"
          gap="10px"
          sx={{
            height: '60px',
            background: palette.background.card,
            borderRadius: `${shape.borderRadius * 2}px`,
          }}
        >
          {getType()}
        </Box>
      </>
    ),
    [amount, getType, image, palette.background.card, shape.borderRadius, title]
  );

  return (
    <Box width="100%" display="flex" flexDirection="column" rowGap="16px">
      {getPage({ defaultPage, item: props.item, page, setPage })}
    </Box>
  );
};
