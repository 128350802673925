import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import { useUserStore } from 'stores/useUserStore';
import { MarketInteractor } from '../../interactor';
import { ContainersList } from './ContainersList';

export const ContainersTab: React.FC = () => {
  const { fethMarketContainer } = MarketInteractor();
  const { marketContainer } = useUserStore();

  useEffect(() => {
    fethMarketContainer();
  }, []);

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <ContainersList cars={marketContainer} />
    </Box>
  );
};
