import { CoinActionType, ListType } from '../DashboardPage.types';
import { DepositTab } from './CoinTabs/DepositTab';
import { SwapTab } from './CoinTabs/SwapTab';
import { SwapTabWallet } from './CoinTabs/SwapTabWallet';
import { TransferTab } from './CoinTabs/TransferTab';
import { WithdrawTab } from './CoinTabs/WithdrawTab';

export const getPage = (props: {
  item: ListType<string>;
  setPage: React.Dispatch<React.SetStateAction<CoinActionType>>;
  defaultPage: JSX.Element;
  page: CoinActionType;
}) => {
  const { defaultPage, item, page, setPage } = props;

  const commonPages: Record<CoinActionType, JSX.Element | undefined> = {
    Deposit: <DepositTab {...{ item, setPage }} />,
    Withdraw: <WithdrawTab {...{ item, setPage }} />,
    Swap: <SwapTabWallet {...{ item, setPage }} />,
    Transfer: undefined,
    Default: undefined,
  };

  const gamingPagesMapping: Record<CoinActionType, JSX.Element | undefined> = {
    Transfer: <TransferTab {...{ item, setPage }} />,
    Swap: <SwapTab {...{ item, setPage }} />,
    Deposit: undefined,
    Withdraw: undefined,
    Default: undefined,
  };

  const walletIdentifiers: ListType<string>['identifier'][] = [
    'Wallet.moil',
    'Wallet.busd',
    'Wallet.bnb',
  ];
  const gamingIdentifiers: ListType<string>['identifier'][] = [
    'Gaming.moil',
    'Gaming.mgas',
  ];

  if (walletIdentifiers.includes(item.identifier)) {
    return commonPages[page] ?? defaultPage;
  } else if (gamingIdentifiers.includes(item.identifier)) {
    return gamingPagesMapping[page] ?? defaultPage;
  } else {
    return defaultPage;
  }
};
