import { Box, Grid, Typography } from '@mui/material';
import Opened from 'assets/images/containers/openedBackground.png';
import { ConsumptionIcon } from 'assets/svg-components/Consumption';
import { DiscanteIcon } from 'assets/svg-components/Distance';
import { EfficiencyIcon } from 'assets/svg-components/Efficiency';
import { FuelIcon } from 'assets/svg-components/Fuel';
import { CongratulationModal } from 'components/ui/CongratulationModal';
import React from 'react';
import { OpenContainerResponse } from 'types/models';
import { capitalizeFirstLetter } from 'utils/textUtils';
import Moovy from '../../../../../assets/images/moovy.svg';
import { labelColors } from '../../../../../constants';
import { Dot, Property } from '../styles';

interface Props {
  openModal: boolean;
  onClose: () => void;
  car: OpenContainerResponse;
}

export const OpenedCarModal = ({ openModal, onClose, car }: Props) => {
  return (
    <CongratulationModal
      open={openModal}
      onClose={onClose}
      // TODO: from backend
      rarity="epic"
      label={false}
      backgroundUrl={Opened}
    >
      <Box
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        width={'100%'}
        height={'100%'}
      >
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <img
            src={car.visual.assets.find((a) => a.name === 'garage')?.asset.url}
            width={700}
            alt=""
          />

          <Grid container spacing={2} gap={2} columns={16}>
            <Property item xs={7.5}>
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                width={'100%'}
              >
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  gap={1}
                >
                  <img src={Moovy} height={16} alt="" />
                  <Typography fontWeight={'bold'}>{car?.model.name}</Typography>
                </Box>
                <Typography fontWeight={'bold'} color={'gray'}>
                  {car?.model.brand.name}
                </Typography>
              </Box>
            </Property>
            <Property item xs={3.5}>
              <Dot sx={{ background: labelColors[car?.rarity.name!] }} />
              {capitalizeFirstLetter(car?.rarity.name!)}
            </Property>
            <Property item xs={3.5}>
              {car?.model.type.name}
            </Property>

            <Property item xs={3.55}>
              <DiscanteIcon />
              {(
                ((car.characteristics.find((c) => c.name === 'tank')?.value ||
                  1) /
                  (car.characteristics.find((c) => c.name === 'consumption')
                    ?.value || 1)) *
                100
              ).toFixed()}{' '}
              km
            </Property>
            <Property item xs={3.55}>
              <FuelIcon />
              {car?.characteristics
                .find((c) => c.name === 'tank')
                ?.value.toFixed()}
            </Property>
            <Property item xs={3.55}>
              <ConsumptionIcon />
              {car?.characteristics
                .find((c) => c.name === 'consumption')
                ?.value.toFixed()}
            </Property>
            <Property item xs={3.55}>
              <EfficiencyIcon />
              {car?.characteristics
                .find((c) => c.name === 'efficiency')
                ?.value.toFixed()}
            </Property>
          </Grid>
        </Box>
      </Box>
    </CongratulationModal>
  );
};
