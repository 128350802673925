import { Box, Button, Grid, Paper, styled } from '@mui/material';
import { fontFamily } from 'theme/fontFamily';

export const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:
    'linear-gradient(138.69deg, #141313 0%, #18191B 100%), linear-gradient(0deg, #21E7D6, #21E7D6),linear-gradient(180deg, #424D50 0%, #202526 100%)',
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  fontFamily: fontFamily,
  fontWeight: 'bold',
}));

export const Property = styled(Grid)<{ padding?: string }>(
  ({ theme, padding }) => ({
    background: '#1D1D1D',
    padding: Boolean(padding) ? padding : theme.spacing(2),
    textAlign: 'center',
    color: '#fff',
    fontSize: '14px',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 7,
    fontFamily: fontFamily,
    fontWeight: 'bold',
  })
);

export const Dot = styled(Box)(() => ({
  width: 8,
  height: 8,
  borderRadius: '50%',
}));

export const MintButton = styled(Button)(() => ({
  width: '100%',
  height: 63,
  marginTop: 20,
  fontWeight: 'bold',
  fontSize: '25px',
  display: 'grid',
  gridTemplateColumns: '1fr repeat(1, auto) 1fr',
  justifyItems: 'center',
}));

export const PriceBlock = styled(Box)(() => ({
  height: 51,
  width: 145,
  gridColumnStart: 3,
  backgroundImage: `url(${
    require('../../../../assets/images/PriceBackground.svg').default
  })`,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'cyan',
  gap: 5,
  marginLeft: 'auto',
}));

export const Label = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 7,
  background: '#1D1D1D',
  padding: '13px',
  height: 49,
  width: 129,
  borderRadius: 25,
  fontWeight: 'bold',
}));

export const ContinueButton = styled(Button)(() => ({
  position: 'absolute',
  bottom: '-70px',
  width: '60vw',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  fontWeight: 'bold',
  fontSize: '20px',
  height: 60,
}));
