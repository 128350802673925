import { useUserItemsQuery } from 'api';
import { useEffect } from 'react';
import {
  UsersItemsCurrency,
  UsersItemsExpandable,
  UsersItemsStorage,
} from 'types/models';
import { create, useStore } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface UserItemsStore {
  storage: UsersItemsStorage | null;
  expandable: UsersItemsExpandable | null;
  currency: UsersItemsCurrency | null;
  setStorage: (payload: UsersItemsStorage | null) => void;
  setExpandable: (payload: UsersItemsExpandable | null) => void;
  setCurrency: (payload: UsersItemsCurrency | null) => void;
}

export const store = create(
  persist<UserItemsStore>(
    (set, get) => ({
      storage: null,
      expandable: null,
      currency: null,
      setStorage: (payload) => {
        set({ storage: payload });
      },
      setExpandable: (payload) => {
        set({ expandable: payload });
      },
      setCurrency: (payload) => {
        set({ currency: payload });
      },
    }),
    {
      name: 'moovy-user-items',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
export const useUserItemsStore = () => {
  const {
    currency,
    expandable,
    storage,
    setCurrency,
    setExpandable,
    setStorage,
  } = useStore(store);

  const { data: currencyData, refetch: currencyRefetch } = useUserItemsQuery(
    'currency',
    { enabled: true }
  );

  const { data: storageData, refetch: storageRefetch } = useUserItemsQuery(
    'storage',
    { enabled: true }
  );

  const { data: expandableData, refetch: expandableRefetch } =
    useUserItemsQuery('expandable', { enabled: true });

  useEffect(() => {
    currencyData && setCurrency(currencyData);
    expandableData && setExpandable(expandableData);
    storageData && setStorage(storageData);
  }, [
    currencyData,
    expandableData,
    setCurrency,
    setExpandable,
    setStorage,
    storageData,
  ]);

  return {
    currency,
    storage,
    expandable,
    currencyRefetch,
    storageRefetch,
    expandableRefetch,
  };
};
