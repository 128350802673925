import { BigNumber, ContractTransaction, ethers } from 'ethers';
import { ERC_20_ABI } from '../ABIs/erc20.ABI';
import { BaseContract } from './baseContract.service';

export class Erc20Contract extends BaseContract {
  contractAddress: string;
  provider: ethers.providers.Provider | ethers.Signer;
  constructor(
    erc20Address: string,
    provider: ethers.providers.Provider | ethers.Signer
  ) {
    super(erc20Address, ERC_20_ABI, provider);
    this.contractAddress = erc20Address;
    this.provider = provider;
  }

  async balanceOf(account: string) {
    try {
      const result: BigNumber = await this.contract.balanceOf(account);
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  async allowance(owner: string, spender: string) {
    try {
      const result: BigNumber = await this.contract.allowance(owner, spender);
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  async approve(spender: string, value: BigNumber) {
    try {
      const tx = await this.contract.approve(spender, value);
      const result: boolean = await tx.wait();
      return result;
    } catch (error) {
      console.error(error);
    }
  }

  async symbol() {
    try {
      const tx: string = await this.contract.symbol();
      return tx;
    } catch (error) {
      console.error(error);
    }
  }

  async decimals() {
    try {
      const result = await this.contract.decimals();
      return result;
    } catch (error) {
      console.error(error);
    }
  }
  async transfer(to: string, amount: BigNumber) {
    try {
      const result: ContractTransaction = await this.contract.transfer(
        to,
        amount
      );
      return result;
    } catch (error) {
      console.error(error);
    }
  }
}
