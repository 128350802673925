import { ethers } from 'ethers';
import { useWeb3 } from 'hooks';
import { useCallback, useState } from 'react';
import { create, useStore } from 'zustand';
import { Erc20Contract } from '../evm/contracts/erc20Contract.service';
import { useUserStore } from './useUserStore';
import { ConfigTokenType } from 'config';

interface WithdrawStore {
  receiver: string;
  setReceiver: (value: string) => void;
  amount: string;
  setAmount: (value: string) => void;
}

const store = create<WithdrawStore>((set) => ({
  receiver: '',
  amount: '',
  setAmount: (value: string) => {
    if (value.includes(',')) {
      value = value.replace(',', '.');
    }
    set(() => ({ amount: value }));
  },
  setReceiver: (value: string) => set(() => ({ receiver: value })),
}));

export const useWithdraw = () => {
  const [loading, setLoading] = useState(false);
  const { receiver, setReceiver, amount, setAmount } = useStore(store);
  const address = useUserStore((s) => s.info?.wallet?.address);
  const privKey = useUserStore((s) => s.info?.privateKey);

  const { provider } = useWeb3();

  function reset() {
    setAmount('');
    setReceiver('');
  }

  async function withdraw(token: ConfigTokenType | null) {
    try {
      if (!token?.address) {
        return await withdrawNative(token);
      } else {
        return await withdrawErc20(token);
      }
    } catch (error: any) {
      console.error(error);
      throw Error("Can't create transaction");
    }
  }

  const withdrawErc20 = useCallback(
    async (token: ConfigTokenType | null) => {
      if (!token?.address) {
        throw Error('No address provided');
      }
      if (!provider) {
        throw Error("Can't retrieve network");
      }

      const signer = new ethers.Wallet(privKey!, provider);
      const instance = new Erc20Contract(token.address, signer);

      setLoading(true);

      const tx = await instance.transfer(
        receiver,
        ethers.utils.parseUnits(amount, token.decimals)
      );

      const res = await tx?.wait();
      return res;
    },
    [provider, privKey, amount, receiver]
  );

  const withdrawNative = useCallback(
    async (token: ConfigTokenType | null) => {
      if (!token) {
        throw Error('No token provided');
      }
      if (!address) {
        throw new Error('No address');
      }
      if (!provider) {
        throw Error("Can't retrieve network");
      }
      const withdrawedAmount = ethers.utils.parseEther(amount);
      const signer = new ethers.Wallet(privKey!, provider);

      setLoading(true);
      const params = {
        from: address,
        to: receiver,
        value: withdrawedAmount,
      };

      const tx = await signer.sendTransaction(params);

      return await tx.wait();
    },
    [address, provider, amount, privKey, receiver]
  );

  return {
    receiver,
    setReceiver,
    withdraw,
    reset,
    amount,
    loading,
    setLoading,
    setAmount,
  };
};
