import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useUserQuery } from 'api';
import moilPhoto from 'assets/images/DashboardTabs/moil.png';
import { CarIcon } from 'assets/svg-components/Car';
import React, { useEffect, useState } from 'react';
//@ts-ignore
import video from '../../../../../assets/videos/MoovyContainerOpenSite.mp4';

import { Close } from '@mui/icons-material';
import { useCarsCrossbreedingMutation } from 'api/mutations/carsCrossbreedingMutation';
import { CongratulationModal } from 'components/ui/CongratulationModal';
import { containers } from 'constants/containerImages';
import { useUserItemsStore } from 'stores/useUserItemsStore';
import { Car } from 'types/models';
import { MAX_BREED_COUNT, moil } from '../../../../../constants';
import { MintButton, PriceBlock } from '../styles';
import { CarsList } from './CarsList';

export const CarsTab: React.FC = () => {
  const [containerRarity, setContainerRarity] = useState('common');
  const { data: cars, refetch } = useUserQuery('cars', { enabled: true });
  const [bridPrice, setBridPrice] = useState(0);
  const [isSelectEnabled, setIsSelectEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCars, setSelectedCars] = useState<Array<number>>([]);
  const { mutateAsync: getBreedPreview } = useCarsCrossbreedingMutation(
    'crossBreedingPreview'
  );
  const { storageRefetch, currency, currencyRefetch } = useUserItemsStore();
  const { mutateAsync: breed } = useCarsCrossbreedingMutation('crossBreeding');

  const onItemClick = (car: Car) => {
    if (!isSelectEnabled || car.breeds_count === MAX_BREED_COUNT) return;

    if (selectedCars.length < 2 && !selectedCars.includes(car.id)) {
      setSelectedCars((prev) => [...prev, car.id]);
    } else if (selectedCars.includes(car.id)) {
      setSelectedCars((prev) => prev.filter((carId) => carId !== car.id));
      setBridPrice(0);
    } else if (!selectedCars.includes(car.id)) {
      setSelectedCars([]);
      setBridPrice(0);
    }
  };

  const moilItem = currency?.data.find(({ item }) => {
    return item.name === moil;
  });

  const cancel = () => {
    setSelectedCars([]);
    setIsSelectEnabled(false);
  };

  const mint = async () => {
    setLoading(true);
    try {
      const container = await breed({
        car1Id: selectedCars[0],
        car2Id: selectedCars[1],
      });

      setContainerRarity(container.data.rarity.name);
      cancel();
      setOpenModal(true);
      storageRefetch();
      refetch();
      currencyRefetch();
    } catch (error) {
      console.log(error);
      cancel();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCars.length === 2) {
      getBreedPreview({
        car1Id: selectedCars[0],
        car2Id: selectedCars[1],
      })
        .then((res) => {
          setBridPrice(res.bridePrice);
        })
        .catch((err) => {
          console.log('[breed price error] : ', err);
        });
    }
  }, [selectedCars]);

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <Box display="flex" columnGap="16px" mb={2} alignItems={'center'}>
        <Typography variant="h1">CARS</Typography>
        <Typography marginX={'10px'} color={'cyan'} variant="h1">
          {cars?.data.length || 0}
        </Typography>
        {!isSelectEnabled ? (
          <Button
            variant="contained"
            sx={{ alignItems: 'center', marginLeft: 'auto' }}
            onClick={() => setIsSelectEnabled(true)}
          >
            <Typography marginX={'22px'} variant="h4">
              Mint
            </Typography>
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              sx={{ alignItems: 'center', width: '100%' }}
              onClick={cancel}
            >
              <Typography marginX={'22px'} variant="h4">
                Choose two cars to be minted
              </Typography>
              <Close sx={{ marginLeft: 'auto' }} />
            </Button>
          </>
        )}
      </Box>

      <CarsList
        cars={cars}
        isSelectEnabled={isSelectEnabled}
        onItemClick={onItemClick}
        selectedCars={selectedCars}
      />

      {isSelectEnabled && (
        <MintButton
          onClick={mint}
          disabled={
            selectedCars.length < 2 ||
            (moilItem?.amount || 0) < bridPrice ||
            bridPrice === 0
          }
          variant="contained"
        >
          {loading ? (
            <CircularProgress sx={{ gridColumnStart: 2 }} color="secondary" />
          ) : (
            <>
              <Box sx={{ gridColumnStart: 2 }}>
                <CarIcon width={28} height={18} fill="black" />
                <span style={{ marginLeft: '10px' }}>MINT</span>
              </Box>
              {selectedCars.length === 2 && !!bridPrice && (
                <PriceBlock>
                  <img src={moilPhoto} width={27} height={27} alt="" />
                  <Typography variant="h3">{bridPrice}</Typography>
                </PriceBlock>
              )}
            </>
          )}
        </MintButton>
      )}
      {openModal && (
        <CongratulationModal
          open={openModal}
          onClose={() => {
            setContainerRarity('common');
            setOpenModal(false);
          }}
          rarity={containerRarity}
          backgroundUrl={containers[containerRarity]}
          videoTimeout={11500}
          videoUrl={video}
        />
      )}
    </Box>
  );
};
