import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import { handleAxiosError, useUserMutation } from 'api';
import { Input } from 'components/ui';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUserStore } from 'stores/useUserStore';
import { buttonSx } from 'theme/sxProps';
import { Reset } from 'types/models';
import * as yup from 'yup';

export const EmailForm = (props: {
  setSendSuccess: (key: boolean) => void;
}) => {
  const email = useUserStore((s) => s.info?.email);
  const setInfo = useUserStore((s) => s.setInfo);

  const { setSendSuccess } = props;
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Reset>({
    defaultValues: { email },
    mode: 'all',
    resolver: yupResolver(
      yup.object().shape({
        email: yup
          .string()
          .email('Email should have correct format')
          .required('Email is a required field'),
      })
    ),
  });

  const {
    mutate,
    isSuccess,
    isError,
    error: mutateError,
  } = useUserMutation('reset');

  const onSubmit: SubmitHandler<Reset> = useCallback(
    ({ email }) => {
      setInfo({ email });
      mutate({ email });
    },
    [mutate, setInfo]
  );

  useEffect(() => {
    if (isSuccess) {
      setSendSuccess(isSuccess);
    }
  }, [isSuccess, setSendSuccess]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={2} marginBottom={5}>
        <Input
          type="email"
          text="E-mail"
          placeholder="email@gmail.com"
          fullWidth
          required
          message={errors.email?.message || handleAxiosError(mutateError)}
          error={!!errors.email || isError}
          {...register('email')}
        />
        <LoadingButton
          fullWidth
          type="submit"
          sx={buttonSx({ mb: '16px' })}
          disabled={!isValid}
        >
          <Typography variant="h5" fontWeight="700">
            Send
          </Typography>
        </LoadingButton>
      </Stack>
    </Box>
  );
};
