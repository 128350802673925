import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authContainerSx } from 'theme/sxProps';
import { BackButton } from '../AuthPage.support';
import { AuthType } from '../AuthPage.types';
import { EmailForm } from './EmailForm';
import { ResetForm } from './ResetForm';
import { VerificatePincodeForm } from './ValidatePincodeForm';

export const ResetDrawer = (props: {
  setActive: (payload: AuthType) => void;
  active: AuthType;
}) => {
  const { setActive } = props;
  const [pincode, setPincode] = useState('');
  const [sendSuccess, setSendSuccess] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [verificateSuccess, setVerificateSuccess] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (resetSuccess) {
      setTimeout(() => setActive('login'), 1500);
    }
  }, [navigate, resetSuccess, setActive]);

  return (
    <Box sx={authContainerSx()}>
      <Typography variant="h1">Forgot password?</Typography>
      <Typography variant="h5" color="secondary" mt="15px">
        {!sendSuccess &&
          'Please provide your email address to reset the password'}
        {sendSuccess && 'Please provide your pincode to reset the password'}
      </Typography>
      <BackButton {...{ setActive: () => setActive('login') }} />
      <Box mt="30px">
        {!sendSuccess && <EmailForm {...{ setSendSuccess }} />}
        {sendSuccess && !verificateSuccess && (
          <VerificatePincodeForm {...{ setVerificateSuccess, setPincode }} />
        )}
        {sendSuccess && verificateSuccess && !resetSuccess && (
          <ResetForm {...{ setResetSuccess, pincode }} />
        )}
        {resetSuccess && (
          <Typography color="primary">
            Password changed successfully!
          </Typography>
        )}
      </Box>
    </Box>
  );
};
