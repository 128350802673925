export const ConsumptionIcon = () => {
  return (
    <svg
      width="14"
      height="20"
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.4766 9.4673C12.7488 7.1806 11.3668 5.0325 10.0584 3.3694C8.472 1.3339 7 0 7 0C7 0 5.528 1.3252 3.9416 3.3694C2.6332 5.0325 1.2512 7.1806 0.523399 9.4673C0.196299 10.4894 0 11.5461 0 12.5942C0 16.6825 3.132 20 7 20C10.868 20 14 16.6825 14 12.5942C14 11.5461 13.8037 10.4894 13.4766 9.4673ZM5.8715 9.4067V6.2105H8.1285V9.4067H9.1916L7 12.8714L4.8084 9.4067H5.8715ZM7 18.311C4.2033 18.311 1.9054 16.0762 1.6028 13.2005C2.764 15.0541 4.7512 16.2668 7 16.2668C9.2488 16.2668 11.236 15.0541 12.3972 13.2005C12.0946 16.0762 9.7967 18.311 7 18.311Z"
        fill="#939393"
      />
    </svg>
  );
};
