import { Box, Drawer } from '@mui/material';
import { Logo, MoovySnackbar } from 'components/ui';
import { Fragment, useState } from 'react';
import { AuthType } from './AuthPage.types';
import { CarsCarousel } from './CarsCarousel';
import { LoginDrawer } from './LoginDrawer/LoginDrawer';
import { SignUpDrawer } from './SignUpDrawer/SignUpDrawer';
import { ResetDrawer } from './ResetDrawer/ResetDrawer';

export const AuthPage = () => {
  const [active, setActive] = useState<AuthType>('login');

  const getAuth = (type: AuthType) => {
    if (type === 'signup') return <SignUpDrawer {...{ active, setActive }} />;
    if (type === 'login') return <LoginDrawer {...{ active, setActive }} />;
    if (type === 'reset') return <ResetDrawer {...{ active, setActive }} />;
  };

  return (
    <Fragment>
      <Box
        width="100vw"
        height="100vh"
        sx={{
          background: ({ palette }) => palette.gradient.black,
          position: 'relative',
        }}
      >
        <CarsCarousel />
      </Box>
      <Drawer variant="permanent" open>
        <Logo />
        {getAuth(active)}
      </Drawer>
      <MoovySnackbar />
    </Fragment>
  );
};
