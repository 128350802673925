import { Grid } from '@mui/material';
import { GridList } from 'components/ui/GridList';
import { ItemType } from 'types/models';
import { ContainerItem } from './ContainerItem';

export const ContainersList = ({
  isSelectEnabled,
  containers,
  onItemClick,
  selectedContainers,
}: {
  isSelectEnabled: boolean;
  containers: ItemType[] | undefined;
  selectedContainers: number[];
  onItemClick: (id: number) => void;
}) => {
  return (
    <GridList height={isSelectEnabled ? 422 : 502}>
      <Grid container spacing={2} columns={16}>
        {containers?.map((container) => (
          <ContainerItem
            container={container}
            key={container.id}
            selectedContainers={selectedContainers}
            onClick={onItemClick}
            isSelectEnabled={isSelectEnabled}
          />
        ))}
      </Grid>
    </GridList>
  );
};
