import { Box, ButtonBase, Typography, useTheme } from '@mui/material';
import stakingPhoto from 'assets/images/DashboardTabs/staking.png';
import { GalleryIcon } from 'assets/svg-components/Gallery';
import { Fragment } from 'react';
import { buttonSx } from 'theme/sxProps';

export const Staking = () => {
  const { palette, shape } = useTheme();

  return (
    <Fragment>
      <Box
        width="100%"
        height="430px"
        display="grid"
        sx={{
          p: '12px',
          placeItems: 'center',
          borderRadius: `${shape.borderRadius}px`,
          background:
            'linear-gradient(98.27deg, rgba(33, 231, 214, 0.08) 0%, rgba(131, 218, 255, 0.08) 105.42%)',
        }}
      >
        <img
          height="100%"
          width="100%"
          src={stakingPhoto}
          style={{ objectFit: 'fill' }}
          alt="staking"
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="end"
        height="80px"
        sx={{
          p: '20px',
          background: palette.primary.dark,
          borderRadius: `${shape.borderRadius}px`,
        }}
      >
        <ButtonBase
          onClick={(e) => {}}
          sx={buttonSx({
            mr: '16px',
            height: '44px',
            width: '210px',
            borderRadius: `${shape.borderRadius}px`,
          })}
        >
          <GalleryIcon fill="black" />
          <Typography ml={0.5} variant="h5" fontWeight={500}>
            Buy MOIL
          </Typography>
        </ButtonBase>
        <ButtonBase
          onClick={(e) => {}}
          sx={buttonSx({
            height: '44px',
            width: '210px',
            borderRadius: `${shape.borderRadius}px`,
          })}
        >
          <GalleryIcon fill="black" />
          <Typography ml={0.5} variant="h5" fontWeight={500}>
            Go to staking
          </Typography>
        </ButtonBase>
      </Box>
    </Fragment>
  );
};
