import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { AuthType } from './AuthPage.types';
import { ArrowBackRounded } from '@mui/icons-material';
import { SystemStyleObject } from '@mui/system';

export const AuthTitle = (props: {
  path: AuthType;
  title: string;
  ml?: number;
  setActive: (payload: AuthType) => void;
  active: AuthType;
}) => {
  const { active, ml = 0, path, setActive, title } = props;
  const { palette } = useTheme();

  return (
    <Typography
      variant="h4"
      color="secondary"
      ml={`${ml}px`}
      mb={4}
      sx={{
        borderBottom:
          active === path ? `2px solid ${palette.primary.main}` : 'none',
        color: active === path ? palette.primary.main : palette.text.main,
        cursor: 'pointer',
        '&:hover': { color: palette.primary.main },
      }}
    >
      <span onClick={() => setActive(path)}>{title}</span>
    </Typography>
  );
};

export const BackButton = (props: {
  sx?: SystemStyleObject;
  handleBack?: (key: boolean) => void;
  setActive?: () => void;
}) => {
  const { handleBack, setActive, sx } = props;
  const { shape, palette } = useTheme();

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 1,
        top: 124,
        left: 0,
        width: 70,
        height: 70,
        background: palette.primary.dark,

        // background: 'rgba(129, 138, 137, 0.1)',
        display: 'grid',
        placeItems: 'center',
        borderRadius: `${shape.borderRadius}px`,
        ...sx,
      }}
    >
      <IconButton
        onClick={() => {
          handleBack && handleBack(false);
          setActive && setActive();
        }}
        size="large"
      >
        <ArrowBackRounded color="primary" fontSize="small" />
      </IconButton>
    </Box>
  );
};
