import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useUserStore } from 'stores/useUserStore';
import { MarketInteractor } from '../../interactor';
import { CarsList } from './CarsList';

export const CarsTab: React.FC = () => {
  const { marketCars } = useUserStore();
  const { fethMarketCars } = MarketInteractor();
  useEffect(() => {
    fethMarketCars();
  }, []);

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      {marketCars && <CarsList cars={marketCars} />}
    </Box>
  );
};
