import { Wallet } from 'ethers';
import { MODAL_TYPE, UsersCarsSale } from 'types/models';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

type UserInfoType = {
  email: string;
  privateKey: string;
  bbid: string;
  wallet: Wallet;
} | null;

interface UserStore {
  info: Partial<UserInfoType> | null;
  setInfo: (payload: Partial<UserInfoType> | null) => void;
  isOpenModal: {
    isOpen: boolean;
    type: MODAL_TYPE | undefined;
    handleClick?: (v?: number) => void;
  };
  setIsOpenModal: (isOpenModal: {
    isOpen: boolean;
    type?: MODAL_TYPE;
    handleClick?: (v?: number) => void;
  }) => void;
  marketCars: UsersCarsSale | undefined;
  marketContainer: UsersCarsSale | undefined;
  setMarketCarsState: (payload: UsersCarsSale) => void;
  setMarketContainer: (payload: UsersCarsSale) => void;
}

export const useUserStore = create(
  persist<UserStore>(
    (set, get) => ({
      info: null,
      marketCars: undefined,
      marketContainer: undefined,
      isOpenModal: { isOpen: false, type: MODAL_TYPE.AddToMarket },
      setInfo: async (info) => {
        set((state) => ({ info: { ...state.info, ...info } }));
      },
      setIsOpenModal: async (info) => {
        set(() => ({
          isOpenModal: {
            isOpen: info.isOpen,
            type: info.type,
            handleClick: info.handleClick,
          },
        }));
      },
      setMarketCarsState: async (info) => {
        set(() => ({ marketCars: info }));
      },
      setMarketContainer: async (info) => {
        set(() => ({ marketContainer: info }));
      },
    }),
    {
      name: 'moovy-user',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
