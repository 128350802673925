import React from 'react';

export const MoovyDash = (props: {
  fill?: string;
  height?: number;
  width?: number;
}) => {
  const width = 36;
  const height = 20;
  return (
    <svg
      width={props?.width ?? width}
      height={props?.height ?? height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.5702 11.6953C19.333 13.0998 18.7982 14.4364 18.0023 15.614C17.0927 16.9665 15.8677 18.0737 14.4347 18.8383C13.0017 19.603 11.4044 20.0018 9.78279 19.9999C7.44198 20.0001 5.17642 19.1668 3.38633 17.6472C1.59624 16.1276 0.396839 14.0195 0 11.6953H3.49872C3.87064 13.0883 4.68775 14.319 5.82367 15.1971C6.95959 16.0752 8.35103 16.5518 9.78279 16.5531C11.2145 16.5518 12.606 16.0752 13.7419 15.1971C14.8778 14.319 15.6949 13.0883 16.0668 11.6953H19.5702Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M35.9248 7.90929H32.3749C31.9392 6.60965 31.1102 5.48024 30.0045 4.67981C28.8988 3.87939 27.5719 3.4482 26.2103 3.44684C24.8487 3.4482 23.5218 3.87939 22.4161 4.67981C21.3103 5.48024 20.4813 6.60965 20.0456 7.90929H16.502C16.7674 6.64809 17.2747 5.45124 17.9954 4.38589C18.905 3.03336 20.13 1.92626 21.563 1.16161C22.996 0.396956 24.5934 -0.00189712 26.2149 6.78424e-06C28.4876 -0.000860902 30.6917 0.784061 32.459 2.22362C34.2263 3.66319 35.4497 5.67019 35.9248 7.90929Z"
        fill="black"
        fillOpacity="0.5"
      />
      <path
        d="M19.5045 7.90929H15.9531C15.5174 6.60965 14.6884 5.48024 13.5826 4.67981C12.4769 3.87939 11.15 3.4482 9.78842 3.44684C8.42686 3.4482 7.09995 3.87939 5.9942 4.67981C4.88846 5.48024 4.05947 6.60965 3.62377 7.90929H0.0800781C0.555148 5.67046 1.77834 3.66366 3.54531 2.22412C5.31229 0.784586 7.51603 -0.000503101 9.78842 6.78424e-06C11.41 -0.00189712 13.0073 0.396956 14.4403 1.16161C15.8734 1.92626 17.0983 3.03336 18.0079 4.38589C18.7297 5.45099 19.2381 6.64786 19.5045 7.90929Z"
        fill="black"
      />
      <path
        d="M35.9999 11.6953C35.6031 14.0195 34.4037 16.1276 32.6136 17.6472C30.8235 19.1668 28.5579 20.0001 26.2171 19.9999C24.5955 20.0018 22.9982 19.603 21.5652 18.8383C20.1322 18.0737 18.9072 16.9665 17.9976 15.614C17.2017 14.4364 16.6669 13.0998 16.4297 11.6953H19.9284C20.3003 13.0883 21.1174 14.319 22.2534 15.1971C23.3893 16.0752 24.7807 16.5518 26.2125 16.5531C27.6442 16.5518 29.0357 16.0752 30.1716 15.1971C31.3075 14.319 32.1246 13.0883 32.4965 11.6953H35.9999Z"
        fill="black"
      />
    </svg>
  );
};
