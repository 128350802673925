import baseAxios, {
  AxiosError,
  AxiosInstance,
  CreateAxiosDefaults,
} from 'axios';
import { config } from 'config';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routes';
import { localStorageUtil } from 'utils/localStorageUtils';

const headers: CreateAxiosDefaults<any>['headers'] = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

let instance: AxiosInstance = baseAxios.create({
  baseURL: config.api,
  timeout: 15000,
  headers,
});

export function api() {
  const access_token = localStorageUtil.getItem({ key: 'access_token' });

  instance.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
  return instance;
}

export const setAuthToken = (args: { access_token: string | null }) => {
  instance.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${args.access_token}`;
};

export const handleAxiosError = (error: unknown) => {
  if (error instanceof AxiosError && error.response) {
    const errorMessage = error.response.data.message;
    return errorMessage;
  } else return null;
};

export function useAxios() {
  const navigate = useNavigate();

  useEffect(() => {
    instance.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.response && error.response.status === 401) {
          localStorageUtil.removeItem({ key: 'access_token' });
          setAuthToken({ access_token: null });
          navigate(routes.auth.path);
        }
        return Promise.reject(error);
      }
    );

    return () => {
      instance.interceptors.response.clear();
    };
  }, [navigate]);

  return { setAuthToken, api };
}
