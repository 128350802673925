import { QueryFunction, useQuery } from '@tanstack/react-query';
import { QueryOptionsType, QueryTagType } from 'api/types';
import { UsersAuthorized, UsersCars, UsersGarage } from 'types/models';
import { useAxios } from '../axios';

// Define a mapping from keys to types
type QueryKeyToType = {
  marketCars: any;
  marketItems: any;
};

type QueryKey = keyof QueryKeyToType;

export const useMarketQuery = <K extends QueryKey>(
  key: K,
  options?: QueryOptionsType<QueryKeyToType[K]>,
  tags: string[] = []
) => {
  const { api } = useAxios();
  const baseUrl = '/market';

  const marketCars = async () => {
    const { data } = await api().get<QueryKeyToType[K]['response']>(
      baseUrl + '/cars'
    );
    return data;
  };

  const marketItems = async () => {
    const { data } = await api().get<QueryKeyToType[K]['response']>(
      baseUrl + '/items'
    );
    return data;
  };

  const queries = {
    marketCars,
    marketItems,
  };

  if (!queries.hasOwnProperty(key)) {
    throw new Error(`Invalid query key: ${key}`);
  }
  return useQuery<QueryKeyToType[K], unknown, QueryKeyToType[K], QueryTagType>(
    [key as any, ...tags],
    queries[key] as unknown as QueryFunction<QueryKeyToType[K], QueryTagType>,
    options
  );
};
