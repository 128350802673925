import React from 'react';

export const QuestionMarkIcon = (props: {
  fill?: string;
  height?: number;
  width?: number;
}) => {
  const width = 18;
  const height = 18;
  const defaultColor = '#21E7D6';

  return (
    <svg
      width={props?.width ?? width}
      height={props?.height ?? height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.343699 6.02975C-0.114567 7.9834 -0.114566 10.0166 0.343699 11.9703C1.00549 14.7916 3.20841 16.9945 6.02975 17.6563C7.9834 18.1146 10.0166 18.1146 11.9703 17.6563C14.7916 16.9945 16.9945 14.7916 17.6563 11.9702C18.1146 10.0166 18.1146 7.9834 17.6563 6.02975C16.9945 3.20841 14.7916 1.00549 11.9703 0.343698C10.0166 -0.114567 7.9834 -0.114565 6.02975 0.343698C3.20842 1.00549 1.00549 3.20841 0.343699 6.02975ZM9.76579 12.207C9.76579 12.6035 9.44435 12.9249 9.04783 12.9249C8.65131 12.9249 8.32986 12.6035 8.32986 12.207C8.32986 11.8105 8.65131 11.489 9.04783 11.489C9.44435 11.489 9.76579 11.8105 9.76579 12.207ZM7.65977 6.98975C7.65977 6.24958 8.2598 5.64955 8.99997 5.64955C9.74015 5.64955 10.3402 6.24958 10.3402 6.98975V7.10589C10.3402 7.49214 10.1867 7.86256 9.91362 8.13568L8.59383 9.45547C8.36953 9.67978 8.36953 10.0434 8.59383 10.2678C8.81814 10.4921 9.18181 10.4921 9.40612 10.2678L10.7259 8.94796C11.2145 8.45942 11.4889 7.7968 11.4889 7.10589V6.98975C11.4889 5.61515 10.3746 4.50081 8.99997 4.50081C7.62537 4.50081 6.51103 5.61515 6.51103 6.98975V7.4684C6.51103 7.78561 6.76819 8.04277 7.0854 8.04277C7.40262 8.04277 7.65977 7.78561 7.65977 7.4684V6.98975Z"
        fill={props?.fill ?? defaultColor}
      />
    </svg>
  );
};
