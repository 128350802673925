import {
  CHAIN_NAMESPACES,
  SafeEventEmitterProvider,
  UserInfo,
  WALLET_ADAPTERS,
} from '@web3auth/base';
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider';
import { Web3AuthNoModal } from '@web3auth/no-modal';
import { OpenloginAdapter } from '@web3auth/openlogin-adapter';
import { config } from 'config';
import { ethers } from 'ethers';
import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useUserStore } from 'stores/useUserStore';
import RPC from './ethersRPC';
import { localStorageUtil } from 'utils/localStorageUtils';

type ProviderType = 'google' | 'apple' | 'discord';

interface IContext {
  web3auth: Web3AuthNoModal | null;
  provider: ethers.providers.JsonRpcProvider;
  loading: boolean;
  loggedWithWeb3: boolean;
  initLoading: boolean;
  loginWithProvider: (loginProvider: ProviderType) => Promise<void>;
  loginWithEmail: (email: string) => Promise<void>;
  logout: () => Promise<void>;
  getUserInfo: () => Promise<Partial<UserInfo> | undefined>;
  getWallet: (provider: SafeEventEmitterProvider) => Promise<
    | {
        wallet: ethers.Wallet;
        privateKey: string;
      }
    | null
    | undefined
  >;
}

let clientId = process.env.REACT_APP_ETHEREUM_CLIENT_ID!;

export const Web3Context = createContext<IContext>({} as IContext);

export const Web3Provider: FC<PropsWithChildren> = ({ children }) => {
  const ethersProvider = useMemo(
    () =>
      new ethers.providers.JsonRpcProvider(config.rpcTarget, config.chainId),
    []
  );
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [web3auth, setWeb3auth] = useState<Web3AuthNoModal | null>(null);

  const { setInfo } = useUserStore();
  const privateKey = useUserStore((s) => s.info?.privateKey);

  const init = useCallback(async () => {
    setInitLoading(true);
    try {
      const chainConfig = {
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: '0x1',
        rpcTarget: 'https://rpc.ankr.com/eth',
        displayName: 'Ethereum Mainnet',
        blockExplorer: 'https://goerli.etherscan.io/',
        ticker: 'ETH',
        tickerName: 'Ethereum',
      };
      const privateKeyProvider = new EthereumPrivateKeyProvider({
        config: { chainConfig },
      });
      const web3auth = new Web3AuthNoModal({
        clientId,
        chainConfig,
        web3AuthNetwork: 'cyan',
      });
      setWeb3auth(web3auth);

      const openloginAdapter = new OpenloginAdapter({
        //@ts-ignore
        privateKeyProvider,
      });
      web3auth.configureAdapter(openloginAdapter);

      await web3auth.init();
    } catch (e) {
      console.error(e);
    } finally {
      setInitLoading(false);
    }
  }, []);

  const getWallet = useCallback(
    async (provider: SafeEventEmitterProvider) => {
      try {
        if (!provider) {
          return null;
        }
        const rpc = new RPC(provider);

        const privateKey = await rpc.getPrivateKey();
        const wallet = new ethers.Wallet(privateKey);
        setInfo({ wallet, privateKey });
        return { wallet, privateKey };
      } catch (e) {
        console.error(e);
      }
    },
    [setInfo]
  );

  const getUserInfo = useCallback(async () => {
    try {
      if (!web3auth) {
        console.error('web3auth not initialized yet');
        return;
      }
      const user = await web3auth.getUserInfo();
      if (user && user.email) {
        setInfo({ email: user.email });
      } else {
        console.error('No user data found');
      }
      return user;
    } catch (e) {
      console.error(e);
    }
  }, [setInfo, web3auth]);

  const loginWithProvider = useCallback(
    async (loginProvider: ProviderType) => {
      setLoading(true);
      try {
        if (!web3auth) {
          console.error('web3auth not initialized yet');
          return;
        }

        const web3authProvider = await web3auth.connectTo(
          WALLET_ADAPTERS.OPENLOGIN,
          {
            loginProvider,
          }
        );
        if (web3authProvider) {
          getWallet(web3authProvider);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3auth]
  );

  const loginWithEmail = useCallback(
    async (email: string) => {
      setLoading(true);
      try {
        if (!web3auth) {
          console.error('web3auth not initialized yet');
          return;
        }

        const web3authProvider = await web3auth.connectTo(
          WALLET_ADAPTERS.OPENLOGIN,
          {
            loginProvider: 'email_passwordless',
            extraLoginOptions: {
              login_hint: email,
            },
          }
        );
        if (web3authProvider) {
          getWallet(web3authProvider);
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [web3auth]
  );

  const logout = useCallback(async () => {
    try {
      if (!web3auth) {
        console.error('web3auth not initialized yet');
        return;
      }
      setInfo({ privateKey: undefined, email: undefined });
      await web3auth.logout();
    } catch (e) {
      console.error(e);
    }
  }, [setInfo, web3auth]);

  useEffect(() => {
    init();
  }, [init]);

  useEffect(() => {
    const openlogin_store = JSON.parse(
      localStorageUtil.getItem({ key: 'openlogin_store' })
    );

    if (privateKey && openlogin_store?.email) {
      setInfo({ email: openlogin_store?.email });
    }
  }, [ethersProvider, getUserInfo, privateKey, setInfo, web3auth]);

  const value: IContext = useMemo(
    () => ({
      web3auth,
      provider: ethersProvider,
      loading,
      loggedWithWeb3: Boolean(privateKey),
      logout,
      loginWithEmail,
      loginWithProvider,
      getUserInfo,
      getWallet,
      initLoading,
    }),
    [
      web3auth,
      ethersProvider,
      loading,
      privateKey,
      logout,
      loginWithEmail,
      loginWithProvider,
      getUserInfo,
      getWallet,
      initLoading,
    ]
  );

  return <Web3Context.Provider value={value}>{children}</Web3Context.Provider>;
};
