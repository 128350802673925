import {
  Box,
  Card,
  Container,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import { useUserQuery } from 'api/queries/userQuery';
import { Header } from 'components/layout';
import ModalConfirmation from 'components/Modal';
import { container, numberplate } from 'constants/index';
import { Fragment, useState } from 'react';
import { useUserItemsStore } from 'stores/useUserItemsStore';
import { useUserStore } from 'stores/useUserStore';
import { containerSx } from 'theme/sxProps';
import {
  useTabButton,
  useTabDictionary,
  useTabInfo,
} from './DashboardPage.hooks';
import { MainTabType, TabType } from './DashboardPage.types';

export const DashboardPage = () => {
  const { palette, shape } = useTheme();
  const [selectedTab, setSelectedTab] = useState<MainTabType>('Main');
  const [selectedTabInfo, setSelectedTabInfo] = useState<TabType>('Wallet');
  const {isOpenModal, setIsOpenModal} = useUserStore();
  const onClose = () => {
    setIsOpenModal({isOpen: false});
  }
  const { data: authorized } = useUserQuery('authorized', { enabled: true });

  const { data: cars } = useUserQuery('cars', { enabled: true });

  const { storage } = useUserItemsStore();

  const numberplates = storage?.data.filter(({ item }) => {
    return item.name === numberplate;
  }).length;

  const containers = storage?.data.filter(({ item }) => {
    return item.name === container;
  }).length;

  const info = useUserStore((s) => s.info);
  const result = authorized?.data;

  return (
    <Fragment>
      <Box width="100%" sx={{ background: palette.gradient.black }}>
        <Header />
        <Container maxWidth="lg" sx={containerSx({ rowGap: '16px' })}>
          <Typography variant="h1" fontSize={40}>
            DASHBOARD
          </Typography>
          <Box display="flex" columnGap="70px" width="100%">
            <Box
              display="flex"
              rowGap="10px"
              minWidth="450px"
              flexDirection="column"
            >
              <Card
                sx={{
                  display: 'flex',
                  columnGap: '15px',
                  background: palette.background.card,
                  width: '100%',
                  height: '150px',
                  p: '10px',
                }}
              >
                <Box
                  width="98px"
                  height="100%"
                  borderRadius={`${shape.borderRadius}px`}
                  overflow="hidden"
                  sx={{ background: palette.primary.dark }}
                >
                  {result?.face?.asset.url ? (
                    <img
                      loading="lazy"
                      width="100%"
                      height="100%"
                      style={{ objectFit: 'cover' }}
                      src={result?.face?.asset.url}
                      alt="face"
                    />
                  ) : (
                    <Typography
                      color="secondary"
                      variant="h3"
                      sx={{
                        display: 'grid',
                        placeItems: 'center',
                        width: '100%',
                        height: '100%',
                      }}
                    >
                      ---
                    </Typography>
                  )}
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box
                    display="flex"
                    alignItems="end"
                    sx={{ color: palette.secondary.main }}
                  >
                    <Typography mr={0.75} sx={{ color: palette.text.disabled }}>
                      {'BBID'}
                    </Typography>
                    <Typography variant="h5" color={palette.text.main}>
                      {info?.bbid}
                    </Typography>
                  </Box>
                  <Typography mt="2px" variant="h3" color={palette.text.main}>
                    {result?.name ?? (
                      <Typography color="secondary">---</Typography>
                    )}
                  </Typography>
                  <Typography mt="10px" variant="h6" color="secondary">
                    Type
                  </Typography>
                  <Typography mt="2px" variant="h4" color="primary">
                    {result?.avatar ?? (
                      <Typography color="secondary">---</Typography>
                    )}
                  </Typography>
                </Box>
              </Card>
              <Grid
                container
                sx={{
                  width: '100%',
                  height: '60px',
                  background: palette.background.card,
                  borderRadius: `${shape.borderRadius * 2}px`,
                }}
              >
                {useTabButton({
                  tab: 'Gaming',
                  selectedTabInfo,
                  setSelectedTabInfo,
                })}
                {useTabButton({
                  tab: 'Wallet',
                  selectedTabInfo,
                  setSelectedTabInfo,
                })}
              </Grid>
              {useTabInfo({
                cars: cars?.data.length,
                containers,
                numberplates,
                selectedTab,
                setSelectedTab,
                tab: selectedTabInfo,
              })}
            </Box>
            {useTabDictionary({ selectedTab, setSelectedTab })}
          </Box>
          <ModalConfirmation isOpenModal={isOpenModal} onClose={onClose} />
        </Container>
      </Box>
    </Fragment>
  );
};
