import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Typography,
  useTheme,
} from '@mui/material';
import { handleAxiosError } from 'api';
import { useUserItemsMutation } from 'api/mutations/userItemsMutation';
import { TransferIcon } from 'assets/svg-components/Transfer';
import { BackButton } from 'components/pages/AuthPage/AuthPage.support';
import {
  mgasToMoilExchangeRate,
  moilToMgasExchangeRate,
} from 'constants/index';
import { FC, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUserItemsStore } from 'stores/useUserItemsStore';
import { buttonSx } from 'theme/sxProps';
import * as yup from 'yup';
import { CoinActionPageProps } from '../../DashboardPage.types';
import { formBoxSx, inputSx } from '../../DashboardPage.shared';
import { useNotify } from 'hooks/useToast';
import { useUserWalletBalanceStore } from 'stores/useUserBalanceStore';

export const SwapTab: FC<CoinActionPageProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const item = props.item;
  const title = props.item.identifier.split('.').pop()!;

  const { refetch: coinsRefetch } = useUserWalletBalanceStore();
  const { currencyRefetch } = useUserItemsStore();
  const { notify } = useNotify();
  const { shape, palette } = useTheme();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset: resetFields,
    formState: { errors, isValid },
  } = useForm<{ amount: number }>({
    mode: 'all',
    defaultValues: { amount: 0 },
    resolver: yupResolver(
      yup.object().shape({
        amount: yup
          .number()
          .positive()
          .moreThan(0)
          .max(Number(item?.amount ?? 0), 'Not enough in your account')
          .required(),
      })
    ),
  });

  const {
    mutateAsync: mutateAsyncMgas,
    isError: isErrorMgas,
    error: errorMgas,
  } = useUserItemsMutation('exchangeMgasToMoil');

  const {
    mutateAsync: mutateAsyncMoil,
    isError: isErrorMoil,
    error: errorMoil,
  } = useUserItemsMutation('exchangeMoilToMgas');

  const onSuccess = useCallback(async () => {
    resetFields();
    setTimeout(() => {
      currencyRefetch();
      coinsRefetch();
    }, 1000);
    notify({ type: 'success', meassage: 'Swap success!' });
  }, [coinsRefetch, currencyRefetch, notify, resetFields]);

  const onError = useCallback(() => {
    notify({ type: 'error', meassage: 'Swap error!' });
  }, [notify]);

  const onSubmit: SubmitHandler<{ amount: number }> = useCallback(
    async ({ amount }) => {
      setLoading(true);
      try {
        if (item.identifier === 'Gaming.moil') {
          await mutateAsyncMoil({ amount });
        }
        if (item.identifier === 'Gaming.mgas') {
          await mutateAsyncMgas({ amount });
        }
        !(isErrorMgas || isErrorMoil) && (await onSuccess());
      } catch (e) {
        onError();
      } finally {
        setLoading(false);
      }
    },
    [
      isErrorMgas,
      isErrorMoil,
      item.identifier,
      mutateAsyncMgas,
      mutateAsyncMoil,
      onError,
      onSuccess,
    ]
  );

  const getCoinOpposite = (type: string) => {
    if (type === 'moil') {
      return {
        opposite: 'mgas',
        exchangeRate: moilToMgasExchangeRate,
      };
    }
    if (type === 'mgas') {
      return {
        opposite: 'moil',
        exchangeRate: mgasToMoilExchangeRate,
      };
    }
  };

  const setCoinOpposite = () => {
    if (item.identifier === 'Gaming.moil') {
      item.setSelectedTab('Gaming.mgas');
    }
    if (item.identifier === 'Gaming.mgas') {
      item.setSelectedTab('Gaming.moil');
    }
  };

  useEffect(() => {
    if (isErrorMgas || isErrorMoil) {
      notify({
        type: 'error',
        meassage: handleAxiosError(errorMgas) || handleAxiosError(errorMoil),
      });
    }
  }, [errorMgas, errorMoil, isErrorMgas, isErrorMoil, notify]);

  return (
    <>
      <Box display="flex" alignItems="center">
        <BackButton
          sx={{ position: 'initial', width: '60px', height: '60px' }}
          handleBack={() => props.setPage('Default')}
        />
        <Typography variant="h1" ml="15px">
          Swap
        </Typography>
        <Box
          sx={{
            display: 'grid',
            placeItems: 'center',
            background: 'rgba(129, 138, 137, 0.1)',
            width: 110,
            height: 60,
            ml: 'auto',
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <Typography variant="h3"> {title.toUpperCase()}</Typography>
        </Box>
      </Box>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={formBoxSx}>
        <Box
          display="flex"
          flexDirection="column"
          gap="20px"
          width="100%"
          height="105px"
          p="15px"
          sx={{
            background: palette.background.card,
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ color: palette.text.disabled }}>
              {title.toUpperCase()} Amount
            </Typography>
            <Typography sx={{ color: palette.text.disabled }}>
              Balance: {item.amount}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ color: palette.secondary.main }}>
              <InputBase
                {...register('amount')}
                placeholder="0.00"
                error={
                  !!errors.amount ||
                  handleAxiosError(errorMgas) ||
                  handleAxiosError(errorMoil)
                }
                sx={inputSx()}
              />
              {errors.amount?.message && (
                <Typography color="error">{errors.amount?.message}</Typography>
              )}
            </Typography>
            <Button
              onClick={() =>
                item?.amount && setValue('amount', Number(item?.amount))
              }
              sx={{
                ml: '20px',
                color: palette.primary.main,
                borderRadius: `${shape.borderRadius * 2}px`,
              }}
            >
              MAX
            </Button>
          </Box>
        </Box>

        <Box
          display="grid"
          top="25%"
          right="60px"
          width="60px"
          height="60px"
          sx={{
            placeItems: 'center',
            background: palette.primary.dark,
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <IconButton onClick={setCoinOpposite}>
            <TransferIcon variant={2} />
          </IconButton>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          gap="20px"
          width="100%"
          height="105px"
          p="15px"
          sx={{
            background: palette.background.card,
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ color: palette.text.disabled }}>
              {getCoinOpposite(title)?.opposite.toUpperCase()} Amount
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ color: palette.secondary.main }}>
              <Typography variant="h4">
                {watch('amount') * getCoinOpposite(title)?.exchangeRate!}
              </Typography>
            </Typography>
          </Box>
        </Box>

        <Button
          type="submit"
          disabled={!isValid || loading}
          sx={buttonSx({ mt: 'auto', width: '352px' })}
        >
          <Typography fontWeight={600}>
            {loading ? 'LOADING' : 'SWAP'}
          </Typography>
        </Button>
      </Box>
    </>
  );
};
