import { Box, Button, CircularProgress, Typography } from '@mui/material';
import React, { useState } from 'react';

import { TransferIcon } from 'assets/svg-components/Transfer';
import { MintButton } from '../styles';
import { ContainersList } from './ContainersList';

import { Close } from '@mui/icons-material';
import { useUserQuery } from 'api';
import { useContainersMutation } from 'api/mutations/containersMutation';
import { useUserItemsStore } from 'stores/useUserItemsStore';
import { OpenContainerResponse } from 'types/models';
import { container } from '../../../../../constants';
import { OpenedCarModal } from './OpenedCarModal';

export const ContainersTab: React.FC = () => {
  const [car, setCar] = useState<OpenContainerResponse>();
  const { refetch: carsRefetch } = useUserQuery('cars', { enabled: true });
  const { mutateAsync: open } = useContainersMutation('open');
  const { storage, storageRefetch } = useUserItemsStore();

  const containers = storage?.data.filter(({ item }) => {
    return item.name === container;
  });

  const [isSelectEnabled, setIsSelectEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedContainers, setSelectedContainers] = useState<Array<number>>(
    []
  );

  const onItemClick = (id: number) => {
    if (!isSelectEnabled) return;

    if (selectedContainers.includes(id)) {
      setSelectedContainers([]);
    } else {
      setSelectedContainers([id]);
    }
  };

  const cancel = () => {
    setSelectedContainers([]);
    setIsSelectEnabled(false);
    setCar(undefined);
  };

  const mint = async () => {
    setLoading(true);
    try {
      const car = await open({
        userGarageItemContainerId: selectedContainers[0],
      });
      setCar(car);
      setOpenModal(true);
      await storageRefetch();
      await carsRefetch();
    } catch (error) {
      console.log('open error : ', error);
    }
    setLoading(false);
  };

  return (
    <Box display={'flex'} flexDirection={'column'} width={'100%'}>
      <Box display="flex" columnGap="16px" mb={2} alignItems={'center'}>
        <Typography variant="h1">CONTAINERS</Typography>
        <Typography marginX={'10px'} color={'cyan'} variant="h1">
          {containers?.length || 0}
        </Typography>
        {!isSelectEnabled ? (
          <Button
            variant="contained"
            sx={{ alignItems: 'center', marginLeft: 'auto' }}
            onClick={() => setIsSelectEnabled(true)}
          >
            <Typography marginX={'22px'} variant="h4">
              Open
            </Typography>
          </Button>
        ) : (
          <>
            <Button
              variant="contained"
              sx={{ alignItems: 'center', width: '100%' }}
              onClick={cancel}
            >
              <Typography marginX={'22px'} variant="h4">
                CHOOSE A CONTAINER
              </Typography>
              <Close sx={{ marginLeft: 'auto' }} />
            </Button>
          </>
        )}
      </Box>
      <ContainersList
        containers={containers}
        isSelectEnabled={isSelectEnabled}
        onItemClick={onItemClick}
        selectedContainers={selectedContainers}
      />
      {isSelectEnabled && (
        <MintButton
          onClick={mint}
          disabled={!selectedContainers.length}
          variant="contained"
        >
          {loading ? (
            <CircularProgress sx={{ gridColumnStart: 2 }} color="secondary" />
          ) : (
            <>
              <Box sx={{ gridColumnStart: 2 }}>
                <TransferIcon width={30} height={20} fill="black" />
                <span style={{ marginLeft: '10px' }}>OPEN</span>
              </Box>
              {/* {!!selectedContainers.length && (
                <PriceBlock>
                  <img src={moilPhoto} width={27} height={27} alt="" />
                  <Typography variant="h3">4700</Typography>
                </PriceBlock>
              )} */}
            </>
          )}
        </MintButton>
      )}
      {car && (
        <OpenedCarModal
          openModal={openModal}
          onClose={() => {
            setOpenModal(false);
            cancel();
          }}
          car={car}
        />
      )}
    </Box>
  );
};
