export const DiscanteIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.27246 0.273438V4.63707L11.6361 9.0007L7.27246 13.3643V17.728L15.9997 9.0007L7.27246 0.273438Z"
        fill="#939393"
      />
      <path
        d="M0 0.273438V4.63707L4.36363 9.0007L0 13.3643V17.728L8.72726 9.0007L0 0.273438Z"
        fill="#939393"
      />
    </svg>
  );
};
