import React from 'react';

export const ContainerIcon = (props: {
  fill?: string;
  height?: number;
  width?: number;
}) => {
  const width = 22;
  const height = 20;
  const defaultColor = 'white';

  return (
    <svg
      width={props?.width ?? width}
      height={props?.height ?? height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4095 0H10.0993C9.95872 0 9.82281 0.0504937 9.71502 0.1423L0.201517 8.22584C0.0796692 8.33142 0 8.48749 0 8.65733V19.4262C0 19.7429 0.262441 20 0.585807 20H12.5269C12.7097 20 12.8737 19.9174 12.9815 19.7888L20.8688 10.2318C20.9531 10.1308 21 10.0023 21 9.86918V0.573789C20.9953 0.257058 20.7329 0 20.4095 0ZM3.17273 13.6516H2.52131V14.4319H3.17273V18.8524H1.17161V9.23112H3.17273V13.6516ZM5.97054 18.8524H3.96943V14.4319H4.62084V13.6516H3.96943V9.23112H5.97054V18.8524ZM9.14327 13.6516H8.49186V14.4319H9.14327V18.8524H7.14215V9.23112H9.14327V13.6516ZM11.9411 18.8524H9.93997V14.4319H10.5914V13.6516H9.93997V9.23112H11.9411V18.8524ZM12.2785 8.08354H2.15577L10.3196 1.14758H19.0411L12.2785 8.08354Z"
        fill={props?.fill ?? defaultColor}
      />
    </svg>
  );
};
