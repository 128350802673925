import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

export const TabButton = (props: {
  tab: string;
  onClick: () => void;
  icon: JSX.Element;
  isSelected?: boolean;
  sx?: SystemStyleObject;
}) => {
  const { onClick, isSelected = true, icon, tab, sx } = props;
  const { palette, shape } = useTheme();
  const sxProps: SxProps = {
    pt: '0 !important',
    pl: '22px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: `${shape.borderRadius * 2}px`,
    background: isSelected ? palette.primary.main : palette.background.card,
    color: isSelected ? palette.primary.dark : palette.primary.main,
    ...sx,
  };

  return (
    <Box height="60px" onClick={onClick} sx={sxProps}>
      {icon}
      <Typography ml="22px" variant="h4">
        {tab}
      </Typography>
    </Box>
  );
};
