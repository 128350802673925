import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { handleAxiosError, useAxios, useUserMutation } from 'api';
import { Input } from 'components/ui';
import { useWeb3 } from 'hooks';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routes';
import { useUserStore } from 'stores/useUserStore';
import { authContainerSx, buttonSx } from 'theme/sxProps';
import { localStorageUtil } from 'utils/localStorageUtils';
import { AuthTitle } from '../AuthPage.support';
import { AuthType, IFormLoginData } from '../AuthPage.types';
import { LoginProviders } from '../LoginProviders';
import { LoginSchema } from './Schema';
import { useNotify } from 'hooks/useToast';

export const LoginDrawer = (props: {
  setActive: (payload: AuthType) => void;
  active: AuthType;
}) => {
  let email = useUserStore((s) => s.info?.email);
  const setAccessToken = localStorageUtil.setItem;
  const navigate = useNavigate();
  const setInfo = useUserStore((s) => s.setInfo);

  const { notify } = useNotify();
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    setValue,
  } = useForm<IFormLoginData>({
    defaultValues: { email, password: '' },
    mode: 'all',
    resolver: yupResolver(LoginSchema),
  });
  const {
    data,
    mutate,
    isSuccess,
    isError,
    error: mutateError,
  } = useUserMutation('login');
  const { active, setActive } = props;
  const { loggedWithWeb3, initLoading, loginWithEmail } = useWeb3();
  const { setAuthToken } = useAxios();
  const { palette } = useTheme();

  const onSubmit: SubmitHandler<IFormLoginData> = useCallback(
    (formData) => {
      !loggedWithWeb3 && loginWithEmail(formData.email);
      setInfo({ email: formData.email });
      mutate(formData);
    },
    [loggedWithWeb3, loginWithEmail, mutate, setInfo]
  );

  let watchEmail = watch('email');

  useEffect(() => {
    if (watchEmail === '') {
      setInfo({ email: undefined });
    }
  }, [setInfo, watchEmail]);

  useEffect(() => {
    if (email) {
      setValue('email', email);
    }
  }, [email, setValue]);

  useEffect(() => {
    if (isSuccess) {
      notify({ type: 'success', meassage: 'Login success!' });
      const token = data.data.accessToken;
      const bbid = data.data.user.bbid;
      setAuthToken({ access_token: token });
      setInfo({ bbid });
      setAccessToken({ key: 'access_token', value: token });
      setInfo({ email });
      loggedWithWeb3 && navigate(routes.dashboard.path);
      console.info('User logged via backend');
    }
    if (loggedWithWeb3) {
      console.info('User logged via web3');
    }
    if (loggedWithWeb3 && isSuccess) {
      navigate(routes.dashboard.path);
      console.info('User logged via all');
    }
  }, [
    isSuccess,
    data,
    navigate,
    loggedWithWeb3,
    initLoading,
    setAccessToken,
    setInfo,
    email,
    setAuthToken,
    notify,
  ]);

  return (
    <>
      <Box sx={authContainerSx()}>
        <Typography variant="h1">
          Welcome to <br /> Moovy Dashboard
        </Typography>
        <Box display="flex" mt="30px">
          <AuthTitle
            {...{ path: 'login', title: 'Login', setActive, active }}
          />
          <AuthTitle
            {...{ path: 'signup', title: 'Sign up', setActive, active, ml: 30 }}
          />
          <Typography ml="auto" variant="h5" color="secondary" marginBottom={4}>
            <Box
              style={{ color: palette.primary.main, cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                setActive('reset');
              }}
            >
              Forgot Password?
            </Box>
          </Typography>
        </Box>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2} marginBottom={5}>
            <Input
              type="email"
              text="E-mail"
              placeholder="email@gmail.com"
              fullWidth
              required
              message={errors.email?.message || handleAxiosError(mutateError)}
              error={!!errors.email || isError}
              {...register('email')}
            />
            <Input
              text="Password"
              placeholder="qwerty"
              fullWidth
              required
              message={
                errors.password?.message || handleAxiosError(mutateError)
              }
              error={!!errors.password || isError}
              showIcon={true}
              {...register('password')}
            />
          </Stack>
          <LoadingButton
            fullWidth
            type="submit"
            disabled={!isDirty || !isValid || initLoading}
            sx={buttonSx({ mb: '16px' })}
          >
            <Typography variant="h5" fontWeight="700">
              {initLoading ? 'Loading' : 'Login'}
            </Typography>
          </LoadingButton>
          <LoginProviders />
        </Box>
      </Box>
    </>
  );
};
