import { Box, Button, Typography, useTheme } from '@mui/material';
import AppleIcon from 'assets/images/Apple.svg';
import DiscordIcon from 'assets/images/Discord.svg';
import GoogleIcon from 'assets/images/Google.svg';
import { useWeb3 } from 'hooks';
import { Fragment, useLayoutEffect, useState } from 'react';
import { buttonSx } from 'theme/sxProps';

export const LoginProviders = () => {
  const { loginWithProvider, loggedWithWeb3, logout } = useWeb3();
  const { palette } = useTheme();
  const [isAppleDevice, setIsAppleDevice] = useState(false);

  useLayoutEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor;
    setIsAppleDevice(/Macintosh|iPad|iPhone|iPod/.test(userAgent));
  }, []);

  return (
    <Fragment>
      <Typography mb="16px" color="secondary" textAlign="center">
        {loggedWithWeb3 && (
          <>
            YOU ARE SIGNED VIA WEB3 -{' '}
            <span
              style={{ color: palette.primary.main, cursor: 'pointer' }}
              onClick={() => logout()}
            >
              Logout?
            </span>
          </>
        )}
        {!loggedWithWeb3 && 'AND SIGN UP VIA'}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: 120,
          marginInline: 'auto',
          columnGap: '10px',
        }}
      >
        <Button
          disabled={loggedWithWeb3}
          sx={buttonSx({ borderRadius: 999, p: '10px' })}
          onClick={() => loginWithProvider('google')}
        >
          <img src={GoogleIcon} alt="google" />
        </Button>
        {isAppleDevice && (
          <Button
            disabled={loggedWithWeb3}
            sx={buttonSx({ borderRadius: 999, p: '10px' })}
            onClick={() => loginWithProvider('apple')}
          >
            <img src={AppleIcon} alt="apple" />
          </Button>
        )}
        <Button
          disabled={loggedWithWeb3}
          sx={buttonSx({ borderRadius: 999, p: '10px' })}
          onClick={() => loginWithProvider('discord')}
        >
          <img src={DiscordIcon} alt="discord" />
        </Button>
      </Box>
    </Fragment>
  );
};
