import React from 'react';

export const MenuIcon = (props: {
  fill?: string;
  height?: number;
  width?: number;
  style?: React.SVGProps<SVGSVGElement>['style'];
}) => {
  const width = 18;
  const height = 18;
  const defaultColor = '#21E7D6';
  return (
    <svg
      style={props.style}
      width={props?.width ?? width}
      height={props?.height ?? height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.19119 0.155948C4.30475 -0.0519828 3.38222 -0.0519826 2.49578 0.155948C1.33479 0.428279 0.42828 1.33479 0.155948 2.49578C-0.0519828 3.38222 -0.0519828 4.30475 0.155948 5.19119C0.42828 6.35218 1.33479 7.25869 2.49578 7.53102C3.38222 7.73895 4.30475 7.73895 5.19119 7.53102C6.35218 7.25869 7.25869 6.35218 7.53102 5.19119C7.73895 4.30475 7.73895 3.38222 7.53102 2.49578C7.25869 1.33479 6.35218 0.428279 5.19119 0.155948Z"
        fill={props?.fill ?? defaultColor}
      />
      <path
        d="M5.19119 10.469C4.30475 10.261 3.38222 10.261 2.49578 10.469C1.33479 10.7413 0.42828 11.6478 0.155948 12.8088C-0.0519828 13.6952 -0.0519828 14.6178 0.155948 15.5042C0.42828 16.6652 1.33479 17.5717 2.49578 17.8441C3.38222 18.052 4.30475 18.052 5.19119 17.8441C6.35218 17.5717 7.25869 16.6652 7.53102 15.5042C7.73895 14.6178 7.73895 13.6952 7.53102 12.8088C7.25869 11.6478 6.35218 10.7413 5.19119 10.469Z"
        fill={props?.fill ?? defaultColor}
      />
      <path
        d="M15.5042 0.155948C14.6178 -0.0519828 13.6952 -0.0519826 12.8088 0.155948C11.6478 0.428279 10.7413 1.33479 10.469 2.49578C10.261 3.38222 10.261 4.30475 10.469 5.19119C10.7413 6.35218 11.6478 7.25869 12.8088 7.53102C13.6952 7.73895 14.6178 7.73895 15.5042 7.53102C16.6652 7.25869 17.5717 6.35218 17.8441 5.19119C18.052 4.30475 18.052 3.38222 17.8441 2.49578C17.5717 1.33479 16.6652 0.428279 15.5042 0.155948Z"
        fill={props?.fill ?? defaultColor}
      />
      <path
        d="M15.5042 10.469C14.6178 10.261 13.6952 10.261 12.8088 10.469C11.6478 10.7413 10.7413 11.6478 10.469 12.8088C10.261 13.6952 10.261 14.6178 10.469 15.5042C10.7413 16.6652 11.6478 17.5717 12.8088 17.8441C13.6952 18.052 14.6178 18.052 15.5042 17.8441C16.6652 17.5717 17.5717 16.6652 17.8441 15.5042C18.052 14.6178 18.052 13.6952 17.8441 12.8088C17.5717 11.6478 16.6652 10.7413 15.5042 10.469Z"
        fill={props?.fill ?? defaultColor}
      />
    </svg>
  );
};
