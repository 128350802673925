import { Box, Snackbar, SnackbarOrigin, Typography } from '@mui/material';
import { useState } from 'react';
import MoovyIcon from 'assets/images/moovy.svg';

export const MoovySnackbar = () => {
  const [state] = useState<
    SnackbarOrigin & {
      open: boolean;
    }
  >({
    open: true,
    vertical: 'bottom',
    horizontal: 'right',
  });
  const { vertical, horizontal, open } = state;

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      sx={{
        '& .MuiPaper-root': {
          height: '100px',
          width: '280px',
          paddingInline: '25px',
          borderRadius: '20px',
          backdropFilter: 'blur(12.5px)',
          background:
            'linear-gradient(179.16deg, rgba(49, 73, 74, 0.5) -7.44%, rgba(27, 23, 27, 0.5) 106%)',
        },
      }}
      message={
        <Box display="flex">
          <img src={MoovyIcon} style={{ marginRight: 18 }} alt="" />
          <Box>
            <Typography color="white">Moovy</Typography>
            <Typography color="secondary">drive2earn game</Typography>
          </Box>
        </Box>
      }
      key={vertical + horizontal}
    />
  );
};
