import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { handleAxiosError } from 'api';
import { GamingIcon } from 'assets/svg-components/Gaming';
import { TransferIcon } from 'assets/svg-components/Transfer';
import { WalletIcon } from 'assets/svg-components/Wallet';
import { BackButton } from 'components/pages/AuthPage/AuthPage.support';
import { config } from 'config';
import { moil } from 'constants/index';
import { useNotify } from 'hooks/useToast';
import { useVaultKeeper } from 'hooks/useVaultKeeper';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useUserWalletBalanceStore } from 'stores/useUserBalanceStore';
import { useUserItemsStore } from 'stores/useUserItemsStore';
import { useWithdraw } from 'stores/useWithdraw';
import { buttonSx } from 'theme/sxProps';
import * as yup from 'yup';
import { inputSx, transferFormBoxSx } from '../../DashboardPage.shared';
import {
  CoinActionPageProps,
  TransactionIconType,
} from '../../DashboardPage.types';

const initialValidationSchema = yup.object().shape({
  amount: yup.number().typeError('amount must be a number').min(25).required(),
});

export const TransferTab: FC<CoinActionPageProps> = (props) => {
  const [validationSchema, setValidationSchema] = useState(
    initialValidationSchema
  );
  const [loading, setLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [fromTo, setFromTo] = useState<{
    from: TransactionIconType;
    to: TransactionIconType;
  }>({
    from: 'Gaming',
    to: 'Wallet',
  });

  const { notify } = useNotify();
  const { currency, currencyRefetch } = useUserItemsStore();
  const { refetch: coinsRefetch, tokenBalances } = useUserWalletBalanceStore();

  const {
    activeTxId,
    requestWithdraw,
    vaultKeeperCall,
    error: vaultError,
    setError: setVaultError,
    transactionWithdrawError,
    getTransactionWithdrawError,
    setActiveTxId,
  } = useVaultKeeper();
  const { reset, setAmount, setReceiver, withdraw } = useWithdraw();
  const { shape, palette } = useTheme();
  const {
    watch,
    register,
    handleSubmit,
    setValue,
    reset: resetFields,
    formState: { errors, isValid },
  } = useForm<{ amount: number }>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const item = props.item;
  const title = props.item.identifier.split('.').pop()!;
  const token = config.tokens.find((t) => t.symbol === title)!;
  const moilItem = currency?.data.find(({ item }) => {
    return item.name === moil;
  });

  const onSuccess = useCallback(async () => {
    reset();
    resetFields();
    setTimeout(() => {
      coinsRefetch();
      currencyRefetch();
    }, 3000);
    notify({
      type: 'success',
      meassage:
        fromTo.from === 'Wallet'
          ? 'Transaction is in progress. Your assets will arrive in 1 minutes'
          : 'Transfer success!',
    });
  }, [coinsRefetch, fromTo, currencyRefetch, notify, reset, resetFields]);

  const onError = useCallback(() => {
    if (fromTo.from === 'Gaming') {
      setVaultError(true);
    }
    notify({ type: 'error', meassage: 'Transfer error!' });
  }, [fromTo.from, notify, setVaultError]);

  const onSubmit: SubmitHandler<{ amount: number }> = useCallback(
    async ({ amount }) => {
      setLoading(true);
      try {
        const itemId = moilItem!.item.itemId;

        if (fromTo.from === 'Gaming') {
          await requestWithdraw({ amount, itemId });
        }
        if (fromTo.from === 'Wallet') {
          await withdraw(token);
        }
        await onSuccess();
      } catch (e) {
        onError();
      } finally {
        setLoading(false);
      }
    },
    [
      fromTo.from,
      moilItem,
      onError,
      onSuccess,
      requestWithdraw,
      token,
      withdraw,
    ]
  );

  const handleRetry = useCallback(async () => {
    setLoading(true);
    try {
      if (activeTxId) {
        await vaultKeeperCall({ id: activeTxId }).then(() => {
          onSuccess();
        });
      }
    } catch (e) {
      console.error(e);
      setVaultError(true);
    } finally {
      setActiveTxId(null);
      setLoading(false);
    }
  }, [activeTxId, onSuccess, setActiveTxId, setVaultError, vaultKeeperCall]);

  const getIcon = (type: TransactionIconType) => {
    if (type === 'Gaming') return <GamingIcon />;
    if (type === 'Wallet') return <WalletIcon />;
  };

  useEffect(() => {
    const newValidationSchema = yup.object().shape({
      amount: yup
        .number()
        .typeError('Amount must be a number')
        .min(fromTo.from === 'Gaming' ? 25 : 0)
        .required(),
    });
    setValidationSchema(newValidationSchema);
  }, [fromTo]);

  let amount = String(watch('amount'));

  useEffect(() => {
    setReceiver(config.vault_keeper);
    setAmount(amount);
  }, [amount, setAmount, setReceiver, watch]);

  useEffect(() => {
    if (submitSuccess) {
      setTimeout(() => {
        setSubmitSuccess(false);
      }, 1500);
    }
  }, [setReceiver, submitSuccess]);

  const submitButton = useMemo(
    () => (
      <>
        {!activeTxId && (
          <Button
            type="submit"
            disabled={!isValid || loading}
            sx={buttonSx({ mt: 'auto', width: '352px' })}
          >
            <Typography fontWeight={600}>
              {loading ? 'LOADING' : 'CONFIRM TRANSFER'}
            </Typography>
          </Button>
        )}
        {activeTxId && fromTo.from === 'Gaming' && (
          <Button
            onClick={handleRetry}
            disabled={loading}
            sx={buttonSx({ mt: 'auto', width: '352px' })}
          >
            <Typography fontWeight={600}>
              {loading ? 'LOADING' : 'RETRY'}
            </Typography>
          </Button>
        )}
      </>
    ),
    [activeTxId, handleRetry, isValid, fromTo, loading]
  );

  return (
    <>
      <Box display="flex" alignItems="center">
        <BackButton
          sx={{ position: 'initial', width: '60px', height: '60px' }}
          handleBack={() => props.setPage('Default')}
        />
        <Typography variant="h1" ml="15px">
          Transfer
        </Typography>
        <Box
          sx={{
            display: 'grid',
            placeItems: 'center',
            background: 'rgba(129, 138, 137, 0.1)',
            width: 110,
            height: 60,
            ml: 'auto',
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <Typography variant="h3"> {title.toUpperCase()}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'relative',
          borderRadius: `${shape.borderRadius * 1.5}px`,
          background:
            'linear-gradient(135deg, rgba(33, 231, 214, 0.08) 0%, rgba(131, 218, 255, 0.08) 100%)',
          backdropFilter: 'blur(21.5)',
        }}
      >
        <Box
          display="grid"
          position="absolute"
          top="25%"
          right="60px"
          width="60px"
          height="60px"
          sx={{
            placeItems: 'center',
            background: palette.primary.dark,
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <IconButton
            onClick={() => {
              setFromTo((prev) => {
                if (prev.from === 'Gaming') {
                  return { from: 'Wallet', to: 'Gaming' };
                }
                return { from: 'Gaming', to: 'Wallet' };
              });
            }}
          >
            <TransferIcon variant={2} />
          </IconButton>
        </Box>
        <Box display="flex" alignItems="center" p="20px">
          <Typography width="40px" color={palette.text.disabled}>
            From:
          </Typography>
          {getIcon(fromTo.from)}
          <Typography ml="5px"> {fromTo.from}</Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          p="20px"
          sx={{ borderTop: `1px solid #315451` }}
        >
          <Typography width="40px" color={palette.text.disabled}>
            To:
          </Typography>
          {getIcon(fromTo.to)}
          <Typography ml="5px"> {fromTo.to}</Typography>
        </Box>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={transferFormBoxSx}
      >
        <Box
          display="flex"
          height="60px"
          width="100%"
          px="22px"
          sx={{
            alignItems: 'center',
            background: palette.primary.dark,
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <img
            width={24}
            height={24}
            src={props.item.image ?? ''}
            alt={title}
          />
          <Typography variant="h4" ml="10px">
            {title.toUpperCase()}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap="20px"
          width="100%"
          height="105px"
          sx={{
            p: '15px',
            background: palette.background.card,
            borderRadius: `${shape.borderRadius}px`,
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography sx={{ color: palette.text.disabled }}>
              Amount
            </Typography>
            <Typography sx={{ color: palette.text.disabled }}>
              Available:{' '}
              {fromTo.from === 'Gaming'
                ? item.amount
                : tokenBalances?.find((c) => c.symbol === 'moil')?.balance}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography sx={{ color: palette.secondary.main }}>
              <StyledNumberInput
                {...register('amount')}
                placeholder="0.00"
                type="number"
                error={
                  !!errors.amount ||
                  handleAxiosError(transactionWithdrawError) ||
                  handleAxiosError(getTransactionWithdrawError)
                }
                sx={inputSx()}
              />
            </Typography>
            <Box display="flex" alignItems="center">
              {item.image && (
                <img src={item.image} width={24} height={24} alt={title} />
              )}
              <Typography ml="5px" fontWeight={600}>
                {title.toLocaleUpperCase()}
              </Typography>
              <Button
                onClick={() =>
                  item?.amount && setValue('amount', Number(item?.amount))
                }
                sx={{
                  ml: '20px',
                  background: palette.primary.dark,
                  color: palette.primary.main,
                  borderRadius: `${shape.borderRadius * 2}px`,
                }}
              >
                ALL
              </Button>
            </Box>
          </Box>
          {errors.amount?.message && (
            <Typography color="error">{errors.amount?.message}</Typography>
          )}
        </Box>
        {submitButton}
        {submitSuccess && !vaultError && (
          <Typography color="primary">Success</Typography>
        )}
        {/* {vaultError && <Typography color="error">Error</Typography>} */}
      </Box>
    </>
  );
};

export const StyledNumberInput = styled(InputBase)`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    display: none;
  }
`;
