import { SelectProps, SxProps, Theme } from '@mui/material';
import { CSSProperties } from 'react';
import { SystemStyleObject } from '@mui/system';

export const selectSx: SelectProps['sx'] = {
  fontWeight: '700',
  height: '44px',
  width: '124px',
  borderRadius: '22px',
  background: ({ palette }) => palette.primary.dark,
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

export const formBoxSx: SxProps<Theme> = {
  display: 'flex',
  rowGap: '10px',
  alignItems: 'center',
  flexDirection: 'column',
  height: '508px',
  width: '710px',
  border: ({ palette }) => `2px solid ${palette.text.disabled}`,
  borderRadius: ({ shape }) => `${shape.borderRadius}px`,
  background: 'linear-gradient(135deg, #141313 0%, #18191B 100%)',
  backdropFilter: 'blur(10px)',
  px: '20px',
  pt: '30px',
  pb: '30px',
};

export const transferFormBoxSx: SxProps<Theme> = {
  display: 'flex',
  rowGap: '10px',
  alignItems: 'center',
  flexDirection: 'column',
  height: '374px',
  width: '710px',
  border: ({ palette }) => `2px solid ${palette.text.disabled}`,
  borderRadius: ({ shape }) => `${shape.borderRadius}px`,
  background: 'linear-gradient(135deg, #141313 0%, #18191B 100%)',
  backdropFilter: 'blur(10px)',
  px: '20px',
  pt: '30px',
  pb: '30px',
};

export const imgSx: CSSProperties = {
  objectFit: 'fill',
  opacity: 0.2,
  zIndex: -1,
  position: 'absolute',
  bottom: -50,
  right: -50,
  width: '100%',
  height: '100%',
};

export const inputSx =
  (styles?: SystemStyleObject<Theme>): SxProps<Theme> =>
  () => ({
    fontSize: '20px',
    fontWeight: '600',
    color: ({ palette }) => palette.text.main,
    background: 'transparent',
    border: 'none',
    outline: 'none',
    ...styles,
  });

