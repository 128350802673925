import { Box, Typography } from '@mui/material';
import { useMarketMutation } from 'api/mutations/marketMutation';
import { Basket } from 'assets/svg-components/Basket';
import { containers } from 'constants/containerImages';
import { useNotify } from 'hooks/useToast';
import { useUserStore } from 'stores/useUserStore';
import styled from 'styled-components';
import { CarSale } from 'types/models';
import { capitalizeFirstLetter } from 'utils/textUtils';
import MGAS3dxxx from '../../../../../assets/images/MGAS3dxxx.png';
import Moovy from '../../../../../assets/images/moovy.svg';
import { labelColors } from '../../../../../constants';
import { MarketInteractor } from '../../interactor';
import { Dot, Property } from '../styles';

export const CarItem = ({
  //TODO: replace car to container
  car,
}: {
  car: CarSale;
}) => {
  const { setIsOpenModal } = useUserStore();
  const { mutateAsync: buyMarketItems } = useMarketMutation('buyMarketItems');
  const { notify } = useNotify();
  const { fethMarketContainer } = MarketInteractor();
  const handleBuy = () => {
    setIsOpenModal({
      isOpen: true,
      handleClick: () => {
        buyMarketItems({
          marketItemId: car.id,
        })
          .then((res: any) => {
            notify({
              type: 'success',
              meassage: 'You successfully purchased ad container',
            });
            fethMarketContainer();
          })
          .catch((err: any) => {
            notify({
              type: 'error',
              meassage: err.response.data.message,
            });
            console.log('[breed price error] : ', err);
          });
      },
    });
  };
  return (
    <CardBox>
      <>
        <Img
          src={containers[car.item_to_sale.item.container.rarity.name]}
          alt={'CarImg'}
        />
        <Property style={{ width: '100%' }} mt={2} item xs={15.5}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            width={'100%'}
          >
            <Box
              display={'flex'}
              flexDirection={'row'}
              alignItems={'center'}
              gap={1}
              width={'100%'}
            >
              <img src={Moovy} height={16} alt="" />
              <Typography fontWeight={'bold'}>Container</Typography>
            </Box>
          </Box>
        </Property>
        <OptionBox>
          <Property item xs={7.5}>
            <Dot
              sx={{
                background:
                  labelColors[car.item_to_sale.item.container.rarity.name],
              }}
            />
            {capitalizeFirstLetter(car.item_to_sale.item.container.rarity.name)}
          </Property>
          <BuyBtn onClick={handleBuy}>
            <Img style={{ width: 15 }} src={MGAS3dxxx} alt={'CarImg'} />
            {car.price} <Basket />
          </BuyBtn>
        </OptionBox>
      </>
    </CardBox>
  );
};

const CardBox = styled.div`
  border-radius: 10px;
  padding: 15px;
  background: linear-gradient(135deg, #141313 0%, #18191b 100%);
  box-shadow: 0px 3.6074767112731934px 18.037384033203125px 0px
    rgba(0, 0, 0, 0.5);
  width: 340px;
  border: 2px solid #1d1d1d;
`;
const Img = styled.img`
  border-radius: 15px;
  width: 100%;
`;
const OptionBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 4px;
  margin-top: 5px;
`;
const BuyBtn = styled.div`
  display: flex;
  width: 49%;
  height: 42px;
  padding: 10px 15px;
  align-items: center;
  gap: 5px;
  bordet: none;
  border-radius: 15px;
  background: linear-gradient(180deg, #21e7d6 0%, #83daff 100%);
  color: black;
  font-family: 'Fact';
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  justify-content: center;
`;
